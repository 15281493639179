import { ProjectDynamicControls } from '../form-controls/project.form-controls';
import { IProject } from '../interfaces/project';
import { DynamicField, DynamicFieldType, IDynamicFieldType, DynamicFieldTypes, IDynamicField, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { nameof } from '../shared-methods/shared-methods';
import { Validators } from '@angular/forms';
import { IProjectDynamicControlsParametersPartial } from './project-dynamic-controls-parameters-partial';

export class ProjectDynamicControlsPartial extends ProjectDynamicControls {
    constructor(
        project?: IProject,
        additionalParameters?: IProjectDynamicControlsParametersPartial,
    ) {
        super(project, additionalParameters);

        this.Form.Name = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project Name',
            name: 'Name',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { 'required': true },
            value: project && project.hasOwnProperty(nameof<IProject>('Name')) ? project.Name : null,
        } as IDynamicField);

        this.Form.Description = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project Description / Comments',
            name: 'Description',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value: project && project.hasOwnProperty(nameof<IProject>('Description')) ? project.Description : null,
        } as IDynamicField);

        this.Form.Address = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project Address',
            name: 'Address',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { 'required': true },
            value: project && project.hasOwnProperty(nameof<IProject>('Address')) ? project.Address : null,
        } as IDynamicField);

        this.Form.City = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project City',
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { 'required': true },
            value: project && project.hasOwnProperty(nameof<IProject>('City')) ? project.City : null,
        } as IDynamicField);

        this.Form.Zip = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project Zip',
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(20), Validators.pattern(RegExp(/^\d+$/))],
            validators: { 'required': true, 'maxlength': 20, 'pattern': RegExp(/^\d+$/) },
            value: project && project.hasOwnProperty(nameof<IProject>('Zip')) ? project.Zip : null,
        } as IDynamicField);

        this.Form.ContactName = new DynamicField({
            formGroup: this.formGroup,
            label: 'Contact Name',
            name: 'ContactName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { 'required': true },
            value: project && project.hasOwnProperty(nameof<IProject>('ContactName')) ? project.ContactName : null,
        } as IDynamicField);

        this.Form.ContactEmail = new DynamicField({
            formGroup: this.formGroup,
            label: 'Contact Email',
            name: 'ContactEmail',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(50), Validators.email],
            validators: { 'required': true, 'maxlength': 50, 'email': true },
            value: project && project.hasOwnProperty(nameof<IProject>('ContactEmail')) ? project.ContactEmail : null,
        } as IDynamicField);

        (this.Form.ProjectTimelineId as DynamicField).validation = [Validators.required];
        (this.Form.ProjectTimelineId as DynamicField).validators = { 'required': true };

        this.Form.StateCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'Project State',
            name: 'StateCode',
            options: additionalParameters.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { 'required': true },
            value: project && project.hasOwnProperty(nameof<IProject>('StateCode')) ? project.StateCode : null,
        } as IDynamicField);

        this.Form.CompanyStateCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'CompanyStateCode',
            options: additionalParameters.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            } as IDynamicFieldType),
            validation: [ Validators.required, Validators.maxLength(2) ],
            validators: { 'required': true, 'maxlength': 2 },
            value: project && project.hasOwnProperty(nameof<IProject>('CompanyStateCode')) ? project.StateCode : null,
        });

        (<DynamicField>this.Form.ContactName).labelHtml = '<label>User Name</label>';
        (<DynamicField>this.Form.ContactName).label = 'User Name';
        (<DynamicField>this.Form.ContactName).validation = [Validators.required];
        (<DynamicField>this.Form.ContactName).validators = { 'required': true };

        (<DynamicField>this.Form.CompanyName).labelHtml = '<label>User Company Name</label>';
        (<DynamicField>this.Form.CompanyName).label = 'User Company Name';
        (<DynamicField>this.Form.CompanyName).validation = [Validators.required];
        (<DynamicField>this.Form.CompanyName).validators = { 'required': true };

        (<DynamicField>this.Form.CompanyAddress).labelHtml = '<label>Company Address</label>';
        (<DynamicField>this.Form.CompanyAddress).label = 'Company Address';
        (<DynamicField>this.Form.CompanyAddress).validation = [Validators.required];
        (<DynamicField>this.Form.CompanyAddress).validators = { 'required': true };

        (<DynamicField>this.Form.CompanyCity).labelHtml = '<label>Company City</label>';
        (<DynamicField>this.Form.CompanyCity).label = 'Company City';
        (<DynamicField>this.Form.CompanyCity).validation = [Validators.required];
        (<DynamicField>this.Form.CompanyCity).validators = { 'required': true };

        (<DynamicField>this.Form.ContactEmail).labelHtml = '<label>User Email</label>';
        (<DynamicField>this.Form.ContactEmail).label = 'User Email';
        (<DynamicField>this.Form.ContactEmail).validation = [Validators.required];
        (<DynamicField>this.Form.ContactEmail).validators = { 'required': true };

        (<DynamicField>this.Form.ContactPhone).labelHtml = '<label>User Phone Number</label>';
        (<DynamicField>this.Form.ContactPhone).label = 'User Phone Number';
        (<DynamicField>this.Form.ContactPhone).validation = [Validators.required];
        (<DynamicField>this.Form.ContactPhone).validators = { 'required': true };

        (<DynamicField>this.Form.CompanyZip).labelHtml = '<label>Zip Code</label>';
        (<DynamicField>this.Form.CompanyZip).label = 'Zip Code';
        (<DynamicField>this.Form.CompanyZip).validation = [Validators.required];
        (<DynamicField>this.Form.CompanyZip).validators = { 'required': true };
    }
}

<form (submit)="init()" ngNativeValidate>
    <div class="row">
        <div class="col-xs-12 col-sm-2">
            <label>Units</label>
            <br />
            <div class="btn-group">
                <button type="button" class="btn" [ngClass]="{'btn-default': isImperial, 'btn-primary active': !isImperial}" (click)="setUnitOfMeasure(false)">
                    mm
                </button>
                <button type="button" class="btn" [ngClass]="{'btn-default': !isImperial, 'btn-primary active': isImperial}" (click)="setUnitOfMeasure(true)">
                    in
                </button>
            </div>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-2">
            <label>Width</label>
            <input type="number" name="width" class="form-control" [(ngModel)]="totalWidth" required [disabled]="isStarted" />
        </div>
        <div class="col-xs-6 col-sm-3 col-md-2">
            <label>Height</label>
            <input type="number" name="height" class="form-control" [(ngModel)]="totalHeight" required [disabled]="isStarted" />
        </div>
        <div [ngSwitch]="productCategory.CustomSizingRuleId" *ngIf="productCategory">
            <div *ngSwitchCase="customSizingRules.Normal">
                <div class="col-xs-6 col-sm-2 col-md-2">
                    <label>Rows</label>
                    <input type="number" name="horizontals" class="form-control" [(ngModel)]="numHorizontals" min="1" max="50" [disabled]="isStarted" />
                </div>
                <div class="col-xs-6 col-sm-2 col-md-2">
                    <label>Columns</label>
                    <input type="number" name="verticals" class="form-control" [(ngModel)]="numVerticals" min="1" max="50" [disabled]="isStarted" />
                </div>
            </div>
            <div *ngSwitchCase="customSizingRules.SingleOpening">
                <div class="col-xs-6 col-sm-2 col-md-2">
                </div>
                <div class="col-xs-6 col-sm-2 col-md-2">
                </div>
            </div>
            <div *ngSwitchCase="customSizingRules.NoStacking">
                <div class="col-xs-6 col-sm-2 col-md-2">
                    <label>Columns</label>
                    <input type="number" name="verticals" class="form-control" [(ngModel)]="numVerticals" min="1" max="50" [disabled]="isStarted" />
                </div>
                <div class="col-xs-6 col-sm-2 col-md-2">
                </div>
            </div>
            <div *ngSwitchDefault></div>
        </div>
        <div class="col-xs-3 col-sm-2 col-md-2">
            <label>&nbsp;</label>
            <br />
            <button type="submit" class="btn btn-primary btn-block" *ngIf="!isStarted">Start</button>
            <button class="btn btn-default btn-block" *ngIf="isStarted" (click)="reset()">Start Over</button>
        </div>
    </div>
</form>
<div *ngIf="rows.length">
    <div class="grid-container pull-left">
        <table class="total-product-grid" *ngIf="rows && rows.length">
            <thead>
                <tr>
                    <th colspan="2"><!-- for total & cell dimension --></th>
                    <th [attr.colspan]="rows[0].Widths.length" class="horizontal-header">
                        <div><span>{{ round(totalWidth, 2) + ' ' + getUnitOfMeasure() }}</span></div>
                    </th>
                </tr>
                <tr>
                    <th colspan="2"><!-- for total & cell dimension --></th>
                    <th *ngFor="let width of rows[0].Widths; let i = index"
                        class="frame-col-header"
                        [style.width.px]="width.View"
                    >
                        <a (click)="editCol(i)" *ngIf="colEditIndex !== i" title="{{'Edit Column ' + (i + 1)}}">{{ round(width.Actual, 2) }}</a>
                        <form (submit)="updateCol(i, colEditValue)">
                            <input
                                type="number"
                                name="{{ 'width' + i }}"
                                [(ngModel)]="colEditValue"
                                *ngIf="colEditIndex === i"
                                [style.max-width.px]="width.View"
                                (keydown)="colKeyPress(i, $event)"
                                (blur)="updateCol(i, colEditValue)"
                                [autofocus]
                            />
                            <input type="submit" style="display:none" />
                        </form>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows; let i = index" class="product-row">
                    <td [attr.rowspan]="rows.length" class="vertical-header" *ngIf="i === 0">
                        <div>{{ round(totalHeight, 2) + ' ' + getUnitOfMeasure() }}</div>
                    </td>
                    <td class="frame-row-header">
                        <a (click)="editRow(i)" *ngIf="rowEditIndex !== i" title="{{'Edit Row ' + (i + 1)}}">{{ round(row.Height.Actual, 2) }}</a>
                        <form (submit)="updateRow(i, rowEditValue)">
                            <input
                                type="number"
                                name="{{ 'height' + i }}"
                                [(ngModel)]="rowEditValue"
                                *ngIf="rowEditIndex === i"
                                (keydown)="rowKeyPress(i, $event)"
                                (blur)="updateRow(i, rowEditValue)"
                                [autofocus]
                            />
                            <input type="submit" style="display:none" />
                        </form>
                    </td>
                    <td *ngFor="let width of row.Widths" class="frame-cell" [style.width.px]="width.View" [style.height.px]="row.Height.View"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="results-container pull-left">
        <div class="panel panel-default">
            <div class="panel-heading product-performance-header">Custom Sizing Results</div>
            <div class="panel-body">
                <table class="custom-sizing-results-table">
                    <tr>
                        <td>Dimensions</td>
                        <td>{{ round(totalWidth, 2) + ' ' + getUnitOfMeasure() + ' x ' + round(totalHeight, 2) + ' ' + getUnitOfMeasure() }}</td>
                    </tr>
                    <tr>
                        <td>Total Area</td>
                        <td>{{ round(getArea(), 2) + ' ' + getAreaUnitOfMeasure() }}<sup>2</sup></td>
                    </tr>
                    <tr>
                        <td>Area Weighted U-factor</td>
                        <td style="padding:10px;padding-left:0;">
                            <button *ngIf="!uValueIsSet() && !unableToCompute" type="button" class="btn btn-xs btn-primary" (click)="calculate()" [disabled]="isCalculating">
                                {{ isCalculating ? 'Calculating...' : 'Calculate' }}
                            </button>
                            <span *ngIf="!unableToCompute">{{ getUValueDisplay() }}</span>
                            <span *ngIf="unableToCompute && !exceedsSizeLimit">
                                Weighted U-factor cannot be calculated for this product. For assistance with custom sizing or other questions about this product call 1-866-OLDCASTLE (653-2278) or <a routerLink="../contact-us">Contact OBE</a> for Support.
                            </span>
                            <span *ngIf="unableToCompute && exceedsSizeLimit">
                                For projects requiring glass sizes larger than 65 square feet, please call 1-866-OLDCASTLE (653-2278) or <a routerLink="../contact-us">Contact OBE</a> for Support.
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>

import { IEntity } from './base';

import { IUserWindowTotalProduct } from './user-window-total-product';
import { IWindowTotalProductFrameComponent } from './window-total-product-frame-component';
import { IWindowDataSet } from './window-data-set';
import { IWindowFrame } from './window-frame';
import { IWindowGlazingSystem } from './window-glazing-system';

export interface IWindowTotalProduct extends IEntity {
    Name: string;
    WindowDataSetId: number;
    WindowFrameId: number;
    WindowGlazingSystemId: number;
    Width: number;
    Height: number;
    UFactor: number;
    Shgc: number;
    VisibleTransmittance: number;
    CondensationResistance: number;

    // reverse nav
    UserWindowTotalProducts?: IUserWindowTotalProduct[];
    WindowTotalProductFrameComponents?: IWindowTotalProductFrameComponent[];

    // foreign keys
    WindowDataSet?: IWindowDataSet;
    WindowFrame?: IWindowFrame;
    WindowGlazingSystem?: IWindowGlazingSystem;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectBasicInfoComponent } from './project-basic-info/project-basic-info.component';
import { SaveToProjectComponent } from './save-to-project/save-to-project.component';
import { ChooseProjectComponent } from './choose-project/choose-project.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectService, ProjectTimelineService, UserService } from '@system-select/web-services';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedPublicModule } from '../common/shared.module';

@NgModule({
    declarations: [
        ProjectBasicInfoComponent,
        SaveToProjectComponent,
        ChooseProjectComponent,
        ProjectsListComponent,
    ],
    exports: [ProjectBasicInfoComponent, SaveToProjectComponent, ProjectsListComponent],
    imports: [CommonModule, FormsModule, SharedPublicModule, ModalModule, NgxPaginationModule],
    providers: [ProjectService, ProjectTimelineService, UserService],
})
export class ProjectModule { }

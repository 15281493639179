import { SharedModule } from 'src/app/common/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlassBuilderService } from './glass-builder.service';
import { GlassBuilderRoutingModule } from './glass-builder-routing.module';
import { GlassBuilderComponent } from './glass-builder.component';
import { SolidLayerEditComponent } from './solid-layer/solid-layer-edit.component';
import { GasLayerEditComponent } from './gas-layer/gas-layer-edit.component';
import { SolidLayerProductSelectComponent } from './solid-layer/solid-layer-product-select.component';
import {
    GasTypeService,
    GlassColorService,
    SpandrelTypeService,
    GlassTypeService,
    GlassService,
    GlazingSystemService,
    VendorService,
    SilkScreenColorService,
    SilkScreenPatternService,
    InterlayerTypeService,
} from '@system-select/web-services';
import { UnitsService } from '@system-select/common';
import { GlassSystemPerformanceComponent } from './glass-system-performance/glass-system-performance.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule as LocalCommonModule } from '@system-select/common';
import { LoginModule } from '../login/login.module';
import { CreateAccountModule } from '../create-account/create-account.module';
import { SpecSheetModule } from '../data-sheets/spec-sheet.module';
import { ProjectModule } from '../projects/project.module';
import { ModalModule } from '@mt-ng2/modal-module';
import {CdkAccordionModule} from '@angular/cdk/accordion'

@NgModule({
    declarations: [
        GlassBuilderComponent,
        SolidLayerEditComponent,
        GasLayerEditComponent,
        SolidLayerProductSelectComponent,
        GlassSystemPerformanceComponent,
    ],
    exports: [
        GlassBuilderComponent,
    ],
    imports: [
        CdkAccordionModule,
        CreateAccountModule,
        GlassBuilderRoutingModule,
        SharedModule,
        NgxLoadingModule,
        NgxPaginationModule,
        LocalCommonModule,
        LoginModule,
        SpecSheetModule,
        ProjectModule,
        ModalModule,
    ],
})
export class GlassBuilderModule {
    static forRoot(): ModuleWithProviders<GlassBuilderModule> {
        return {
            ngModule: GlassBuilderModule,
            providers: [
                GlassBuilderService,
                GasTypeService,
                GlassColorService,
                GlassTypeService,
                GlassService,
                GlazingSystemService,
                UnitsService,
                VendorService,
                SilkScreenColorService,
                SilkScreenPatternService,
                InterlayerTypeService,
                SpandrelTypeService,
            ],
        };
    }
}

import { Component } from '@angular/core';
import { IGlazingSystem, LeedReportBuilderFormSteps } from '@system-select/model';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';

@Component({
    selector: 'leed-report-glass-builder',
    templateUrl: 'leed-report-glass-builder.component.html',
})

export class LeedReportGlassBuilderComponent {
    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
    ) { }

    onGlazingSystemSelected(glazingSystem: IGlazingSystem): void {
        this.stateService.glazingSystem$.next(glazingSystem);
        this.stateService.markStepCompleted(LeedReportBuilderFormSteps.Glass);
        if (this.stateService.selectedPaths$.value.length > 1) {
            this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Metal);
        } else {
            this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Complete);
        }
    }
}

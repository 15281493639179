import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPublicUserDetail } from '../interfaces/public-user-detail';
import { IUser } from '../interfaces/user';

export interface IPublicUserDetailDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
}

export class PublicUserDetailDynamicControls {

    formGroup: string;
    users: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private publicuserdetail?: IPublicUserDetail, additionalParameters?: IPublicUserDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PublicUserDetail';
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('City') && this.publicuserdetail.City !== null ? this.publicuserdetail.City.toString() : '',
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('CompanyName') && this.publicuserdetail.CompanyName !== null ? this.publicuserdetail.CompanyName.toString() : '',
            }),
            EngagementEmailSentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Engagement Email Sent Date',
                name: 'EngagementEmailSentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.publicuserdetail && this.publicuserdetail.EngagementEmailSentDate || null,
            }),
            OptedIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Opted In',
                name: 'OptedIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('OptedIn') && this.publicuserdetail.OptedIn !== null ? this.publicuserdetail.OptedIn : false,
            }),
            Role: new DynamicField({
                formGroup: this.formGroup,
                label: 'Role',
                name: 'Role',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('Role') && this.publicuserdetail.Role !== null ? this.publicuserdetail.Role.toString() : '',
            }),
            State: new DynamicField({
                formGroup: this.formGroup,
                label: 'State',
                name: 'State',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(2) ],
                validators: { 'required': true, 'maxlength': 2 },
                value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('State') && this.publicuserdetail.State !== null ? this.publicuserdetail.State.toString() : '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.publicuserdetail && this.publicuserdetail.UserId || null,
            }),
        };

        this.View = {
            City: new DynamicLabel({
			    label: 'City',
			    value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('City') && this.publicuserdetail.City !== null ? this.publicuserdetail.City.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyName: new DynamicLabel({
			    label: 'Company Name',
			    value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('CompanyName') && this.publicuserdetail.CompanyName !== null ? this.publicuserdetail.CompanyName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            EngagementEmailSentDate: new DynamicLabel({
			    label: 'Engagement Email Sent Date',
			    value: this.publicuserdetail && this.publicuserdetail.EngagementEmailSentDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            OptedIn: new DynamicLabel({
			    label: 'Opted In',
			    value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('OptedIn') && this.publicuserdetail.OptedIn !== null ? this.publicuserdetail.OptedIn : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Role: new DynamicLabel({
			    label: 'Role',
			    value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('Role') && this.publicuserdetail.Role !== null ? this.publicuserdetail.Role.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            State: new DynamicLabel({
			    label: 'State',
			    value: this.publicuserdetail && this.publicuserdetail.hasOwnProperty('State') && this.publicuserdetail.State !== null ? this.publicuserdetail.State.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UserId: new DynamicLabel({
			    label: 'User',
			    value: getMetaItemValue(this.users as unknown as IMetaItem[], this.publicuserdetail && this.publicuserdetail.hasOwnProperty('UserId') && this.publicuserdetail.UserId !== null ? this.publicuserdetail.UserId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

<div class="well">
    <div class="btn-group">
        <button type="button" class="btn" [ngClass]="{'btn-default': laminated, 'btn-primary active': !laminated}" (click)="checkLaminatedValue()">
            Monolithic
        </button>
        <button type="button" class="btn" [ngClass]="{'btn-default': !laminated, 'btn-primary active': laminated}" (click)="checkLaminatedValue()">
            Laminated
        </button>
    </div>
    <div class="well" *ngIf="laminated">
        <label for="interlayerType">Interlayer Type: </label>
        <select class="form-control" id="interlayerTypeId" [(ngModel)]="interlayerTypeId"
            (ngModelChange)="buildLayerParams()">
            <option [value]=""></option>
            <option *ngFor="let item of interlayerTypeItems" [value]="item.Id">
                {{item.Name}}
            </option>
        </select>
    </div><br>
    <button *ngIf="interlayerTypeId" class="btn btn-default" (click)="clearLaminateFilter()">Reset Selection</button>
</div>
<div class="well">
    <label for="glassColor">Glass Color: </label><br>
    <label class="radio-inline" *ngFor="let item of glassColorItems" [ngStyle]="{'display': isGlassColorDisabled(item)}">
        <input type="radio" name="{{getGlassColorLabel(item.Id)}}" [value]="item.Id" [(ngModel)]="glassColorId"
            (ngModelChange)="buildLayerParams()"><span class="color-filter"
            [ngStyle]="{'background-color': getSwatchColor(item.Name)}"></span>
    </label>
    <div>
        <button class="btn btn-default" *ngIf="glassColorItems && glassColorItems.length > 1"
            (click)="clearGlassColorFilter()">Reset Selection</button>
    </div>
</div>
<div class="well">
    <label for="glassType">Glass Type: </label>
    <select class="form-control" id="glassType" [(ngModel)]="glassTypeId" (ngModelChange)="buildLayerParams(); checkGlassTypeFilter();">
        <option [value]="0">All Glass Types</option>
        <option *ngFor="let item of glassTypeItems" [value]="item.Id">
            {{item.Name}}
        </option>
    </select>
    <div class="well" *ngIf="glassTypeId == glassTypes.SilkScreened">
        <label for="silkScreenColor">Silk-screened Color:</label>
        <select class="form-control" id="silkScreenColorId" [(ngModel)]="silkScreenColorId"
            (ngModelChange)="buildLayerParams()">
            <option [value]=""></option>
            <option *ngFor="let item of silkScreenColorItems" [value]="item.Id">
                {{item.Name}}
            </option>
        </select>
        <label for="silkScreenPattern">Silk-screened Pattern: </label>
        <select class="form-control" id="silkScreenPatternId" [(ngModel)]="silkScreenPatternId"
            (ngModelChange)="buildLayerParams()">
            <option [value]=""></option>
            <option *ngFor="let item of silkScreenPatternItems" [value]="item.Id">
                {{item.Name}}
            </option>
        </select>
    </div>
</div>
<div class="well">
    <label>Vendors</label>
    <form>
        <label *ngFor="let item of vendorItems" class="checkbox-inline">
            <input type="checkbox" value="item.Id" (change)="updateVendorList(item.Id)">{{item.Name}}
        </label>
    </form>
</div>

import { IEntity } from './base';

import { IFramingSystemDetail } from './framing-system-detail';
import { ITotalProduct } from './total-product';
import { IFramingSystemCategory } from './framing-system-category';
import { IFramingSystemType } from './framing-system-type';

export interface IFramingSystem extends IEntity {
    Name: string;
    FramingSystemCategoryId: number;
    FramingSystemTypeId: number;

    // reverse nav
    FramingSystemDetails?: IFramingSystemDetail[];
    TotalProducts?: ITotalProduct[];

    // foreign keys
    FramingSystemCategory?: IFramingSystemCategory;
    FramingSystemType?: IFramingSystemType;
}

import {
    IEntityListConfig,
    EntityListConfig,
    EntityListColumn,
    IEntityListDynamicCellComponent,
    IEntityListComponentMembers,
} from "@mt-ng2/entity-list-module";
import { IGlass, IEntity, IWindowTotalProduct } from "@system-select/model";
import { Component, EventEmitter, Injectable, Output } from "@angular/core";
import {
    UnitsService,
    IGlazingSystemLayer,
    FormatService,
} from "@system-select/common";
import { WindowTotalProductService } from "@system-select/web-services";
import swal from "sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
import type {
    IWindowGlazingSystemWrapper,
    IWindowTotalProductsSearchItem,
} from "@system-select/model";
import { Router } from "@angular/router";
@Injectable()
export class WindowTotalProductsListConfig extends EntityListConfig {
    @Output("onItemDeleted") onItemDeleted =
        new EventEmitter<IWindowTotalProductsSearchItem>();
    @Output() onDataSheetDownload = new EventEmitter<number>();

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService
    ) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    component: SavedProductsGlassCellComponent,
                    name: "Glass System",
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: SavedProductsFrameCellComponent,
                    name: "Frame",
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (x) => {
                        return !x.WindowFrame.IsCog
                            ? `${this.unitsService
                                  .mmToIn(
                                      x.WindowGlazingSystem.WindowGlazingSystem
                                          .Thickness as number
                                  )
                                  .toFixed(3)} / ${
                                  x.WindowGlazingSystem.WindowGlazingSystem.Thickness.toFixed(
                                      3
                                  ) as number
                              }`
                            : "N/A";
                    },
                    name: "Glass Thickness (in) / (mm)",
                    sort: {
                        sortProperty:
                            "WindowGlazingSystem.WindowGlazingSystem.Thickness",
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x) => {
                        return `${this.unitsService
                            .uFactorMetricToImp(
                                x.WindowGlazingSystem.WindowGlazingSystem
                                    .UFactorWinter as number
                            )
                            .toFixed(3)}`;
                    },
                    name: "Glass Winter \n U-Factor \n (Btu/h·ft2·F)",
                    sort: {
                        sortProperty:
                            "WindowGlazingSystem.WindowGlazingSystem.UFactorWinter",
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x) => {
                        return `${
                            x.WindowGlazingSystem.WindowGlazingSystem.UFactorWinter.toFixed(
                                3
                            ) as number
                        }`;
                    },
                    name: "Glass Winter \n U-Factor \n (W/m2·K)",
                    sort: {
                        sortProperty:
                            "WindowGlazingSystem.WindowGlazingSystem.UFactorWinter",
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x) => {
                        return `${this.unitsService
                            .uFactorMetricToImp(
                                x.WindowTotalProduct.UFactor as number
                            )
                            .toFixed(3)}`;
                    },
                    name: "Total Product \n U-Factor \n (Btu/h·ft2·F)",
                    sort: { sortProperty: "WindowTotalProduct.UFactor" },
                }),
                new EntityListColumn({
                    accessorFunction: (x) => {
                        return `${
                            x.WindowTotalProduct.UFactor.toFixed(2) as number
                        }`;
                    },
                    name: "Total Product \n U-Factor \n (W/m2·K)",
                    sort: { sortProperty: "WindowTotalProduct.UFactor" },
                }),
                new EntityListColumn({
                    accessorFunction: (x) =>
                        !x.WindowFrame.IsCog
                            ? x.WindowTotalProduct.Shgc.toFixed(2)
                            : "N/A",
                    name: "Total Product SHGC",
                    sort: { sortProperty: "WindowTotalProduct.Shgc" },
                }),
                new EntityListColumn({
                    accessorFunction: (x) =>
                        !x.WindowFrame.IsCog
                            ? x.WindowTotalProduct.VisibleTransmittance.toFixed(
                                  2
                              )
                            : "N/A",
                    name: "Total Product VT",
                    sort: {
                        sortProperty: "WindowTotalProduct.VisibleTransmittance",
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x) =>
                        x.WindowTotalProduct.CondensationResistance !== null
                            ? `${Math.round(
                                  x.WindowTotalProduct
                                      .CondensationResistance as number
                              )}`
                            : "N/A",
                    name: "Total Product CR",
                    sort: {
                        sortProperty:
                            "WindowTotalProduct.CondensationResistance",
                    },
                }),
                new EntityListColumn({
                    component: SavedProductsActionComponent,
                    name: "Actions",
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });

        this.getDefaultSortProperty = () => "WindowTotalProduct.Id";
    }
}

@Component({
    template: `
        <div class="d-tc v-align-top" [style.padding-right]="'5px'"></div>
        <div class="d-tc v-align-top">
            <div *ngFor="let layer of layers">
                <span *ngIf="layer.Prefix">{{ layer.Prefix }}:</span>
                {{ layer.Name }}
            </div>
        </div>
    `,
})
export class SavedProductsGlassCellComponent
    implements IEntityListDynamicCellComponent
{
    entityListComponentMembers: IEntityListComponentMembers;
    item: any;
    glazingSystem: IWindowGlazingSystemWrapper;
    glass: IGlass;
    layers: Partial<IGlazingSystemLayer>[];
    isCog: boolean;

    constructor(private formatService: FormatService) {}

    set entity(value: IEntity) {
        this.item = value;
        this.glazingSystem = this.item.WindowGlazingSystem;
        this.isCog = this.item.WindowFrame.IsCog;
        this.glass = this.glazingSystem.WindowSolidLayers[0].Glass;
        this.layers = this.getLayers();
    }

    getLayers(): Partial<IGlazingSystemLayer>[] {
        return this.formatService.formatWindowLayers(
            this.glazingSystem.WindowSolidLayers,
            this.glazingSystem.WindowGasLayers,
            this.isCog,
            this.glazingSystem.WindowGlazingSystem.UFactorWinter
        );
    }
}

@Component({
    template: `
        <div class="d-tc v-align-top">
            <b>{{ item.WindowFrame.Name }}</b>
        </div>
    `,
})
export class SavedProductsFrameCellComponent
    implements IEntityListDynamicCellComponent
{
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.item = value;
    }

    item: any;
}

@Component({
    styles: [
        `
            .ib-container {
                white-space: nowrap;
            }
        `,
    ],
    template: `
        <div class="ib-container p-sm">
            <button
                (click)="downloadDataSheet(item.WindowTotalProduct)"
                class="btn btn-xs btn-default"
                title="Download Spec Sheet"
            >
                <i class="fa fa-file-pdf-o"></i></button
            ><button
                (click)="deleteSavedProduct(item)"
                class="btn btn-xs btn-default"
                title="Delete"
            >
                <i class="fa fa-close"></i>
            </button>
        </div>
    `,
})
export class SavedProductsActionComponent
    implements IEntityListDynamicCellComponent
{
    item: any;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(
        private windowTotalProductService: WindowTotalProductService,
        private router: Router
    ) {}

    set entity(value: IEntity) {
        this.item = value;
    }

    isEditableGlazingSystem(
        glazingSystem: IWindowGlazingSystemWrapper
    ): boolean {
        return (
            !glazingSystem.WindowSolidLayers.some((sl) => sl.Glass === null) &&
            !glazingSystem.WindowGasLayers.some((gl) => gl.GasType === null)
        );
    }

    navigateToGlassBuilder(id: number): void {
        void this.router.navigate(["/glass-builder"], {
            queryParams: { windowGlazingSystemId: id },
        });
    }

    downloadDataSheet(item: IWindowTotalProduct): void {
        (<WindowTotalProductsListConfig>(
            this.entityListComponentMembers.entityListConfig
        )).onDataSheetDownload.emit(item.Id);
    }

    deleteSavedProduct(item: IWindowTotalProductsSearchItem): void {
        void swal
            .fire(<SweetAlertOptions>{
                focusCancel: false,
                focusConfirm: false,
                showCancelButton: true,
                showCloseButton: true,
                text: "Are you sure you want to delete this from your saved products?",
                title: "Confirm",
                type: "warning",
            })
            .then((answer) => {
                if (answer.value) {
                    this.windowTotalProductService
                        .delete(item.WindowTotalProduct.Id)
                        .subscribe(() => {
                            (<WindowTotalProductsListConfig>(
                                this.entityListComponentMembers.entityListConfig
                            )).onItemDeleted.emit(item);
                        });
                }
            });
    }
}

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ModalModule } from "@mt-ng2/modal-module";
import { CommonModule as LocalCommonModule } from "@system-select/common";
import { NgxLoadingModule } from "ngx-loading";
import { NgxPaginationModule } from "ngx-pagination";
import { SharedModule } from "src/app/common/shared.module";
import { CreateAccountModule } from "../create-account/create-account.module";
import { SpecSheetModule } from "../data-sheets/spec-sheet.module";
import { LoginModule } from "../login/login.module";
import { ProjectModule } from "../projects/project.module";
import { GlazingSystemsFullDetailListComponent } from "./glazing-systems-list/glazing-systems-full-detail-list/glazing-systems-full-detail-list.component";
import {
    SavedGlazingSystemsActionComponent,
    SavedGlazingSystemsGlassCellComponent,
} from "./glazing-systems-list/glazing-systems-full-detail-list/glazing-systems-full.entity-list-config";
import { GlazingSystemsPartialDetailListComponent } from "./glazing-systems-list/glazing-systems-partial-detail-list/glazing-systems-partial-detail-list.component";
import { PerformanceTargetsComponent } from "./performance-targets/performance-targets.component";
import { ProductRecommendationRoutingModule } from "./product-recommendation-routing.module";
import { ProductRecommendationComponent } from "./product-recommendation.component";
import { ProductRecommendationService } from "./product-recommendation.service";
import { LayerFilterComponent } from "./secondary-filters/layer-filter/layer-filter.component";
import { OverallFilterComponent } from "./secondary-filters/overall-filter/overall-filter.component";
import { SecondaryFilterComponent } from "./secondary-filters/secondary-filter.component";

@NgModule({
    declarations: [
        ProductRecommendationComponent,
        PerformanceTargetsComponent,
        GlazingSystemsPartialDetailListComponent,
        GlazingSystemsFullDetailListComponent,
        SavedGlazingSystemsGlassCellComponent,
        SavedGlazingSystemsActionComponent,
        SecondaryFilterComponent,
        LayerFilterComponent,
        OverallFilterComponent,
    ],
    imports: [
        SharedModule,
        ProductRecommendationRoutingModule,
        NgxLoadingModule,
        NgxPaginationModule,
        LocalCommonModule,
        NgxSliderModule,
        LoginModule,
        CreateAccountModule,
        ProjectModule,
        SpecSheetModule,
        ModalModule,
        CdkAccordionModule,
    ],
})
export class ProductRecommendationModule {
    static forRoot(): ModuleWithProviders<ProductRecommendationModule> {
        return {
            ngModule: ProductRecommendationModule,
            providers: [ProductRecommendationService],
        };
    }
}

import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IVmFramingSystemCategory } from '../interfaces/vm-framing-system-category';

export interface IVmFramingSystemCategoryDynamicControlsParameters {
    formGroup?: string;
}

export class VmFramingSystemCategoryDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private vmframingsystemcategory?: IVmFramingSystemCategory, additionalParameters?: IVmFramingSystemCategoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'VmFramingSystemCategory';

        this.Form = {
            Id: new DynamicField({
                formGroup: this.formGroup,
                label: '',
                name: 'Id',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.vmframingsystemcategory && this.vmframingsystemcategory.Id || null,
            }),
            Level: new DynamicField({
                formGroup: this.formGroup,
                label: 'Level',
                name: 'Level',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.vmframingsystemcategory && this.vmframingsystemcategory.Level || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.vmframingsystemcategory && this.vmframingsystemcategory.hasOwnProperty('Name') && this.vmframingsystemcategory.Name !== null ? this.vmframingsystemcategory.Name.toString() : '',
            }),
            ParentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Parent',
                name: 'ParentId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.vmframingsystemcategory && this.vmframingsystemcategory.ParentId || null,
            }),
        };

        this.View = {
            Id: new DynamicLabel({
			    label: '',
			    value: this.vmframingsystemcategory && this.vmframingsystemcategory.Id || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            Level: new DynamicLabel({
			    label: 'Level',
			    value: this.vmframingsystemcategory && this.vmframingsystemcategory.Level || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.vmframingsystemcategory && this.vmframingsystemcategory.hasOwnProperty('Name') && this.vmframingsystemcategory.Name !== null ? this.vmframingsystemcategory.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ParentId: new DynamicLabel({
			    label: 'Parent',
			    value: this.vmframingsystemcategory && this.vmframingsystemcategory.ParentId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
        };

    }
}

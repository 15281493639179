import { Component, OnInit } from '@angular/core';
import { IState } from '@system-select/model';
import { CommonService } from 'src/app/common/services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ContactUsService } from '@system-select/web-services';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-contact-us',
    styles: [`
        .errortext {
            color: #a94442;
        }
        .btn-success {
            background-color: #00573d;
            border-color: #00573d;
            color: #fff;
        }

        .btn-primary {
            margin-bottom: 15px;
        }
    `],
    templateUrl: 'contact-us.component.html',
})

export class ContactUsComponent implements OnInit {
    isSendingEmail = false;
    contactUsForm: UntypedFormGroup;
    contactUsEntity: IContactUsEntity;
    states: IState[];
    phonePattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    inquiryTypes: IInquiryType[] = [
        { Id: 1, Name: 'Comment' },
        { Id: 2, Name: 'Custom product assistance' },
        { Id: 3, Name: 'Help with SystemSelect' },
        { Id: 4, Name: 'Literature request' },
        { Id: 5, Name: 'Performance targets' },
        { Id: 6, Name: 'Product assistance' },
        { Id: 7, Name: 'Pricing request' },
        { Id: 8, Name: 'Project assistance' },
        { Id: 9, Name: 'Sample request' },
        { Id: 10, Name: 'Other' },
    ];
    constructor(
        private fb: UntypedFormBuilder,
        private commonService: CommonService,
        private contactUsService: ContactUsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.commonService.getStates()
            .subscribe((states) => {
                this.states = states;
            });
        this.contactUsEntity = {
            City: '',
            Comment: '',
            Company: '',
            ConfirmEmail: '',
            Email: '',
            Extension: '',
            FirstName: '',
            Inquiry: '',
            LastName: '',
            Phone: '',
            State: '',
        };
        this.createForm();
    }

    createForm(): void {
        if (!this.contactUsForm) {
            this.contactUsForm = this.fb.group({});
        }
        this.contactUsForm.addControl('FirstName', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('LastName', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('Company', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('City', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('State', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('Phone', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('Extension', new UntypedFormControl(''));
        this.contactUsForm.addControl('Email', new UntypedFormControl('', [(ctrl: AbstractControl) => Validators.required(ctrl), (ctrl: AbstractControl) => Validators.email(ctrl)]));
        this.contactUsForm.addControl('ConfirmEmail', new UntypedFormControl('', [(ctrl: AbstractControl) => Validators.required(ctrl), (ctrl: AbstractControl) => Validators.email(ctrl)]));
        this.contactUsForm.addControl('Inquiry', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));
        this.contactUsForm.addControl('Comment', new UntypedFormControl('', (ctrl: AbstractControl) => Validators.required(ctrl)));

    }

    onSubmit(): void {
        if (this.contactUsForm.valid) {
            this.isSendingEmail = true;
            const values = this.contactUsForm.value;
            if (this.isMatchingEmails(values.email as string, values.confirmemail as string)) {
                this.contactUsEntity = this.mapObjectKeys(values as object, this.contactUsEntity);
                this.contactUsService.sendContactUsEmail(this.contactUsEntity)
                    .subscribe(() => {
                        this.isSendingEmail = false;
                        void swal.fire(<SweetAlertOptions>{
                            text: 'Your inquiry has been sent. We will contact you shortly.',
                            title: 'Thank you!',
                            type: 'success',
                        }).then((answer) => {
                            if (answer.value) {
                                void this.router.navigate(['/home']);
                            }
                        });
                    });
            } else {
                this.notificationsService.error(`Emails do not match.`);
                this.isSendingEmail = false;
            }
        }
    }

    isMatchingEmails(email: string, confirmEmail: string): boolean {
        return email === confirmEmail;
    }

    showFirstNameRequiredError(): boolean {
        const control = this.contactUsForm.get('FirstName');
        return control.touched && control.hasError('required');
    }

    showLastNameRequiredError(): boolean {
        const control = this.contactUsForm.get('LastName');
        return control.touched && control.hasError('required');
    }

    showCompanyRequiredError(): boolean {
        const control = this.contactUsForm.get('Company');
        return control.touched && control.hasError('required');
    }

    showCityRequiredError(): boolean {
        const control = this.contactUsForm.get('City');
        return control.touched && control.hasError('required');
    }

    showStateRequiredError(): boolean {
        const control = this.contactUsForm.get('State');
        return control.touched && control.hasError('required');
    }

    showPhoneRequiredError(): boolean {
        const control = this.contactUsForm.get('Phone');
        return control.touched && control.hasError('required');
    }

    showInvalidPhoneError(): boolean {
        const control = this.contactUsForm.get('Phone');
        return control.touched && control.hasError('required');
    }

    showEmailRequiredError(): boolean {
        const control = this.contactUsForm.get('Email');
        return control.touched && control.hasError('required');
    }

    showInvalidEmailError(): boolean {
        const control = this.contactUsForm.get('Email');
        return control.touched && control.hasError('email');
    }

    showConfirmEmailRequiredError(): boolean {
        const control = this.contactUsForm.get('ConfirmEmail');
        return control.touched && control.hasError('required');
    }

    showInvalidConfirmEmailError(): boolean {
        const control = this.contactUsForm.get('ConfirmEmail');
        return control.touched && control.hasError('email');
    }

    showInquiryRequiredError(): boolean {
        const control = this.contactUsForm.get('Inquiry');
        return control.touched && control.hasError('required');
    }

    showCommentRequiredError(): boolean {
        const control = this.contactUsForm.get('Comment');
        return control.touched && control.hasError('required');
    }

    mapObjectKeys(objectA: object, objectB: object): any {
        for (const prop in objectA) {
            if (prop in objectB) {
                objectB[prop] = objectA[prop];
            }
        }
        return objectB;
    }

    navigateToPrivacyPolicy(): void {
        window.open('https://obe.com/privacy-policy/');
    }

}

interface IContactUsEntity {
    FirstName: string,
    LastName: string,
    Company: string,
    City: string,
    State: string,
    Phone: string,
    Extension: string,
    Email: string,
    ConfirmEmail: string,
    Inquiry: string,
    Comment: string,
}

interface IInquiryType {
    Id: number,
    Name: string,
}

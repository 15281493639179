import { ModuleWithProviders, NgModule } from '@angular/core';
import { MyProductsComponent } from './my-products.component';
import { MyProductsRoutingModule } from './my-products-routing.module';
import { MyProductsService } from './my-products.service';
import { TotalWindowService, GlazingSystemService, ProductDocumentService } from '@system-select/web-services';
import { SavedGlazingSystemsGlassCellComponent, SavedGlazingSystemsActionComponent } from './glazing-systems-list/glazing-systems.entity-list-config';
import { GlazingSystemsListComponent } from './glazing-systems-list/glazing-systems-list.component';
import { FormatService, FractionsService } from '@system-select/common';
import { WindowTotalProductsListComponent } from './window-total-products-list/window-total-products-list.component';
import { SavedProductsActionComponent, SavedProductsFrameCellComponent, SavedProductsGlassCellComponent } from './window-total-products-list/window-total-products.entity-list-config';
import { SharedModule } from 'src/app/common/shared.module';
import { SpecSheetModule } from '../data-sheets/spec-sheet.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedPublicModule } from 'src/public/common/shared.module';
import { ProjectModule } from '../projects/project.module';
import { LeedReportsListComponent } from './leed-reports-list/leed-reports-list.component';
import { SavedLeedReportGlazingSystemsGlassCellComponent, SavedLeedReportsActionComponent, SavedLeedReportsFrameCellComponent } from './leed-reports-list/leed-reports.entity-list-config';

@NgModule({
    declarations: [
        MyProductsComponent,
        GlazingSystemsListComponent,
        SavedGlazingSystemsGlassCellComponent,
        SavedGlazingSystemsActionComponent,
        SavedProductsActionComponent,
        SavedProductsFrameCellComponent,
        SavedProductsGlassCellComponent,
        WindowTotalProductsListComponent,
        LeedReportsListComponent,
        SavedLeedReportGlazingSystemsGlassCellComponent,
        SavedLeedReportsActionComponent,
        SavedLeedReportsFrameCellComponent,
    ],
    imports: [
        MyProductsRoutingModule,
        SharedModule,
        SpecSheetModule,
        NgxLoadingModule,
        NgxPaginationModule,
        ProjectModule,
        SharedPublicModule,
    ],
    providers: [
        GlazingSystemService,
        TotalWindowService,
        FormatService,
        FractionsService,
        ProductDocumentService,
    ]
})
export class MyProductsModule {
    static forRoot(): ModuleWithProviders<MyProductsModule> {
        return {
            ngModule: MyProductsModule,
            providers: [
                MyProductsService,
            ],
        };
    }
}

import { IEntity } from './base';

import { IFrame } from './frame';
import { IFramingSystem } from './framing-system';

export interface IFramingSystemDetail extends IEntity {
    FramingSystemId: number;
    FrameId: number;
    FrameNumber: number;

    // foreign keys
    Frame?: IFrame;
    FramingSystem?: IFramingSystem;
}

import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IGlass, IEntity, IGlazingSystem } from '@system-select/model';
import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { UnitsService, FormatService, IGlazingSystemLayer } from '@system-select/common';
import swal from 'sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { GlazingSystemService } from '@system-select/web-services';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Injectable()
export class GlazingSystemsListConfig extends EntityListConfig {

    @Output('onItemDeleted') onItemDeleted = new EventEmitter<IGlazingSystem>();
    @Output() onDataSheetDownload = new EventEmitter<number>();

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
    ) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    component: SavedGlazingSystemsGlassCellComponent,
                    name: 'Glass System',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${this.unitsService.mmToIn(x.Thickness).toFixed(3)} / ${x.Thickness.toFixed(3)}`;
                    },
                    name: 'Thickness (in) / (mm)',
                    sort: { sortProperty: 'Thickness' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${Math.round(x.VisibleTransmittance * 100)}%` : 'N/A';
                    },
                    name: 'Visible Trans (%)',
                    sort: { sortProperty: 'VisibleTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${Math.round(x.VisibleReflectanceOutside * 100)}%` : 'N/A';
                    },
                    name: 'Visible Refl. Out (%)',
                    sort: { sortProperty: 'VisibleReflectanceOutside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${Math.round(x.VisibleReflectanceInside * 100)}%` : 'N/A';
                    },
                    name: 'Visible Refl. In (%)',
                    sort: { sortProperty: 'VisibleReflectanceInside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        if (isSpandrel) {
                            return 'N/A';
                        }
                        const glassLayers = x.GlazingSystemSolidLayers.map((l) => {
                            return l.Glass;
                        });
                        return this.formatService.formatUVTransmittanceDisplay(glassLayers, x.UvTransmittance);
                    },
                    name: 'UV Trans. (%)',
                    sort: { sortProperty: 'UvTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${Math.round(x.SolarTransmittance * 100)}%` : 'N/A';
                    },
                    name: 'Solar Trans. (%)',
                    sort: { sortProperty: 'SolarTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${Math.round(x.SolarReflectanceOutside * 100)}%` : 'N/A';
                    },
                    name: 'Solar Refl. Out (%)',
                    sort: { sortProperty: 'SolarReflectanceOutside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${this.unitsService.uFactorMetricToImp(x.UFactorWinter).toFixed(2)}`;
                    },
                    name: 'Winter \n U-Factor \n (Btu/h·ft2·F)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${x.UFactorWinter.toFixed(2)}`;
                    },
                    name: 'Winter \n U-Factor \n (W/m2·K)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${x.ShadingCoefficient.toFixed(2)}` : 'N/A';
                    },
                    name: 'Shading Coeff.',
                    sort: { sortProperty: 'ShadingCoefficient' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${x.Shgc.toFixed(2)}` : 'N/A';
                    },
                    name: 'Solar Heat Gain Coeff.',
                    sort: { sortProperty: 'Shgc' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const isSpandrel = x.GlazingSystemSolidLayers.filter((gsl) => gsl.SpandrelTypeId != null).length > 0;
                        return !isSpandrel ? `${x.LightToSolarGain.toFixed(2)}` : 'N/A';
                    },
                    name: 'Light to Solar Gain',
                    sort: { sortProperty: 'LightToSolarGain' },
                }),
                new EntityListColumn({
                    component: SavedGlazingSystemsActionComponent,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });

        this.getDefaultSortProperty = () => 'Id';

    }
}

@Component({
    template: `
        <div class="d-tc v-align-top" [style.padding-right]="'5px'">
            <div class="color-swatch" [style.background-color]="'rgb(' + glass.ColorR + ', ' + glass.ColorG + ', ' + glass.ColorB + ')'"></div>
        </div>
        <div class="d-tc v-align-top">
            <div *ngFor="let layer of layers">
                {{layer.Prefix}}: {{layer.Name}}
            </div>
        </div>
    `,
})
export class SavedGlazingSystemsGlassCellComponent implements IEntityListDynamicCellComponent {
    item: IGlazingSystem;
    glass: IGlass;
    entityListComponentMembers: IEntityListComponentMembers;
    layers: IGlazingSystemLayer[];

    constructor(
        private formatService: FormatService,
    ) { }

    set entity(value: IEntity) {
        this.item = value as IGlazingSystem;
        this.glass = this.item.GlazingSystemSolidLayers[0].Glass;
        this.layers = this.getLayers();
    }

    getLayers(): IGlazingSystemLayer[] {
        return this.formatService.formatGlazingLayers(this.item.GlazingSystemSolidLayers, this.item.GlazingSystemGasLayers);
    }
}

@Component({
    styles: [`
        .ib-container {
            white-space: nowrap;
        }
    `],
    template: `
        <div class="ib-container p-sm">
            <button [routerLink]="['/glass-builder']" [queryParams]="{ id: item.Id }" class="btn btn-xs btn-default" title="Edit">
                <i class="fa fa-edit"></i>
            </button><button
                (click)="getDataSheet(item)" class="btn btn-xs btn-default" title="Download Spec Sheet"
            >
                <i class="fa fa-file-pdf-o"></i>
            </button><button
                (click)="deleteGlazingSystem(item)" class="btn btn-xs btn-default" title="Delete"
            >
                <i class="fa fa-close"></i>
            </button>
        </div>
    `,
})
export class SavedGlazingSystemsActionComponent implements IEntityListDynamicCellComponent {
    item: IGlazingSystem;
    glass: IGlass;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(
        private glazingSystemService: GlazingSystemService,
        private notificationsService: NotificationsService,
    ) {
    }

    set entity(value: IEntity) {
        this.item = value as IGlazingSystem;
        this.glass = this.item.GlazingSystemSolidLayers[0].Glass;
    }

    getDataSheet(item: IGlazingSystem): void {
        (<GlazingSystemsListConfig>this.entityListComponentMembers.entityListConfig).onDataSheetDownload.emit(item.Id);
    }

    deleteGlazingSystem(item: IGlazingSystem): void {
        swal.fire(<SweetAlertOptions>{
            focusCancel: false,
            focusConfirm: false,
            showCancelButton: true,
            showCloseButton: true,
            text: 'Are you sure you want to delete this from your saved glass systems?',
            title: 'Confirm',
            type: 'warning',
        }).then((answer) => {
            if (answer.value) {
                this.glazingSystemService.delete(item.Id).subscribe(
                    () => {
                        (<GlazingSystemsListConfig>this.entityListComponentMembers.entityListConfig).onItemDeleted.emit(item);
                    }, () => {
                        this.notificationsService.error('Unable to delete glass system.  Please make sure it is not being used in one of your saved products.');
                    });
            }
        }).catch(() => null);
    }
}

import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFramingSystemDetail } from '../interfaces/framing-system-detail';
import { IFrame } from '../interfaces/frame';
import { IFramingSystem } from '../interfaces/framing-system';

export interface IFramingSystemDetailDynamicControlsParameters {
    formGroup?: string;
    framingSystems?: IFramingSystem[];
    frames?: IFrame[];
    frameNumbers?: IFrame[];
}

export class FramingSystemDetailDynamicControls {

    formGroup: string;
    framingSystems: IFramingSystem[];
    frames: IFrame[];
    frameNumbers: IFrame[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private framingsystemdetail?: IFramingSystemDetail, additionalParameters?: IFramingSystemDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FramingSystemDetail';
        this.framingSystems = additionalParameters && additionalParameters.framingSystems || undefined;
        this.frames = additionalParameters && additionalParameters.frames || undefined;
        this.frameNumbers = additionalParameters && additionalParameters.frameNumbers || undefined;

        this.Form = {
            FrameId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame',
                name: 'FrameId',
                options: this.frames,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystemdetail && this.framingsystemdetail.FrameId || null,
            }),
            FrameNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame Number',
                name: 'FrameNumber',
                options: this.frameNumbers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystemdetail && this.framingsystemdetail.FrameNumber || null,
            }),
            FramingSystemId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Framing System',
                name: 'FramingSystemId',
                options: this.framingSystems,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystemdetail && this.framingsystemdetail.FramingSystemId || null,
            }),
        };

        this.View = {
            FrameId: new DynamicLabel({
			    label: 'Frame',
			    value: getMetaItemValue(this.frames as unknown as IMetaItem[], this.framingsystemdetail && this.framingsystemdetail.hasOwnProperty('FrameId') && this.framingsystemdetail.FrameId !== null ? this.framingsystemdetail.FrameId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FrameNumber: new DynamicLabel({
			    label: 'Frame Number',
			    value: getMetaItemValue(this.frameNumbers as unknown as IMetaItem[], this.framingsystemdetail && this.framingsystemdetail.hasOwnProperty('FrameNumber') && this.framingsystemdetail.FrameNumber !== null ? this.framingsystemdetail.FrameNumber : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FramingSystemId: new DynamicLabel({
			    label: 'Framing System',
			    value: getMetaItemValue(this.framingSystems as unknown as IMetaItem[], this.framingsystemdetail && this.framingsystemdetail.hasOwnProperty('FramingSystemId') && this.framingsystemdetail.FramingSystemId !== null ? this.framingsystemdetail.FramingSystemId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

<h2>Glass Product Recommendations</h2>
<p>Select performance targets and product options on the left.
    View your recommendations on the right and click Expand Results to save products and download data sheets.</p>
<div class="row" [ngStyle]="{'display': getPartialDisplayView()}">
    <div class="col-md-6">
        <performance-targets (emitUpdatedFilter)="buildPerformanceTargetParams($event)"></performance-targets><br>
        <secondary-filter (emitUpdatedOutboardParams)="updateOutboardParams($event)"
            (emitUpdatedInboardParams)="updateInboardParams($event)"
            (emitUpdatedCenterGlassParams)="updateCenterGlassParams($event)"
            (emitUpdatedOverallParams)="updateOverallParams($event)"
            (emitTargetSystemType)="updateTargetSystemType($event)">
        </secondary-filter>
    </div>
    <div class="col-md-6">
        <div class="panel panel-default recommendations-panel">
            <div class="panel-heading product-performance-header">
                <div class="pull-left recommendations-header" style="margin-top: 1%;">
                    Your Recommendations
                </div>
                <div class="pull-right">
                    <button class="btn btn-default" (click)="toggleView()">Expand Results</button>
                </div>
                <div class="clearfix">
                </div>
            </div>
        </div>
        <glazing-systems-partial-detail-list [_performanceTargetParams]="_performanceTargetParams"
            [_outboardParams]="_outboardParams" [_inboardParams]="_inboardParams"
            [_centerGlassParams]="_centerGlassParams" [_overallParams]="_overallParams"
            [_targetSystemType]="_targetSystemType">
        </glazing-systems-partial-detail-list>
    </div>
</div>
<div class="row" [ngStyle]="{'display': getFullDisplayView()}">
    <div class="col-xs-12">
        <div class="panel panel-default recommendations-panel">
            <div class="panel-heading product-performance-header">
                <div class="pull-left recommendations-header" style="margin-top: 0.5%;">
                    Your Recommendations
                </div>
                <div class="pull-right">
                    <button class="btn btn-default" (click)="toggleView()">Show Less</button>
                </div>
                <div class="clearfix">
                </div>
            </div>
        </div>
        <glazing-systems-full-detail-list [_performanceTargetParams]="_performanceTargetParams"
            [_outboardParams]="_outboardParams" [_inboardParams]="_inboardParams"
            [_centerGlassParams]="_centerGlassParams" [_overallParams]="_overallParams"
            [_targetSystemType]="_targetSystemType">
        </glazing-systems-full-detail-list>
    </div>
</div>

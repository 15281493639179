import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
    styles: [`
        #welcome-header {
            font-size: 26px;
        }
        .action-items {
            margin-top: 20px;
        }
        .action-btn {
            margin-bottom: 20px;
            width: 222px;
        }
        .action-description-text {
            border-radius: 5%;
            text-align: left;
            box-shadow: inset -4px -1px 1px 2px rgba(0, 0, 0, 0.15);
            min-height: 190px;
            max-width: 300px
        }
        .middle-block {
            margin: 0 auto;
        }
        .well {
            width: 290px;
        }
    `],
    templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private loginService: LoginService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        // this is a hack because Breck doesn't expose an "onLogin" event,
        // it just routes to the "home" path.  So when this home component loads
        // we check if we're logged in and emit the event up to the top level app component
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            if (isAuthed) {
                this.loginService.loggedIn.emit(true);
            }
        });
    }
}

import { LoginComponent, LoginModuleConfigToken, ILoginConfig } from '@mt-ng2/login-module';
import { Component, Inject, Output, EventEmitter, Input, Injector } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { LoginService } from '../common/services/login.service';

@Component({
    selector: 'login-override',
    styles: [`
        .embedded-padding {
            display: inline-block;
            margin-top: 10px;
        }
        #feedback {
            font-size: 16px;
            color: #004d36;
        }
    `],
    templateUrl: 'login-override.component.html',
})
export class LoginOverrideComponent extends LoginComponent {
    @Input('isEmbedded') isEmbedded: boolean;
    @Output('emitSuccessfulLogin') emitSuccessfulLogin: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('emitCreateAccountSelected') emitCreateAccountSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private injector: Injector,
        private _authService: AuthService,
        private _notificationsService: NotificationsService,
        private _loginService: LoginService,
        private _router: Router,
        @Inject(LoginModuleConfigToken) private loginConfig: ILoginConfig,
    ) {
        super(injector);
        super.createForm();
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value as {
                username: string;
                password: string;
                rememberMe: boolean;
            };
            this._authService.login(values.username, values.password, values.rememberMe).subscribe(
                () => {
                    if (this.isEmbedded) {
                        this._notificationsService.success('Login Successful');
                        this._loginService.loggedIn.emit(true);
                        this.emitSuccessfulLogin.emit(true);
                    } else {
                        this._loginService.loggedIn.emit(true);
                        this._router.navigate(['/my-products']).catch(() => null);
                    }
                },
                (errorResponse) => {
                    if (errorResponse.status === 418) {
                        if (errorResponse.error === 'DomainLoginEmailSent') {
                            this._notificationsService.success('A login link has been emailed to you');
                        } else {
                            this._notificationsService.error('Email/Password is not correct');
                        }
                    }
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }

    }

    async handleCreateAccount(): Promise<void> {
        if (this.isEmbedded) {
            this.emitCreateAccountSelected.emit(true);
        } else {
            await this._router.navigate([`/create-account`]);
        }
    }
}

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AuthModule } from "@mt-ng2/auth-module";
import { CookieModule } from "@mt-ng2/cookie";
import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import { DynamicFormModule } from "@mt-ng2/dynamic-form";

import { KeyboardShortcutModule } from "@mt-ng2/keyboard-shortcuts-module";
import { LoginModuleConfigToken, MtLoginModule } from "@mt-ng2/login-module";
import { NotificationsModule } from "@mt-ng2/notifications-module";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrModule } from "ngx-toastr";
import { AppNavModule } from "src/app/nav/app-nav.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginConfigOverride } from "./common/config/login.config";

import { EnvironmentModule, EnvironmentModuleConfigToken } from "@mt-ng2/environment-module";
import { SharedPublicModule } from "./common/shared.module";
import { WildcardRoutingModule } from "./common/wildcard-routing.module";
import { CreateAccountModule } from "./create-account/create-account.module";
import { SpecSheetModule } from "./data-sheets/spec-sheet.module";
import { environment } from "./environments/environment";
import { GlassBuilderModule } from "./glass-builder/glass-builder.module";
import { LeedReportBuilderModule } from "./leed-report-builder/leed-report-builder.module";
import { LoginModule } from "./login/login.module";
import { MyProductsModule } from "./my-products/my-products.module";
import { ProductRecommendationModule } from "./product-recommendations/product-recommendation.module";
import { ProjectModule } from "./projects/project.module";
import { TotalProductModule } from "./total-product/total-product.module";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        AppNavModule.forRoot(),
        AppRoutingModule,
        AuthModule.forRoot(),
        BrowserModule,
        CookieModule.forRoot(),
        EnvironmentModule,
        GlassBuilderModule.forRoot(),
        TotalProductModule.forRoot(),
        ProductRecommendationModule.forRoot(),
        LeedReportBuilderModule.forRoot(),
        CreateAccountModule.forRoot(),
        LoginModule.forRoot(),
        HttpClientModule,
        KeyboardShortcutModule,
        MtLoginModule,
        MyProductsModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: "rgba(0,0,0,0.15)",
            primaryColour: "#ffffff",
            secondaryColour: "#bbb",
            tertiaryColour: "#ffffff",
        }),
        NotificationsModule,
        SharedPublicModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: "toast-top-left",
        }),
        WildcardRoutingModule,
        NgxPaginationModule,
        DynamicFormModule,
        SpecSheetModule,
        ProjectModule,
        MtDisableDuringHttpCallsModule.forRoot(),
    ],
    providers: [
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}

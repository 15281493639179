<base href="/" />

<div class="container">
    <div class="top-nav-container">
        <a href="https://obe.com/"
            ><img src="assets/logo.png" class="logo"
        /></a>
        <div *ngIf="isAuthenticated">
            <a href="#" (click)="logout(); $event.preventDefault()">Logout</a>
        </div>
        <div *ngIf="!isAuthenticated">
            <a [routerLink]="['/login']" title="Login">Login</a>
        </div>
    </div>

    <nav class="navbar navbar-custom">
        <div>
            <div class="navbar-header">
                <a
                    [routerLink]="['/home']"
                    class="home-link"
                    title="SystemSelect"
                    ><span class="navbar-brand"
                        >SystemSelect<span class="title-restricted"
                            >&trade;</span
                        ></span
                    ></a
                >
                <button
                    type="button"
                    class="navbar-toggle collapsed"
                    (click)="showMobileMenu = !showMobileMenu"
                >
                    <span class="sr-only">Toggle navigation</span>
                    <i class="fa fa-fw fa-xl fa-bars"></i>
                </button>
            </div>
            <div
                class="collapse navbar-collapse"
                [ngClass]="{ in: showMobileMenu }"
                id="navbar-contents"
            >
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a
                            [routerLink]="['/total-product-builder']"
                            title="Total Product Builder"
                            >Total Product Builder</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/glass-builder']"
                            title="Glass Product Builder"
                            >Glass Product Builder</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/product-recommendations']"
                            title="Glass Product Recommendations"
                            >Glass Product Recommendations</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/leed-report-builder']"
                            title="LEED Report Builder"
                            >LEED Report Builder</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/definitions']"
                            target="_blank"
                            title="Definitions"
                            >Definitions</a
                        >
                    </li>
                    <li>
                        <a [routerLink]="['/contact-us']" title="Contact Us"
                            >Contact Us</a
                        >
                    </li>
                    <li>
                        <a
                            [routerLink]="['/my-products']"
                            title="My Saved Products"
                            >My Saved Selections
                            <i class="cart fa fa-xl fa-folder-open"></i>
                            <span
                                *ngIf="productCount > 0"
                                class="badge cart-total"
                                >{{ productCount }}</span
                            ></a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div>
        <router-outlet></router-outlet>
    </div>
</div>

import { ExtraSearchParams } from '@mt-ng2/common-classes';

export interface IGlassBuilderLayerFilter {
    Layer: number,
    NumberOfLayers: number,
    VendorIds: ExtraSearchParams[],
    Laminated: boolean,
    GlassColorId?: number,
    GlassTypeId?: number,
    InterlayerTypeId?: number,
    InterlayerThickness: number,
    SilkScreenedColorId?: number,
    SilkScreenedPatternId?: number,
}

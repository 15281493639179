import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { LeedReportBuilderFormSteps } from '@system-select/model';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';
@Component({
    providers: [LeedReportBuilderInterfaceStateService],
    selector: 'leed-report-builder',
    templateUrl: 'leed-report-builder-interface.component.html',
})

export class LeedReportBuilderInterfaceComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    LeedReportBuilderFormSteps = LeedReportBuilderFormSteps;
    currentFormStep: LeedReportBuilderFormSteps;
    completedFormSteps: LeedReportBuilderFormSteps[];
    displayCustomProductInquiryForm = false;

    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
    ) { }

    ngOnInit(): void {
        this.initializeSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initializeSubscriptions(): void {
        this.subscriptions.add(
            this.stateService.currentFormStep$.subscribe((value) => {
                this.currentFormStep = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
    }

    isActiveFormStep(step: LeedReportBuilderFormSteps): boolean {
        return step === this.currentFormStep;
    }

    onDisplayCustomProductInquiryForm(): void {
        this.displayCustomProductInquiryForm = true;
    }

    getInquiryFormButtonVisibility(): string {
        return this.displayCustomProductInquiryForm ? 'hidden' : 'visible';
    }

    onProductInquiryFormCancelClick(): void {
        this.displayCustomProductInquiryForm = false;
    }

}

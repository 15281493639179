import { NgModule } from '@angular/core';
import { SpecSheetInputFormComponent } from './spec-sheet-input-form/spec-sheet-input-form.component';
import { SpecSheetEmailFormComponent } from './spec-sheet-email-form/spec-sheet-email-form.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { ProductsListSheetEmailFormComponent } from './products-list-sheet-email-form/products-list-sheet-email-form.component';
import { ModalModule } from '@mt-ng2/modal-module';

@NgModule({
    declarations: [
        SpecSheetInputFormComponent,
        SpecSheetEmailFormComponent,
        ProductsListSheetEmailFormComponent,
    ],
    exports: [
        SpecSheetInputFormComponent,
        SpecSheetEmailFormComponent,
        ProductsListSheetEmailFormComponent,
    ],
    imports: [CommonModule, FormsModule, NgxLoadingModule, ModalModule],
    providers: [],
})
export class SpecSheetModule { }

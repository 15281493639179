import { ModuleWithProviders, NgModule } from "@angular/core";

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import { ModalModule } from "@mt-ng2/modal-module";
import { MultiselectControlModule } from "@mt-ng2/multiselect-control";
import { FormatService, CommonModule as LocalCommonModule, UnitsService } from "@system-select/common";
import {
    ClimateZoneService,
    FramingSystemCategoryService,
    GlazingSystemService,
    ProductCategoryService,
    SightlineService,
    SystemDepthService,
    TotalWindowService,
    UserService,
    UserWindowTotalProductService,
    WindowFramesService,
    WindowGlazingSystemsService,
    WindowTotalProductService,
} from "@system-select/web-services";
import { NgxLoadingModule } from "ngx-loading";
import { NgxPaginationModule } from "ngx-pagination";
import { SharedPublicModule } from "../common/shared.module";
import { CreateAccountModule } from "../create-account/create-account.module";
import { SpecSheetModule } from "../data-sheets/spec-sheet.module";
import { LoginModule } from "../login/login.module";
import { ProjectModule } from "../projects/project.module";
import { ClimateZoneFilterComponent } from "./climate-zone-filter/climate-zone-filter.component";
import { TotalProductCustomSizingComponent } from "./custom-sizing/custom-sizing.component";
import { PerformanceSummaryComponent } from "./performance-summary/performance-summary.component";
import { TotalProductBuilderComponent } from "./total-product-builder/total-product-builder.component";
import { TotalProductRoutingModule } from "./total-product-routing.module";
@NgModule({
    declarations: [
        TotalProductBuilderComponent,
        ClimateZoneFilterComponent,
        PerformanceSummaryComponent,
        TotalProductCustomSizingComponent,
    ],
    imports: [
        SharedPublicModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        TotalProductRoutingModule,
        NgxLoadingModule,
        LocalCommonModule,
        NgxSliderModule,
        NgxPaginationModule,
        LoginModule,
        CreateAccountModule,
        MtDisableDuringHttpCallsModule,
        SpecSheetModule,
        ProjectModule,
        ModalModule,
        ReactiveFormsModule,
        MultiselectControlModule,
    ],
    providers: [
        FramingSystemCategoryService,
        GlazingSystemService,
        TotalWindowService,
        UnitsService,
        WindowGlazingSystemsService,
        WindowFramesService,
        ClimateZoneService,
        WindowTotalProductService,
        UserWindowTotalProductService,
        UserService,
        ProductCategoryService,
        FormatService,
        SystemDepthService,
        SightlineService,
    ],
})
export class TotalProductModule {
    static forRoot(): ModuleWithProviders<TotalProductModule> {
        return {
            ngModule: TotalProductModule,
            providers: [],
        };
    }
}

import { IEntity } from './base';

import { IGlazingSystem } from './glazing-system';
import { ILeedReport } from './leed-report';
import { IUserWindowTotalProduct } from './user-window-total-product';
import { IProjectTimeline } from './project-timeline';
import { IState } from './state';
import { IUser } from './user';

export interface IProject extends IEntity {
    Name: string;
    ContactName?: string;
    ContactEmail?: string;
    Description?: string;
    Address?: string;
    City?: string;
    StateCode?: string;
    Zip?: string;
    ProjectTimelineId?: number;
    Default: boolean;
    UserId: number;
    DateCreated?: Date;
    ContactPhone?: string;
    CompanyName?: string;
    CompanyType?: string;
    CompanyAddress?: string;
    CompanyCity?: string;
    CompanyStateCode?: string;
    CompanyZip?: string;

    // reverse nav
    GlazingSystems?: IGlazingSystem[];
    LeedReports?: ILeedReport[];
    UserWindowTotalProducts?: IUserWindowTotalProduct[];

    // foreign keys
    ProjectTimeline?: IProjectTimeline;
    State?: IState;
    States2?: IState;
    User?: IUser;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LeedReportBuilderFormSteps, IProject, IGlazingSystem, IWindowFrame } from '@system-select/model';
@Injectable()
export class LeedReportBuilderInterfaceStateService {
    private _selectedPaths: BehaviorSubject<LeedReportBuilderFormSteps[]> = new BehaviorSubject<LeedReportBuilderFormSteps[]>([]);
    public get selectedPaths$(): BehaviorSubject<LeedReportBuilderFormSteps[]> {
        return this._selectedPaths;
    }
    // Need to track this entity in case the user isn't authenticated
    private _project: BehaviorSubject<IProject> = new BehaviorSubject<IProject>(null);
    public get project$(): BehaviorSubject<IProject> {
        return this._project;
    }

    private _glazingSystem: BehaviorSubject<IGlazingSystem> = new BehaviorSubject<IGlazingSystem>(null);
    public get glazingSystem$(): BehaviorSubject<IGlazingSystem> {
        return this._glazingSystem;
    }

    private _windowFrame: BehaviorSubject<IWindowFrame> = new BehaviorSubject<IWindowFrame>(null);
    public get windowFrame$(): BehaviorSubject<IWindowFrame> {
        return this._windowFrame;
    }

    private _currentFormStep: BehaviorSubject<LeedReportBuilderFormSteps> = new BehaviorSubject<LeedReportBuilderFormSteps>(LeedReportBuilderFormSteps.Project);
    public get currentFormStep$(): BehaviorSubject<LeedReportBuilderFormSteps> {
        return this._currentFormStep;
    }

    private _completedFormSteps: BehaviorSubject<LeedReportBuilderFormSteps[]> = new BehaviorSubject<LeedReportBuilderFormSteps[]>([]);
    public get completedFormSteps$(): BehaviorSubject<LeedReportBuilderFormSteps[]> {
        return this._completedFormSteps;
    }

    public markStepCompleted(step: LeedReportBuilderFormSteps): void {
        let steps = this.completedFormSteps$.value;
        if (!steps) {
            steps = [];
        }
        if (steps.indexOf(step) === -1) {
            steps.push(step);
        }
        this.completedFormSteps$.next(steps);
    }

    public reset(): void {
        this.selectedPaths$.next([]);
        this.project$.next(null);
        this.glazingSystem$.next(null);
        this.windowFrame$.next(null);
        this.completedFormSteps$.next([]);
        this.currentFormStep$.next(LeedReportBuilderFormSteps.Project);
    }

    constructor(
    ) { }

}

import { IGlass } from './glass';
import { IGlazingSystem } from './glazing-system';
import { ISpandrelType } from './spandrel-type';

export interface IGlazingSystemSolidLayer {
    GlazingSystemId: number;
    GlassId: number;
    Layer: number;
    SpandrelTypeId?: number;
    SpandrelColor?: string;

    // foreign keys
    Glass?: IGlass;
    GlazingSystem?: IGlazingSystem;
    SpandrelType?: ISpandrelType;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@mt-ng2/auth-module';
import { CreateAccountComponent } from './create-account.component';

const routes: Routes = [
    { path: 'create-account', component: CreateAccountComponent, canActivate: [AuthGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(routes),
    ],
})

export class CreateAccountRoutingModule {}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEmailParams, MyProductsListTypes } from '@system-select/model';
import { GlazingSystemService, WindowTotalProductService } from '@system-select/web-services';

@Component({
    selector: 'products-list-sheet-email-form',
    styles: [`
    .form-error {
        color: red;
    }
    .form-control {
        border-radius: 0;
        margin-top: 10px;
    }
    .btn-send {
        margin-top: 10px;
    }
    `],
    templateUrl: 'products-list-sheet-email-form.component.html',
})

export class ProductsListSheetEmailFormComponent implements OnInit, OnChanges {
    @Input() displayForm: boolean;
    @Input() productListType: MyProductsListTypes;
    @Input() selectedProjectId: number | null;
    @Output() emitSuccessfulEmail: EventEmitter<boolean> = new EventEmitter<boolean>();
    emailFormModalApi: IModalWrapperApi;
    loadingResult = false;
    emailParams: IEmailParams;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(
        private glazingSystemService: GlazingSystemService,
        private notificationsService: NotificationsService,
        private windowTotalProductService: WindowTotalProductService,
    ) { }

    ngOnInit(): void {
        this.emailParams = {
            Message: '',
            Subject: '',
            To: '',
            ProjectId: this.selectedProjectId,
        };
    }

    ngOnChanges(): void {
        if (this.displayForm) {
            setTimeout(() => {
                this.emailFormModalApi.show();
            }, 0);
        }
        if (this.selectedProjectId != null) {
            this.emailParams.ProjectId = this.selectedProjectId;
        }
    }

    save(): void {
        this.loadingResult = true;
        if (this.productListType === MyProductsListTypes.GlassProducts) {
            this.sendGlazingListSheetEmail();
        } else if (this.productListType === MyProductsListTypes.TotalProducts) {
            this.sendTotalProductListSheetEmail();
        }
    }

    sendGlazingListSheetEmail(): void {
        this.glazingSystemService.sendGlazingListSheetEmail(this.emailParams)
            .subscribe(() => {
                this.success();
            });
    }

    sendTotalProductListSheetEmail(): void {
        this.windowTotalProductService.sendTotalProductListSheetEmail(this.emailParams)
            .subscribe(() => {
                this.success();
            });
    }

    success(): void {
        this.loadingResult = false;
        this.emailFormModalApi.close();
        this.notificationsService.success('Email sent successfully.');
        this.emitSuccessfulEmail.emit(true);
    }
}

import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IProject } from '../interfaces/project';
import { IProjectTimeline } from '../interfaces/project-timeline';
import { IUser } from '../interfaces/user';

export interface IProjectDynamicControlsParameters {
    formGroup?: string;
    projectTimelines?: IProjectTimeline[];
    users?: IUser[];
}

export class ProjectDynamicControls {

    formGroup: string;
    projectTimelines: IProjectTimeline[];
    users: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private project?: IProject, additionalParameters?: IProjectDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Project';
        this.projectTimelines = additionalParameters && additionalParameters.projectTimelines || undefined;
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            Address: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'Address',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('Address') && this.project.Address !== null ? this.project.Address.toString() : '',
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('City') && this.project.City !== null ? this.project.City.toString() : '',
            }),
            CompanyAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Address',
                name: 'CompanyAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('CompanyAddress') && this.project.CompanyAddress !== null ? this.project.CompanyAddress.toString() : '',
            }),
            CompanyCity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company City',
                name: 'CompanyCity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('CompanyCity') && this.project.CompanyCity !== null ? this.project.CompanyCity.toString() : '',
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.project && this.project.hasOwnProperty('CompanyName') && this.project.CompanyName !== null ? this.project.CompanyName.toString() : '',
            }),
            CompanyStateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company State Code',
                name: 'CompanyStateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(2) ],
                validators: { 'maxlength': 2 },
                value: this.project && this.project.hasOwnProperty('CompanyStateCode') && this.project.CompanyStateCode !== null ? this.project.CompanyStateCode.toString() : '',
            }),
            CompanyType: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Type',
                name: 'CompanyType',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('CompanyType') && this.project.CompanyType !== null ? this.project.CompanyType.toString() : '',
            }),
            CompanyZip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Zip',
                name: 'CompanyZip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.project && this.project.hasOwnProperty('CompanyZip') && this.project.CompanyZip !== null ? this.project.CompanyZip.toString() : '',
            }),
            ContactEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Email',
                name: 'ContactEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.project && this.project.hasOwnProperty('ContactEmail') && this.project.ContactEmail !== null ? this.project.ContactEmail.toString() : '',
            }),
            ContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Name',
                name: 'ContactName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.project && this.project.hasOwnProperty('ContactName') && this.project.ContactName !== null ? this.project.ContactName.toString() : '',
            }),
            ContactPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Phone',
                name: 'ContactPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.project && this.project.hasOwnProperty('ContactPhone') && this.project.ContactPhone !== null ? this.project.ContactPhone.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.project && this.project.DateCreated || null,
            }),
            Default: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default',
                name: 'Default',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.project && this.project.hasOwnProperty('Default') && this.project.Default !== null ? this.project.Default : false,
            }),
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.project && this.project.hasOwnProperty('Description') && this.project.Description !== null ? this.project.Description.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.project && this.project.hasOwnProperty('Name') && this.project.Name !== null ? this.project.Name.toString() : '',
            }),
            ProjectTimelineId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Project Timeline',
                name: 'ProjectTimelineId',
                options: this.projectTimelines,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.project && this.project.ProjectTimelineId || null,
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State Code',
                name: 'StateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(2) ],
                validators: { 'maxlength': 2 },
                value: this.project && this.project.hasOwnProperty('StateCode') && this.project.StateCode !== null ? this.project.StateCode.toString() : '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.project && this.project.UserId || null,
            }),
            Zip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Zip',
                name: 'Zip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.project && this.project.hasOwnProperty('Zip') && this.project.Zip !== null ? this.project.Zip.toString() : '',
            }),
        };

        this.View = {
            Address: new DynamicLabel({
			    label: 'Address',
			    value: this.project && this.project.hasOwnProperty('Address') && this.project.Address !== null ? this.project.Address.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            City: new DynamicLabel({
			    label: 'City',
			    value: this.project && this.project.hasOwnProperty('City') && this.project.City !== null ? this.project.City.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyAddress: new DynamicLabel({
			    label: 'Company Address',
			    value: this.project && this.project.hasOwnProperty('CompanyAddress') && this.project.CompanyAddress !== null ? this.project.CompanyAddress.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyCity: new DynamicLabel({
			    label: 'Company City',
			    value: this.project && this.project.hasOwnProperty('CompanyCity') && this.project.CompanyCity !== null ? this.project.CompanyCity.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyName: new DynamicLabel({
			    label: 'Company Name',
			    value: this.project && this.project.hasOwnProperty('CompanyName') && this.project.CompanyName !== null ? this.project.CompanyName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyStateCode: new DynamicLabel({
			    label: 'Company State Code',
			    value: this.project && this.project.hasOwnProperty('CompanyStateCode') && this.project.CompanyStateCode !== null ? this.project.CompanyStateCode.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyType: new DynamicLabel({
			    label: 'Company Type',
			    value: this.project && this.project.hasOwnProperty('CompanyType') && this.project.CompanyType !== null ? this.project.CompanyType.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompanyZip: new DynamicLabel({
			    label: 'Company Zip',
			    value: this.project && this.project.hasOwnProperty('CompanyZip') && this.project.CompanyZip !== null ? this.project.CompanyZip.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactEmail: new DynamicLabel({
			    label: 'Contact Email',
			    value: this.project && this.project.hasOwnProperty('ContactEmail') && this.project.ContactEmail !== null ? this.project.ContactEmail.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactName: new DynamicLabel({
			    label: 'Contact Name',
			    value: this.project && this.project.hasOwnProperty('ContactName') && this.project.ContactName !== null ? this.project.ContactName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContactPhone: new DynamicLabel({
			    label: 'Contact Phone',
			    value: this.project && this.project.hasOwnProperty('ContactPhone') && this.project.ContactPhone !== null ? this.project.ContactPhone.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.project && this.project.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Default: new DynamicLabel({
			    label: 'Default',
			    value: this.project && this.project.hasOwnProperty('Default') && this.project.Default !== null ? this.project.Default : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Description: new DynamicLabel({
			    label: 'Description',
			    value: this.project && this.project.hasOwnProperty('Description') && this.project.Description !== null ? this.project.Description.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.project && this.project.hasOwnProperty('Name') && this.project.Name !== null ? this.project.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ProjectTimelineId: new DynamicLabel({
			    label: 'Project Timeline',
			    value: getMetaItemValue(this.projectTimelines as unknown as IMetaItem[], this.project && this.project.hasOwnProperty('ProjectTimelineId') && this.project.ProjectTimelineId !== null ? this.project.ProjectTimelineId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StateCode: new DynamicLabel({
			    label: 'State Code',
			    value: this.project && this.project.hasOwnProperty('StateCode') && this.project.StateCode !== null ? this.project.StateCode.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UserId: new DynamicLabel({
			    label: 'User',
			    value: getMetaItemValue(this.users as unknown as IMetaItem[], this.project && this.project.hasOwnProperty('UserId') && this.project.UserId !== null ? this.project.UserId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Zip: new DynamicLabel({
			    label: 'Zip',
			    value: this.project && this.project.hasOwnProperty('Zip') && this.project.Zip !== null ? this.project.Zip.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@mt-ng2/auth-module';
import { ProductRecommendationComponent } from './product-recommendation.component';

const productRecommendationRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ProductRecommendationComponent,
        path: 'product-recommendations',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            productRecommendationRoutes,
        ),
    ],
})
export class ProductRecommendationRoutingModule {

}

import { IEntity } from './base';

import { IGlass } from './glass';

export interface IInterlayerType extends IEntity {
    Name: string;

    // reverse nav
    Glasses?: IGlass[];
}

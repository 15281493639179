import { IEntity, IDocument } from './base';

import { ILeedReport } from './leed-report';
import { IWindowFrameProductCategory } from './window-frame-product-category';
import { IWindowTotalProduct } from './window-total-product';
import { IProductCategory } from './product-category';
import { ISightline } from './sightline';
import { ISystemDepth } from './system-depth';
import { IWindowDataSet } from './window-data-set';

export interface IWindowFrame extends IEntity {
    Name: string;
    WindowDataSetId: number;
    IsCog: boolean;
    HasCustomSizing: boolean;
    ProductCategoryId?: number;
    SystemDepthId?: number;
    SightlineId?: number;
    LeedPoints?: number;
    SiteLink?: string;
    Archived: boolean;

    // reverse nav
    Documents?: IDocument[];
    LeedReports?: ILeedReport[];
    WindowFrameProductCategories?: IWindowFrameProductCategory[];
    WindowTotalProducts?: IWindowTotalProduct[];

    // foreign keys
    ProductCategory?: IProductCategory;
    Sightline?: ISightline;
    SystemDepth?: ISystemDepth;
    WindowDataSet?: IWindowDataSet;
}

import { IUser } from './user';

export interface IPublicUserDetail {
    UserId: number;
    CompanyName: string;
    City: string;
    State: string;
    Role: string;
    OptedIn: boolean;
    EngagementEmailSentDate?: Date;

    // foreign keys
    User?: IUser;
}

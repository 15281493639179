import { IEntity } from './base';

import { IWindowFrame } from './window-frame';
import { IWindowFrameComponent } from './window-frame-component';
import { IWindowGlazingSystem } from './window-glazing-system';
import { IWindowTotalProduct } from './window-total-product';

export interface IWindowDataSet extends IEntity {
    Name: string;
    PublishDate?: Date;
    FileName?: string;

    // reverse nav
    WindowFrames?: IWindowFrame[];
    WindowFrameComponents?: IWindowFrameComponent[];
    WindowGlazingSystems?: IWindowGlazingSystem[];
    WindowTotalProducts?: IWindowTotalProduct[];
}

import { IEntity } from './base';

import { IFramingSystem } from './framing-system';
import { IFramingSystemTypeLayout } from './framing-system-type-layout';

export interface IFramingSystemType extends IEntity {
    Name: string;

    // reverse nav
    FramingSystems?: IFramingSystem[];
    FramingSystemTypeLayouts?: IFramingSystemTypeLayout[];
}

<h3>Total Product Performance Summary</h3>
<h5>{{ selectedFrame.Name }}</h5>
<div class="row p-t-lg">
    <div class="col-md-12">
        <div class="results-panel">
            <div class="panel panel-default">
                <div class="panel-heading product-performance-header">
                    Total Product Results, based on NFRC Standard Sizing
                </div>
                <div class="panel-body">
                    <div *ngIf="totalWindowResult">
                        <table class="performance-table">
                            <tr>
                                <th>U-factor (Btu/h·ft2·F)</th>
                                <th>U-factor (W/m2·K)</th>
                                <th>SHGC</th>
                                <th>VT</th>
                                <th>CR</th>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        uFactorMetricToImp(
                                            totalWindowResult.UValue
                                        ).toFixed(3)
                                    }}
                                </td>
                                <td>
                                    {{
                                        totalWindowResult.UValue
                                            | number: "1.3-3"
                                    }}
                                </td>
                                <td>
                                    {{
                                        !isCog
                                            ? totalWindowResult.Shgc.toFixed(3)
                                            : "N/A"
                                    }}
                                </td>
                                <td>
                                    {{
                                        !isCog
                                            ? totalWindowResult.VisibleTransmittance.toFixed(
                                                  2
                                              )
                                            : "N/A"
                                    }}
                                </td>
                                <td>
                                    {{
                                        totalWindowResult.CondensationResistance
                                            ? (totalWindowResult.CondensationResistance
                                              | number: "1.0-0")
                                            : "N/A"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <span class="font-style-italic" *ngIf="!totalWindowResult"
                        >Choose a frame and glass product to receive performance
                        results.</span
                    >
                </div>
            </div>
            <ngx-loading [show]="loadingResult"></ngx-loading>
        </div>
    </div>
</div>

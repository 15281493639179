<h1>LEED Report Builder</h1>
<ng-container *ngIf="isActiveFormStep(LeedReportBuilderFormSteps.Project)">
    <leed-report-project></leed-report-project>
</ng-container>
<ng-container *ngIf="isActiveFormStep(LeedReportBuilderFormSteps.ChoosePath)">
    <leed-report-choose-path></leed-report-choose-path>
</ng-container>
<ng-container *ngIf="isActiveFormStep(LeedReportBuilderFormSteps.Glass)">
    <leed-report-glass-builder></leed-report-glass-builder>
</ng-container>
<ng-container *ngIf="isActiveFormStep(LeedReportBuilderFormSteps.Metal)">
    <leed-report-metal-builder></leed-report-metal-builder>
</ng-container>
<ng-container *ngIf="isActiveFormStep(LeedReportBuilderFormSteps.Complete)">
    <leed-report-complete></leed-report-complete>
</ng-container>
<button
    id="custom-product-inquiry-help-btn"
    class="btn btn-primary"
    (click)="onDisplayCustomProductInquiryForm()"
    [ngStyle]="{ visibility: getInquiryFormButtonVisibility() }"
>
    Get Help &nbsp; <i class="fa fa-lg fa-envelope-o"></i>
</button>
<custom-product-inquiry
    [displayForm]="displayCustomProductInquiryForm"
    (onCancelClick)="onProductInquiryFormCancelClick()"
></custom-product-inquiry>
<br />

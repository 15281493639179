import { IEntity } from './base';

import { IFramingSystem } from './framing-system';

export interface IFramingSystemCategory extends IEntity {
    ParentId?: number;
    Name: string;

    // reverse nav
    FramingSystems?: IFramingSystem[];
    FramingSystemCategories?: IFramingSystemCategory[];

    // foreign keys
    FramingSystemCategory?: IFramingSystemCategory;
}

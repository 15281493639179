export const environment = {
    apiVersion: '1',
    appName: 'SystemSelect',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 2,
    authSecretVariable: 'pPfNkRcxaAvkVQ23RxgTp6hW',
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    production: false,
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
};

import { Component, Input } from '@angular/core';
import { ITotalWindowPerformance, IGlazingPerformance } from '@system-select/web-services';
import { UnitsService, FormatService } from '@system-select/common';
import { IGlass, IWindowFrame } from '@system-select/model';

@Component({
    selector: 'performance-summary',
    styles: [`
    h3 {
        display: inline-block;
    }
    .btn {
        margin-left: 20px;
        margin-bottom: 10px;
    }
    `],
    templateUrl: 'performance-summary.component.html',
})

export class PerformanceSummaryComponent {
    @Input('glazingResult') glazingResult: IGlazingPerformance;
    @Input() selectedFrame: IWindowFrame;
    @Input('totalWindowResult') totalWindowResult: ITotalWindowPerformance;
    @Input('glassLayers') glassLayers: IGlass[];
    @Input() isCog: boolean;
    loadingResult = false;

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
    ) { }

    mmToIn(mm: number): number {
        return this.unitsService.mmToIn(mm);
    }

    uFactorMetricToImp(uFactor: number): number {
        return this.unitsService.uFactorMetricToImp(uFactor);
    }

    formatUvTransmittance(uvTransmittance: number): string {
        if (this.glassLayers.length > 0) {
            return this.formatService.formatUVTransmittanceDisplay(this.glassLayers, uvTransmittance);
        }
        return '';
    }
}

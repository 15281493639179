import { IGasType } from './gas-type';
import { IGlazingSystem } from './glazing-system';

export interface IGlazingSystemGasLayer {
    GlazingSystemId: number;
    GasTypeId: number;
    Layer: number;
    Thickness: number;

    // foreign keys
    GasType?: IGasType;
    GlazingSystem?: IGlazingSystem;
}

import { Component, OnInit, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { IProject } from '@system-select/model';
import { ProjectService } from '@system-select/web-services';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'choose-project',
    styles: [`
        .form {
            font-size: 14px;
        }
        .list-group-item:hover {
            background: #004d36;
            color: white;
        }
        .list-group-item {
            cursor: pointer;
        }
    `],
    templateUrl: 'choose-project.component.html',
})

export class ChooseProjectComponent implements OnInit, OnChanges {
    chooseProjectModalApi: IModalWrapperApi;
    @Output() emitProjectSelection: EventEmitter<IProject> = new EventEmitter<IProject>();
    @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();
    @Input() displayForm: boolean;
    projects: IProject[];
    @Input() isAuthenticated: boolean;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        didClose: () => this.onModalClosed.emit(),
        showCloseButton: true,
    };

    constructor(
        private projectService: ProjectService,
    ) { }

    ngOnInit(): void {
        this.getProjects();
    }

    getProjects(): void {
        this.projectService.getAll()
            .subscribe((projects) => this.projects = projects);
    }

    ngOnChanges(): void {
        if (this.displayForm) {
            setTimeout(() => {
                this.chooseProjectModalApi.show();
            }, 0);
        }
        if (this.isAuthenticated) {
            this.getProjects();
        }
    }

    useProject(project: IProject): void {
        this.emitProjectSelection.emit(project);
        this.chooseProjectModalApi.close();
    }
}

<h2>Total Product Builder</h2>
<climate-zone-filter
    (emitUpdatedFilter)="onFilterUpdate($event, false)"
    (emitNullSelection)="clearSelectedProducts(true)"
    (onProductCategoryUpdate)="onProductCategoryUpdate($event)"
></climate-zone-filter>

<div style="margin-bottom: 15px">
    <div class="p-t-lg" *ngIf="hasInitialSearch">
        <h3 id="framing-preset-header">
            4. Select a compatible framing preset:
        </h3>
        <div class="col-framing-preset">
            <div>
                <mt-multiselect
                    [disabled]="selectedFrameId"
                    [items]="productCategoryItems"
                    [maxToShowInSelectedText]="2"
                    entity="Product Category"
                    placeholder="Product Categories"
                    (selectionChanged)="
                        onSelectedProductCategoriesUpdate($event)
                    "
                ></mt-multiselect>
            </div>
            <div>
                <mt-multiselect
                    [disabled]="selectedFrameId"
                    [items]="sightlineItems"
                    [maxToShowInSelectedText]="3"
                    entity="Sightline"
                    placeholder="Sightlines"
                    (selectionChanged)="onSelectedSightlinesUpdate($event)"
                ></mt-multiselect>
            </div>
            <div>
                <mt-multiselect
                    [disabled]="selectedFrameId"
                    [items]="systemDepthItems"
                    [maxToShowInSelectedText]="3"
                    entity="System Depth"
                    placeholder="System Depths"
                    (selectionChanged)="onSelectedSystemDepthsUpdate($event)"
                ></mt-multiselect>
            </div>
            <div>
                <div class="input-group framing-search-input-group">
                    <input
                        type="text"
                        [formControl]="searchControl"
                        class="form-control framing-preset-search"
                        placeholder="Search for Framing System..."
                    />
                    <span class="input-group-btn">
                        <button
                            type="button"
                            (click)="clearSearch()"
                            class="btn btn-default btn-nohover btn-flat"
                        >
                            <i class="fa fa-sm fa-remove" aria-hidden="true"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <table class="table table-hover framing-system-table">
                    <thead>
                        <tr>
                            <th>Framing System (and properties)</th>
                            <th class="text-center">Selection</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let frame of windowFrames
                                    | paginate
                                        : {
                                            itemsPerPage: 10,
                                            currentPage: frameCurrentPage,
                                            id: 'frame-pagination'
                                        }
                            "
                            [ngClass]="{ selected: frame.Id === selectedFrameId }"
                            (click)="
                                selectedFrameId = frame.Id;
                                getTotalProducts(currentFilter)
                            "
                        >
                            <td>
                                <div>
                                    <div class="frame-system-name">
                                        <div>
                                            <div>{{ frame.Name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center" style="vertical-align: inherit">
                                <input
                                    type="radio"
                                    [checked]="frame.Id === selectedFrameId"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <ngx-loading [show]="loadingFrames"></ngx-loading>
                    <a
                        *ngIf="selectedFrameId"
                        (click)="clearSelectedFrame(); $event.preventDefault()"
                    >
                        <button class="btn btn-default clear-selection">
                            Clear Selected
                        </button>
                    </a>
                    <pagination-controls
                        class="product-pagination"
                        (pageChange)="frameCurrentPage = $event"
                        id="frame-pagination"
                    ></pagination-controls>
                </table>
            </div>
        </div>
    </div>
    <div
        *ngIf="!windowFrames.length && hasInitialSearch"
        style="margin-top: 15px"
    >
        The options you have selected yield no results for this zone, please
        adjust your selections, call 1-866-OLDCASTLE (653-2278), or
        <a [routerLink]="[]" (click)="navigateToContactUs()">contact us</a> for
        additional assistance.
    </div>
    <hr />
    <div
        *ngIf="
            (selectedFrameId && windowGlazingSystems.length > 0) ||
            loadingGlazingSystems
        "
        class="row p-t-lg"
    >
        <div class="col-md-12">
            <h3>5. Select a compatible glass product:</h3>
        </div>
        <div class="col-md-8">
            <table class="table table-hover framing-system-table">
                <thead>
                    <tr>
                        <th>Glass Product (and properties)</th>
                        <th class="text-center">Selection</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let system of windowGlazingSystems
                                | paginate
                                    : {
                                          itemsPerPage: 10,
                                          currentPage: glazingSystemCurrentPage,
                                          id: 'glazing-system-pagination'
                                      }
                        "
                        [ngClass]="{
                            selected:
                                selectedGlazingSystemId ===
                                system.WindowGlazingSystem.Id
                        }"
                        (click)="
                            selectedGlazingSystemId =
                                system.WindowGlazingSystem.Id;
                            getTotalProducts(currentFilter)
                        "
                    >
                        <td>
                            <div>
                                <div class="frame-system-name">
                                    <ng-container
                                        *ngIf="
                                            !isGenericCogSystem(
                                                system.WindowGlazingSystem
                                            )
                                        "
                                    >
                                        <div
                                            *ngFor="
                                                let layer of system.WindowSolidLayers;
                                                let i = index
                                            "
                                        >
                                            <div *ngIf="i != 0 && !isCog">
                                                {{
                                                    displayGasLayer(
                                                        i - 1,
                                                        system.WindowGasLayers
                                                    )
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    displaySolidLayer(
                                                        system.WindowSolidLayers,
                                                        i
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div
                                        *ngIf="
                                            isGenericCogSystem(
                                                system.WindowGlazingSystem
                                            )
                                        "
                                    >
                                        {{
                                            displayGenericSystem(
                                                system.WindowGlazingSystem
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-center" style="vertical-align: inherit">
                            <input
                                type="radio"
                                [checked]="
                                    system.WindowGlazingSystem.Id ===
                                    selectedGlazingSystemId
                                "
                            />
                        </td>
                    </tr>
                </tbody>
                <ngx-loading [show]="loadingGlazingSystems"></ngx-loading>
                <a
                    *ngIf="selectedGlazingSystemId"
                    (click)="
                        clearSelectedGlazingSystem(); $event.preventDefault()
                    "
                >
                    <button class="btn btn-default clear-selection">
                        Clear Selected
                    </button>
                </a>
                <pagination-controls
                    class="product-pagination"
                    (pageChange)="glazingSystemCurrentPage = $event"
                    id="glazing-system-pagination"
                ></pagination-controls>
            </table>
        </div>
    </div>
    <div
        *ngIf="
            !windowGlazingSystems.length && hasInitialSearch && selectedFrameId
        "
    >
        The options you have selected yield no results for this zone, please
        adjust your selections, call 1-866-OLDCASTLE (653-2278), or
        <a [routerLink]="[]" (click)="navigateToContactUs()">contact us</a> for
        additional assistance.
    </div>
</div>
<!--See performance-->
<ng-container *ngIf="selectedGlazingSystemId && selectedFrameId">
    <hr />
    <performance-summary
        [glazingResult]="glazingResult"
        [totalWindowResult]="totalWindowResult"
        [glassLayers]="selectedGlasses"
        [isCog]="isCog"
        [selectedFrame]="selectedFrame"
    >
    </performance-summary>
</ng-container>
<ng-container
    *ngIf="selectedGlazingSystemId && selectedFrameId && selectedTotalProduct"
>
    <div class="pull-right">
        <button
            class="btn btn-default"
            (click)="downloadDataSheet()"
            [style.margin-right]="'10px'"
            [disabled]="isGeneratingDataSheet"
        >
            {{ getDatasheetDownloadLabel() }}
        </button>
        <button
            mtDisableClickDuringHttpCalls
            class="btn btn-primary"
            (click)="saveTotalProductToProject()"
        >
            Save to My Total Products
        </button>
    </div>
    <div class="clearfix"></div>
    <br />
</ng-container>
<ng-container
    *ngIf="
        isCustomSizingAvailable &&
        selectedGlazingSystemId &&
        selectedFrameId &&
        selectedTotalProduct
    "
>
    <hr />
    <h3>Custom Sizing for Total System Area</h3>
    <total-product-custom-sizing
        [productCategory]="productCategory"
        [windowTotalProductId]="selectedTotalProduct.Id"
    ></total-product-custom-sizing>
    <br />
</ng-container>
<ng-container
    *ngIf="
        !isCustomSizingAvailable &&
        selectedGlazingSystemId &&
        selectedFrameId &&
        selectedTotalProduct
    "
>
    <p>
        If you need assistance understanding or implementing this product
        outside of the NFRC standard dimensions, our team is here to help!
        Please call 1-866-653-2278.
    </p>
    <br />
</ng-container>

<mt-modal-wrapper
    title="Your total product has been saved successfully."
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createTotalProductModalApi = $event"
>
    <div class="m-t-sm text-center">
        <div class="row m-t-md">
            <div class="col-xs-10 col-xs-offset-1 actions">
                <a
                    class="btn btn-primary swal-btn total-product-btn btn-margin-bottom"
                    [routerLink]="['/my-products']"
                >
                    OK, go to My Saved Products.
                </a>
                <button
                    type="button"
                    (click)="closeDialog(); clearForNext()"
                    class="btn btn-primary swal-btn total-product-btn"
                >
                    OK, let’s make another Total Product
                </button>
            </div>
        </div>
    </div>
</mt-modal-wrapper>

<spec-sheet-input-form
    [displayForm]="isGeneratingDataSheet"
    [productListType]="productListTypes.TotalProducts"
    [selectedProductId]="selectedTotalProduct?.Id"
    (emitSuccessfulDownload)="onSuccessfulDownload()"
>
</spec-sheet-input-form>

<save-to-project
    [displayForm]="isSavingToProject"
    [isAuthenticated]="isAuthenticated"
    (emitSaveProjectId)="updateSaveProjectId($event)"
    (onModalClosed)="saveToProjectModalClosed()"
>
</save-to-project>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
        ></create-account>
    </div>
</mt-modal-wrapper>

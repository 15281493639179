import { IEntity } from './base';

import { IUserWindowTotalProduct } from './user-window-total-product';
import { IProductCategory } from './product-category';

export interface IClimateZone extends IEntity {
    Name: string;
    ProductCategoryId?: number;
    UFactorLow: number;
    UFactorHigh: number;
    ShgcLow: number;
    ShgcHigh: number;
    VisibleTransmittanceLow: number;
    VisibleTransmittanceHigh: number;
    CondensationResistanceLow: number;
    CondensationResistanceHigh: number;

    // reverse nav
    UserWindowTotalProducts?: IUserWindowTotalProduct[];

    // foreign keys
    ProductCategory?: IProductCategory;
}

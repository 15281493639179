import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginOverrideComponent } from './login-override.component';
import { CommonModule } from '@angular/common';
import { LoginService } from '@mt-ng2/login-module';
import { SharedPublicModule } from '../common/shared.module';

@NgModule({
    declarations: [
        LoginOverrideComponent,
    ],
    exports: [
        LoginOverrideComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedPublicModule,
    ],
})
export class LoginModule {
    static forRoot(): ModuleWithProviders<LoginModule> {
        return {
            ngModule: LoginModule,
            providers: [
                LoginService,
            ],
        };
    }
}

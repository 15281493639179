import { Component, OnInit } from '@angular/core';
import { IPerformanceTargetFilters, IGlazingSystem, TargetSystemTypes } from '@system-select/model';
import { GlazingSystemService } from '@system-select/web-services';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-product-recommendation',
    styles: [`
        .recommendations-header {
            display: inline-block;
        }
        .recommendations-panel {
            border-bottom: none;
            margin-bottom: 0px;
        }
        .recommendations-panel .panel-heading {
            border: none;
        }
    `],
    templateUrl: './product-recommendation.component.html',
})

export class ProductRecommendationComponent implements OnInit {
    showFullDetailView = false;
    recommendedProducts: IGlazingSystem[];
    _performanceTargetParams: ExtraSearchParams[];
    _outboardParams: ExtraSearchParams[];
    _inboardParams: ExtraSearchParams[];
    _centerGlassParams: ExtraSearchParams[];
    _overallParams: ExtraSearchParams[];
    _targetSystemType: number;
    isAuthenticated: boolean;

    constructor(
        private glazingSystemService: GlazingSystemService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
        });
        this._targetSystemType = TargetSystemTypes.TriplePane;
    }

    buildPerformanceTargetParams(updatedFilters: IPerformanceTargetFilters): void {
            const _params: ExtraSearchParams[] = [];
            _params.push(new ExtraSearchParams({
                name: 'ReflectanceInsideHigh',
                value: updatedFilters.ReflectanceInsideHighValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'ReflectanceInsideLow',
                value: updatedFilters.ReflectanceInsideLowValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'ReflectanceOutsideHigh',
                value: updatedFilters.ReflectanceOutsideHighValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'ReflectanceOutsideLow',
                value: updatedFilters.ReflectanceOutsideLowValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'ShgcHigh',
                value: updatedFilters.ShgcHighValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'ShgcLow',
                value: updatedFilters.ShgcLowValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'TransmittanceHigh',
                value: updatedFilters.TransmittanceHighValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'TransmittanceLow',
                value: updatedFilters.TransmittanceLowValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'UFactorHigh',
                value: updatedFilters.UFactorHighValue,
            }));
            _params.push(new ExtraSearchParams({
                name: 'UFactorLow',
                value: updatedFilters.UFactorLowValue,
            }));

            this._performanceTargetParams = _params;
        }

    updateOutboardParams(params: ExtraSearchParams[]): void {
        this._outboardParams = params;
    }

    updateInboardParams(params: ExtraSearchParams[]): void {
        this._inboardParams = params;
    }

    updateCenterGlassParams(params: ExtraSearchParams[]): void {
        this._centerGlassParams = params;
    }

    updateOverallParams(params: ExtraSearchParams[]): void {
        this._overallParams = params;
    }

    updateTargetSystemType(targetSystemType: number): void {
        this._targetSystemType = targetSystemType;
    }

    toggleView(): void {
        this.showFullDetailView = !this.showFullDetailView;
    }

    getPartialDisplayView(): string {
        return this.showFullDetailView ? 'none' : '';
    }

    getFullDisplayView(): string {
        return this.showFullDetailView ? '' : 'none';
    }

}

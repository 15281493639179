import { IFrameType } from './frame-type';
import { IFramingSystemType } from './framing-system-type';

export interface IFramingSystemTypeLayout {
    FramingSystemTypeId: number;
    FrameNumber: number;
    FrameTypeId: number;
    Description: string;

    // foreign keys
    FrameType?: IFrameType;
    FramingSystemType?: IFramingSystemType;
}

<div class="well">
    <label for="thickness">Air Space:</label>
    <select class="form-control" id="thickness" [(ngModel)]="thickness" (ngModelChange)="buildOverallParams()">
        <option [ngValue]="null"></option>
        <option *ngFor="let item of airSpaceItems" [value]="item">
            {{item | measurement}}
        </option>
    </select>
    <button *ngIf="thickness" class="btn btn-default" (click)="clearThicknessFilter()">Clear</button>
</div>
<div class="well">
    <label for="gasType">Gas Content:</label>
    <select class="form-control" id="gasType" [(ngModel)]="gasTypeId" (ngModelChange)="buildOverallParams()">
        <option [ngValue]="null"></option>
        <option *ngFor="let item of gasTypeItems" [value]="item.Id">
            {{item.Name}}
        </option>
    </select>
    <button *ngIf="gasTypeId" class="btn btn-default" (click)="clearGasTypeFilter()">Clear</button>
</div>

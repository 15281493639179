import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { LeedReportService } from '@system-select/web-services';
import { ILeedReport, MyProductsListTypes } from '@system-select/model';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams, SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { FormatService } from '@system-select/common';
import { MyProductsService } from '../my-products.service';
import { AuthService } from '@mt-ng2/auth-module';
import { LeedReportsListConfig } from './leed-reports.entity-list-config';

@Component({
    selector: 'leed-reports-list',
    templateUrl: 'leed-reports-list.component.html',
})
export class LeedReportsListComponent implements OnInit, OnChanges {
    @Input() selectedProjectId: number;
    entityListConfig: LeedReportsListConfig;
    leedReports: ILeedReport[] = [];
    order: string;
    orderDirection: string;
    total: number;
    currentPage = 1;
    itemsPerPage = 25;
    isGeneratingDataSheet = false;
    productListTypes = MyProductsListTypes;
    currentUserId: number;

    constructor(
        private myProductsService: MyProductsService,
        private formatService: FormatService,
        private authService: AuthService,
        private leedReportService: LeedReportService,
    ) {
        this.entityListConfig = new LeedReportsListConfig();
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.entityListConfig.onItemDeleted.subscribe((leedReport) => {
            const i = this.leedReports.indexOf(leedReport);
            this.leedReports.splice(i, 1);
            this.leedReports = this.leedReports.slice();
            this.myProductsService.productRemoved.emit();
        });

        this.getLeedReports();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedProjectId && !changes.selectedProjectId.firstChange) {
            this.getLeedReports();
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProjectId',
            value: this.selectedProjectId ? this.selectedProjectId.toString() : '',
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'UserId',
            value: this.currentUserId.toString(),
        }));
        return _extraSearchParams;
    }

    getLeedReports(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.leedReportService.get(searchparams).subscribe(
            (answer) => {
                this.leedReports = answer.body;
                this.leedReports.forEach((lr) => {
                    if (lr.GlazingSystem) {
                        lr.GlazingSystem.GlazingSystemSolidLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                        lr.GlazingSystem.GlazingSystemGasLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                        let realSurfaceCount = 0;
                        for (let index = 0; index < lr.GlazingSystem.GlazingSystemSolidLayers.length; index++) {
                            let previousLayer;
                            const currentLayer = lr.GlazingSystem.GlazingSystemSolidLayers[index];
                            if (index > 0 && currentLayer.Layer > 1) {
                                previousLayer = lr.GlazingSystem.GlazingSystemSolidLayers[index - 1];
                                const surfaceCount = previousLayer.Glass.Laminated ? 4 : 2;
                                realSurfaceCount += surfaceCount;
                                currentLayer.Glass.Name = this.formatService.formatRealSurfaceLayerLabel(currentLayer.Glass.Name, realSurfaceCount, currentLayer.Layer);
                            }
                        }
                    }
                });

                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getLeedReports();
    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

}

import { AuthUserDynamicControls } from '@system-select/model';
import { IAuthUser } from '@system-select/model';
import { IUserRole } from '@system-select/model';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '../../common/config/login.config';
export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {

    constructor(private extAuthUser: IAuthUser, userRoles?: IUserRole[]) {
        super(extAuthUser, { roles: userRoles });

        const loginConfig = new LoginConfig(LoginConfigOverride);
        let passwordRegexPattern = loginConfig.passwordPattern;
        let failedPatternMessage = LoginConfigOverride.messageOverrides.failedPattern;

        if (!userRoles) {
            // Add the controls to validate passwords.  These are not in the model.
            this.Form.ConfirmPassword = new DynamicField({
                failedPatternMessage: failedPatternMessage,
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm Password</label>`,
                name: 'ConfirmPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
                validators: {
                    pattern: passwordRegexPattern,
                    required: true,
                },
                value: '',
            });

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            });
        }

        (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
        (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
        (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;

    }

}

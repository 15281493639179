<mt-modal-wrapper
    title="Choose Project"
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="chooseProjectModalApi = $event"
>
    <div class="m-t-sm text-center">
        <ul class="list-group form">
            <li
                *ngFor="let project of projects"
                class="list-group-item"
                (click)="useProject(project)"
            >
                {{ project.Name }}
            </li>
        </ul>
    </div>
</mt-modal-wrapper>

<h2>Metal Builder</h2>
<div class="row">
    <div class="col-md-5">
        <h3>Select a product category:</h3>
        <select
            class="form-control"
            [(ngModel)]="selectedProductCategory"
            (ngModelChange)="updateSelectedProductCategory()"
        >
            <option [ngValue]="null"></option>
            <option
                *ngFor="let category of productCategories"
                [ngValue]="category"
            >
                {{ category.Name }}
            </option>
        </select>
    </div>
</div>
<div
    class="row p-t-lg"
    *ngIf="selectedProductCategory && windowFrames.length > 0"
>
    <div class="col-md-8">
        <table class="table table-hover framing-system-table">
            <thead>
                <tr>
                    <th>Framing System (and properties)</th>
                    <th class="text-center">Selection</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let frame of windowFrames
                            | paginate
                                : {
                                      itemsPerPage: 10,
                                      currentPage: currentPage,
                                      id: 'frame-pagination'
                                  }
                    "
                    [ngClass]="{
                        selected: selectedFrame && frame.Id === selectedFrame.Id
                    }"
                    (click)="selectedFrame = frame"
                >
                    <td>
                        <div>
                            <div class="frame-system-name">
                                <div>
                                    <div>{{ frame.Name }}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center" style="vertical-align: inherit">
                        <input
                            type="radio"
                            [checked]="
                                selectedFrame && frame.Id === selectedFrame.Id
                            "
                        />
                    </td>
                </tr>
            </tbody>
            <a
                *ngIf="selectedFrame"
                (click)="clearSelectedFrame(); $event.preventDefault()"
            >
                <button class="btn btn-default clear-selection">
                    Clear Selected
                </button>
            </a>
            <pagination-controls
                class="product-pagination"
                (pageChange)="currentPage = $event"
                id="frame-pagination"
            ></pagination-controls>
        </table>
    </div>
</div>
<div *ngIf="!windowFrames.length" style="margin-top: 15px">
    The options you have selected yield no results for this zone, please adjust
    your selections, call 1-866-OLDCASTLE (653-2278), or
    <a [routerLink]="[]" (click)="navigateToContactUs()">contact us</a> for
    additional assistance.
</div>
<div *ngIf="selectedFrame">
    <div class="row">
        <div class="col-md-12">
            <div class="results-panel">
                <div class="panel panel-default">
                    <div class="panel-heading product-performance-header">
                        {{ selectedFrame.Name }}
                        <button
                            *ngIf="hasLeedDocuments()"
                            (click)="downloadLeedDocuments()"
                            class="btn btn-xs btn-default"
                            title="Download LEED Documents"
                        >
                            <i class="fa fa-file-pdf-o"></i>
                        </button>
                        <br />
                        LEED Score: {{ selectedFrame.LeedPoints }}
                    </div>
                    <div class="panel-body" *ngIf="selectedFrame.SiteLink">
                        <a href="{{ selectedFrame.SiteLink }}" target="_blank"
                            >Learn More</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selectedFrame" class="pull-right builder-actions">
    <button class="btn btn-primary" (click)="onFrameSelected()">
        Save to LEED Report
    </button>
</div>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
        ></create-account>
    </div>
</mt-modal-wrapper>

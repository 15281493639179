<p>Follow the steps below to build your product</p>
<div class="row">
    <div class="col-md-5">
        <h3>1. Select a product category:</h3>
        <select class="form-control" [(ngModel)]="selectedProductCategory"
            (ngModelChange)="updateSelectedProductCategory()">
            <option [ngValue]="null"></option>
            <option *ngFor="let category of productCategories" [ngValue]="category">{{category.Name}}</option>
        </select>
    </div>
</div>
<div class="row" *ngIf="selectedProductCategory">
    <div class="col-md-12">
        <h3>2. Select a zone:</h3>
        <!--NEED A REAL ASSET FOR THIS MAP....?-->
        <img class="zone-map" src="https://i.redd.it/wmfffv51rikx.jpg" /><br>
        <div class="form-group">
            <label class="zone-selection">Zone Per IECC 2018</label>
            <select class="form-control zone-selection" [(ngModel)]="selectedZone"
                (ngModelChange)="updateSelectedZone()">
                <option [ngValue]="null"></option>
                <option *ngFor="let zone of filteredClimateZones" [ngValue]="zone">{{zone.Name}}</option>
            </select>
        </div>
    </div>
</div>
<div class="row" *ngIf="selectedZone">
    <div class="col-md-12">
        <h3>Select a performance range:</h3>
        <div class="results-panel">
            <div class="panel panel-default">
                <div class="panel-heading product-performance-header">{{selectedZone.Name}} Performance Range</div>
                <div class="panel-body">
                    <table class="performance-table">
                        <tr>
                            <td>U-factor Low</td>
                            <td>U-factor High</td>
                            <td>SHGC Low</td>
                            <td>SHGC High</td>
                            <td>Visible Transmittance Low</td>
                            <td>Visible Transmittance High</td>
                            <td>Condensation Resistance Low</td>
                            <td>Condensation Resistance High</td>
                        </tr>
                        <tr>
                            <td>{{selectedZone.UFactorLow | number: '1.2-2'}}</td>
                            <td>{{selectedZone.UFactorHigh | number: '1.2-2' }}</td>
                            <td>{{selectedZone.ShgcLow | number: '1.2-2'}}</td>
                            <td>{{selectedZone.ShgcHigh | number: '1.2-2'}}</td>
                            <td>{{selectedZone.VisibleTransmittanceLow | number: '1.2-2'}}</td>
                            <td>{{selectedZone.VisibleTransmittanceHigh | number: '1.2-2'}}</td>
                            <td>{{selectedZone.CondensationResistanceLow | number: '1.0-0'}}</td>
                            <td>{{selectedZone.CondensationResistanceHigh | number: '1.0-0'}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<h5 [ngStyle]="{'visibility': getWarningVisibility()}" class="range-error">You have exceeded the zone requirements for your selected zone, please reference project criteria to be certain it meets performance criteria.</h5>
<div class="row" *ngIf="selectedZone">
    <div class="col-md-3" [ngClass]="{'range-error': isFilterOutsideOfRange(minUFactorValue, maxUFactorValue, selectedZone.UFactorLow, selectedZone.UFactorHigh)}">
        <span>U-factor:</span>
        <ngx-slider [(value)]="minUFactorValue" [(highValue)]="maxUFactorValue" [options]="uFactorOptions"></ngx-slider>
    </div>
    <div class="col-md-3" [ngClass]="{'range-error': isFilterOutsideOfRange(minShgcValue, maxShgcValue, selectedZone.ShgcLow, selectedZone.ShgcHigh, useGenericCOGValue)}">
        <span>SHGC:</span>
        <ngx-slider [(value)]="minShgcValue" [(highValue)]="maxShgcValue" [options]="shgcOptions"></ngx-slider>
    </div>
    <div class="col-md-3" [ngClass]="{'range-error': isFilterOutsideOfRange(minVisibleTransmittanceValue, maxVisibleTransmittanceValue, selectedZone.VisibleTransmittanceLow, selectedZone.VisibleTransmittanceHigh, useGenericCOGValue)}">
        <span>Visible Transmittance:</span>
        <ngx-slider [(value)]="minVisibleTransmittanceValue" [(highValue)]="maxVisibleTransmittanceValue"
            [options]="visibleTransmittanceOptions"></ngx-slider>
    </div>
    <div class="col-md-3" [ngClass]="{'range-error': isFilterOutsideOfRange(minCondensationResistanceValue, maxCondensationResistanceValue, selectedZone.CondensationResistanceLow, selectedZone.CondensationResistanceHigh)}">
        <span>Condensation Resistance:</span>
        <ngx-slider [(value)]="minCondensationResistanceValue" [(highValue)]="maxCondensationResistanceValue"
            [options]="condendationResistanceOptions"></ngx-slider>
    </div>
    <hr />
</div><br>
<div class="row" *ngIf="selectedZone">
    <div class="col-md-12">
        <h3>3. Select glass option:</h3>
    </div>
</div>
<div class="row" *ngIf="selectedZone">
    <div class="col-lg-4 col-md-12">
        <button class="btn"
            [ngClass]="{'btn-primary': useActualGlazingProduct, 'btn-default': !useActualGlazingProduct }" type="button"
            (click)="setUseActualGlazingProduct()">Find Total Product Values Using an Actual Glazing Product</button>
    </div>
    <div class="col-lg-2 col-md-2 col-md-offset-1 divider-text">
        <b><i>OR</i></b>
    </div>
    <div class="col-lg-4 col-md-12">
        <button class="btn" [ngClass]="{'btn-primary': useGenericCOGValue, 'btn-default': !useGenericCOGValue }"
            type="button" (click)="setUseGenericCOGValue()">Find Total Product U-factors Using a Generic Center of Glass U-factor</button>
    </div>
</div>
<div class="row" *ngIf="selectedZone && (useActualGlazingProduct || useGenericCOGValue)">
    <hr />
    <div class="col-md-3">
        <button class="btn btn-default" (click)="setSearchFilterValues()">Search</button>
    </div>
</div>

<div
    class="miles-form padded"
    *ngIf="configLoaded"
    [ngStyle]="{ 'text-align': isEmbedded ? 'left' : '' }"
>
    <h4>User Info</h4>
    <mt-dynamic-form
        [config]="formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
    >
        <div>
            <label [ngClass]="{ 'has-error': !roleSelected }" for="role"
                >Role:</label
            >
            <select
                class="form-control"
                [ngClass]="{ 'has-error': !roleSelected }"
                id="role"
                [(ngModel)]="roleId"
                (ngModelChange)="checkForOtherRole()"
                [ngModelOptions]="{ standalone: true }"
            >
                <option *ngFor="let role of roles" [value]="role.Id">
                    {{ role.Name }}
                </option>
            </select>
        </div>
        <br />
        <div *ngIf="isOtherRole" class="other-role-input">
            <label [ngClass]="{ 'has-error': !roleSelected }" for="otherRole"
                >Other:</label
            >
            <input
                [ngClass]="{ 'has-error': !roleSelected }"
                class="form-control"
                type="text"
                id="otherRole"
                [(ngModel)]="role"
                [ngModelOptions]="{ standalone: true }"
            />
        </div>
        <div>
            <input type="checkbox" [(ngModel)]="optIn" />
            <label
                >&nbsp;Send me occasional notifications on Oldcastle
                BuildingEnvelope&#174; products and services</label
            ><br />
        </div>
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-primary"
            [ngClass]="{ 'embedded-padding': isEmbedded }"
        >
            Create Account
        </button>
        <button
            type="button"
            *ngIf="!isEmbedded"
            Class="btn btn-flat btn-default"
            (click)="cancelClick()"
            [ngClass]="{ 'embedded-padding': isEmbedded }"
        >
            Cancel
        </button>
    </mt-dynamic-form>
</div>

<div class="products-container">
    <mt-entity-list class="performance-table saved-products-table table-xs"
        [entities]="products"
        [(itemsPerPage)]="itemsPerPage"
        [total]="total"
        [(currentPage)]="currentPage"
        (pageChanged)="getTotalProducts()"
        (columnSortedEvent)="columnSorted($event)"
        [emptyMessage]="'No saved products.'"
        [entityListConfig]="entityListConfig">
    </mt-entity-list>
</div>
<spec-sheet-input-form
[displayForm]="isGeneratingDataSheet"
[productListType]="productListTypes.TotalProducts"
[selectedProductId]="selectedProductId"
(emitSuccessfulDownload)="onSuccessfulDownload()">
</spec-sheet-input-form>

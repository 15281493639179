<mt-modal-wrapper
    title="User & Project Info"
    [autoShow]="false"
    [showActions]="false"
    [allowOutsideClick]="false"
    [options]="modalOptions"
    (ready)="projectInfoModalApi = $event"
>
    <div class="m-t-sm text-center form" *ngIf="formCreated">
        <form
            class="miles-form padded"
            [formGroup]="projectForm.controls.Project"
            (ngSubmit)="formSubmitted()"
        >
            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.ContactName"
            ></mt-dynamic-field>
            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.CompanyName"
            ></mt-dynamic-field>

            <label>User Company Type</label> *
            <br />
            <label class="btn btn-primary-inverse">
                <input
                    type="radio"
                    name="CompanyType"
                    value="Architect"
                    formControlName="CompanyType"
                />
                Architect
            </label>
            <label class="btn btn-primary-inverse">
                <input
                    type="radio"
                    name="CompanyType"
                    value="Glazing Contractor"
                    formControlName="CompanyType"
                />
                Glazing Contractor
            </label>
            <label class="btn btn-primary-inverse">
                <input
                    type="radio"
                    name="CompanyType"
                    value="Other"
                    formControlName="CompanyType"
                />
                Other
            </label>
            <br />
            <br />

            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.ContactEmail"
            ></mt-dynamic-field>
            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.ContactPhone"
            ></mt-dynamic-field>
            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.CompanyAddress"
            ></mt-dynamic-field>

            <div class="row">
                <div class="col-xs-6">
                    <mt-dynamic-field
                        [form]="projectForm"
                        [field]="abstractProjectControls.CompanyCity"
                    ></mt-dynamic-field>
                </div>
                <div class="col-xs-3">
                    <mt-dynamic-field
                        [form]="projectForm"
                        [field]="abstractProjectControls.CompanyStateCode"
                    ></mt-dynamic-field>
                </div>
                <div class="col-xs-3">
                    <mt-dynamic-field
                        [form]="projectForm"
                        [field]="abstractProjectControls.CompanyZip"
                    ></mt-dynamic-field>
                </div>
            </div>

            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.Name"
            ></mt-dynamic-field>
            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.Description"
            ></mt-dynamic-field>

            <div class="row">
                <div class="col-xs-8">
                    <mt-dynamic-field
                        [form]="projectForm"
                        [field]="abstractProjectControls.City"
                    ></mt-dynamic-field>
                </div>
                <div class="col-xs-4">
                    <mt-dynamic-field
                        [form]="projectForm"
                        [field]="abstractProjectControls.StateCode"
                    ></mt-dynamic-field>
                </div>
            </div>

            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.ProjectTimelineId"
            ></mt-dynamic-field>

            <mt-dynamic-field
                [form]="projectForm"
                [field]="abstractProjectControls.Default"
            ></mt-dynamic-field>

            <div>
                <button
                    type="submit"
                    mtDisableClickDuringHttpCalls
                    Class="btn btn-primary btn-success"
                >
                    Save
                </button>
                <button
                    class="btn btn-default"
                    (click)="cancelEditing(); $event.preventDefault()"
                >
                    Cancel
                </button>
            </div>
        </form>
    </div>
</mt-modal-wrapper>

import { IEntity } from './base';

import { IGlass } from './glass';

export interface IGlassColor extends IEntity {
    Name: string;

    // reverse nav
    Glasses?: IGlass[];
}

<mt-modal-wrapper
    title="Input Spec Sheet Info"
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="inputFormModalApi = $event"
>
    <div class="m-t-sm text-center">
        <div class="row m-t-md">
            <div class="col-xs-12 form-group">
                <form #specSheetInputForm="ngForm" (ngSubmit)="save()">
                    <input
                        class="form-control"
                        name="customer"
                        placeholder="Designed by/for"
                        [(ngModel)]="specSheetParams.Customer"
                        #customer="ngModel"
                    />
                    <input
                        class="form-control"
                        name="project"
                        placeholder="Project Name & Location"
                        [(ngModel)]="specSheetParams.Project"
                        #project="ngModel"
                    />
                    <br />
                    <div>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            [disabled]="specSheetInputForm.invalid"
                        >
                            Download <i class="fa fa-save"></i>
                        </button>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            [disabled]="specSheetInputForm.invalid"
                            (click)="
                                toggleDisplayEmail(); $event.preventDefault()
                            "
                        >
                            Email <i class="fa fa-send"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <ngx-loading [show]="loadingResult"></ngx-loading>
    </div>
</mt-modal-wrapper>
<spec-sheet-email-form
    [specSheetParams]="specSheetParams"
    [displayForm]="displayEmailForm"
    [selectedProductId]="selectedProductId"
    [glazingPerformanceRequest]="glazingPerformanceRequest"
    [productListType]="productListType"
    (emitSuccessfulEmail)="onSuccessfulEmail($event)"
>
</spec-sheet-email-form>

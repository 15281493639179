import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@system-select/model';
import { TotalProductBuilderComponent } from './total-product-builder/total-product-builder.component';

const totalProductAddRoleGuard = {
    claimType: ClaimTypes.Frames,
    claimValues: [ClaimValues.ReadOnly],
};

const totalProductRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: TotalProductBuilderComponent,
        data: totalProductAddRoleGuard,
        path: 'total-product-builder',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            totalProductRoutes,
        ),
    ],
})
export class TotalProductRoutingModule {

}

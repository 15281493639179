import { IEntity } from './base';

import { IProject } from './project';

export interface IProjectTimeline extends IEntity {
    Name: string;

    // reverse nav
    Projects?: IProject[];
}

import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IGlazingSystem } from '@system-select/model';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { UnitsService, FormatService } from '@system-select/common';
import { SavedGlazingSystemsGlassCellComponent } from '../glazing-systems-full-detail-list/glazing-systems-full.entity-list-config';
@Injectable()
export class GlazingSystemsPartialListConfig extends EntityListConfig {

    @Output('onItemDeleted') onItemDeleted = new EventEmitter<IGlazingSystem>();

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
    ) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    component: SavedGlazingSystemsGlassCellComponent,
                    name: 'Glass System',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleTransmittance.toString(),
                    name: 'Visible Trans (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleReflectanceOutside.toString(),
                    name: 'Visible Refl. Out (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleReflectanceOutside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleReflectanceInside.toString(),
                    name: 'Visible Refl. In (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleReflectanceInside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${this.unitsService.uFactorMetricToImp(x.UFactorWinter).toFixed(2)}`;
                    },
                    name: 'Winter U-Factor (Btu/h·ft2·F)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${x.UFactorWinter.toFixed(2)}`;
                    },
                    name: 'Winter U-Factor (W/m2·K)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.Shgc.toString(),
                    name: 'Solar Heat Gain Coeff.',
                    pipes: ['number:\'1.2-2\''],
                    sort: { sortProperty: 'Shgc' },
                }),
            ],
        });

        this.getDefaultSortProperty = () => 'Id';
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { markAllFormFieldsAsTouched } from "@mt-ng2/common-functions";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { ICustomProductInquiry } from "@system-select/model";
import { CustomProductInquiryService } from "@system-select/web-services";
import { CustomProductInquiryDynamicConfig } from "./custom-product.dynamic-config";

@Component({
    selector: "custom-product-inquiry",
    templateUrl: "./custom-product-inquiry.component.html",
})
export class CustomProductInquiryComponent implements OnInit {
    @Input() displayForm: boolean;
    formFactory: CustomProductInquiryDynamicConfig<ICustomProductInquiry>;
    customProductInquiry: ICustomProductInquiry;
    isEditing = true;
    customProductInquiryForm: UntypedFormGroup;
    @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private customProductInquiryService: CustomProductInquiryService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.customProductInquiry =
            this.customProductInquiryService.getEmptyCustomProductInquiry();
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory =
            new CustomProductInquiryDynamicConfig<ICustomProductInquiry>(
                this.customProductInquiry
            );
        const config = this.formFactory.getForCreate();
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    cancelClick(): void {
        this.onCancelClick.emit();
    }

    onFormCreated(form: UntypedFormGroup): void {
        this.customProductInquiryForm = form;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.customProductInquiry,
                form.value.CustomProductInquiry as ICustomProductInquiry
            );
            this.customProductInquiry.Subject = "Website LEED Request";
            this.saveCustomProductInquiry();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error(
                "Save failed.  Please check the form and try again."
            );
        }
    }

    private saveCustomProductInquiry(): void {
        this.customProductInquiryService
            .create(this.customProductInquiry)
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.notificationsService.success(
            "Thank you for your submission. A representative will be in touch with you shortly."
        );
        this.customProductInquiryForm.reset();
        this.onCancelClick.emit();
    }

    getComponentVisibility(): string {
        return this.displayForm ? "visible" : "hidden";
    }
}

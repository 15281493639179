import { ExtraSearchParams } from '@mt-ng2/common-classes';

export interface IProductRecommendationParams {
    query: string;
    skip?: number;
    take?: number;
    extraParams?: ExtraSearchParams[];
    order?: string;
    orderDirection?: string;
    layerParams?: ExtraSearchParams[][];
    overallParams?: ExtraSearchParams[];
    targetsystemType?: number;
}

import { IEntity } from './base';

import { IGlazingSystemSolidLayer } from './glazing-system-solid-layer';

export interface ISpandrelType extends IEntity {
    Name: string;

    // reverse nav
    GlazingSystemSolidLayers?: IGlazingSystemSolidLayer[];
}

import { IEntity } from './base';

import { IFrame } from './frame';
import { IFramingSystemTypeLayout } from './framing-system-type-layout';

export interface IFrameType extends IEntity {
    Name: string;

    // reverse nav
    Frames?: IFrame[];
    FramingSystemTypeLayouts?: IFramingSystemTypeLayout[];
}

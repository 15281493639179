import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { CustomProductInquiryDynamicControls } from 'src/app/model/form-controls/custom-product-inquiry.form-controls';
import { ICustomProductInquiry } from '@system-select/model';

export class CustomProductInquiryDynamicConfig<T extends ICustomProductInquiry> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private customProductInquiry: T,
        private configControls?: string[]) {
        super();
        const dynamicControls = new CustomProductInquiryDynamicControls(this.customProductInquiry, null);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'Email', 'Question'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}

import { IEntity } from './base';

import { IClimateZone } from './climate-zone';
import { IProductCategory } from './product-category';
import { IProject } from './project';
import { IUser } from './user';
import { IWindowTotalProduct } from './window-total-product';

export interface IUserWindowTotalProduct extends IEntity {
    UserId: number;
    WindowTotalProductId: number;
    ClimateZoneId: number;
    ProductCategoryId: number;
    ProjectId?: number;
    UserSaveDate?: Date;

    // foreign keys
    ClimateZone?: IClimateZone;
    ProductCategory?: IProductCategory;
    Project?: IProject;
    User?: IUser;
    WindowTotalProduct?: IWindowTotalProduct;
}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ICustomProductHorizontal } from '@system-select/web-services';
import { WindowTotalProductService } from '@system-select/web-services';
import { finalize } from 'rxjs/operators';
import { UnitsService } from '@system-select/common';
import { IProductCategory, CustomSizingRules } from '@system-select/model';

interface IDimensionView {
    Actual: number;
    View: number;
}

interface IProductRow {
    Height: IDimensionView;
    Widths: IDimensionView[];
}

interface IDimensions {
    Width: number;
    Height: number;
}

@Component({
    selector: 'total-product-custom-sizing',
    styles: [`
        .grid-container {
            margin-top: 20px;
            margin-bottom: 20px;
            margin-right: 20px;
        }
        .results-container {
            margin-top: 32px;
            max-width: 350px;
        }
        .custom-sizing-results-table tr:not(:first-child) td {
            border-top: 1px solid #ddd;
        }
        .custom-sizing-results-table td:first-child {
            padding: 6px 20px 6px 0px;
        }
        .custom-sizing-results-table td:not(:first-child) {
            padding-right: 10px;
        }
        .custom-sizing-results-table button {
            padding-left: 10px;
            padding-right: 10px;
        }
    `],
    templateUrl: 'custom-sizing.component.html',
})

export class TotalProductCustomSizingComponent implements OnInit {
    @Input() productCategory: IProductCategory;
    private _maxViewWidth = 800;
    private _maxViewHeight = 500;

    isImperial = true;

    rows: IProductRow[] = [];

    rowEditIndex: number;
    rowEditValue: number;
    colEditIndex: number;
    colEditValue: number;

    totalWidth: number;
    totalHeight: number;
    numHorizontals: number;
    numVerticals: number;

    uValue: number;
    unableToCompute = false;
    exceedsSizeLimit = false;

    isCalculating = false;

    private _windowTotalProductId: number;
    customSizingRuleId: number;
    customSizingRules = CustomSizingRules;

    @Input('windowTotalProductId')
    set windowTotalProductId(val: number) {
        this._windowTotalProductId = val;
        this.clearUValue(); // if the product changes, current UValue no longer applies
    }
    get windowTotalProductId(): number {
        return this._windowTotalProductId;
    }

    get isStarted(): boolean {
        return this.rows.length > 0;
    }

    constructor(
        private windowTotalProductService: WindowTotalProductService,
        private unitsService: UnitsService,
    ) { }

    ngOnInit(): void {
        // TESTING
        // this.totalHeight = 240;
        // this.totalWidth = 240;
        // this.numHorizontals = 4;
        // this.numVerticals = 4;
        // this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productCategory.currentValue) {
            this.customSizingRuleId = changes.productCategory.currentValue.CustomSizingRuleId;
            switch (this.customSizingRuleId) {
                case CustomSizingRules.Normal:
                    break;
                case CustomSizingRules.SingleOpening:
                    this.numHorizontals = 1;
                    this.numVerticals = 1;
                    break;
                case CustomSizingRules.NoStacking:
                    this.numHorizontals = 1;
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Creates the diagram based on user inputs of width, height,
     * and number of horizontals and verticals.  All frames are
     * initialized with equal widths and heights.
     */
    init(): void {
        const view = this.getViewDimensions();

        const rowHeightView = (view.Height / this.numHorizontals);
        const rowHeightActual = this.totalHeight / this.numHorizontals;

        const colWidthView = view.Width / this.numVerticals;
        const colWidthActual = this.totalWidth / this.numVerticals;

        this.rows = [];

        for (let i = 0; i < this.numHorizontals; i++) {
            const widths = Array(this.numVerticals);
            for (let j = 0; j < this.numVerticals; j++) {
                widths[j] = { Actual: colWidthActual, View: colWidthView };
            }

            this.rows.push({
                Height: { Actual: rowHeightActual, View: rowHeightView },
                Widths: widths,
            });
        }
    }

    /**
     * Gets the dimensions of the view based on the actual widths and heights.
     * This restricts the dimensions based on maxViewWidth and maxViewHeight.
     */
    getViewDimensions(): IDimensions {
        let viewWidth: number;
        let viewHeight: number;

        const viewRatio = this._maxViewWidth / this._maxViewHeight;
        const actualRatio = this.totalWidth / this.totalHeight;

        if (viewRatio < actualRatio) {
            // scale to max width
            const widthRatio = this.totalWidth / this._maxViewWidth;

            viewWidth = this._maxViewWidth;
            viewHeight = this.totalHeight / widthRatio;
        } else {
            // scale to max height
            const heightRatio = this.totalHeight / this._maxViewHeight;

            viewHeight = this._maxViewHeight;
            viewWidth = this.totalWidth / heightRatio;
        }

        return {
            Height: viewHeight,
            Width: viewWidth,
        };
    }

    reset(): void {
        this.rows = [];
        this.clearEditCol();
        this.clearEditRow();
        this.clearUValue();
        this.unableToCompute = false;
    }

    setUnitOfMeasure(toImperial: boolean): void {
        if (toImperial === this.isImperial) {
            return;
        }

        this.isImperial = toImperial;

        this.convertValues(toImperial);
    }

    convertValues(toImperial: boolean): void {
        const convertFn = this.getUnitConversionFunction(toImperial);

        this.totalHeight = this.round(convertFn(this.totalHeight), 2);
        this.totalWidth = this.round(convertFn(this.totalWidth), 2);

        this.rows.forEach((row) => {
            row.Height.Actual = convertFn(row.Height.Actual);
            row.Widths.forEach((width) => {
                width.Actual = convertFn(width.Actual);
            });
        });

        if (this.uValue) {
            const uValueConvertFn = toImperial
                ? (x: number) => this.unitsService.uFactorMetricToImp(x)
                : (x: number) => this.unitsService.uFactorImpToMetric(x);

            this.uValue = uValueConvertFn(this.uValue);
        }
    }

    getUnitConversionFunction(toImperial: boolean): (value: number) => number {
        return toImperial
            ? (x: number) => this.unitsService.mmToIn(x)
            : (x: number) => this.unitsService.inToMm(x);
    }

    getUnitOfMeasure(): string {
        return this.isImperial ? 'in' : 'mm';
    }

    getAreaUnitOfMeasure(): string {
        return this.isImperial ? 'ft' : 'm';
    }

    getUValueUnitOfMeasure(): string {
        return this.isImperial ? 'Btu/h·ft2·F' : 'W/m2·K';
    }

    getArea(): number {
        const area = this.totalWidth * this.totalHeight;
        return area / (this.isImperial ? 144 : 1000000);
    }

    editRow(i: number): void {
        this.rowEditIndex = i;
        this.rowEditValue = this.round(this.rows[i].Height.Actual, 2);

        this.clearEditCol();
    }

    editCol(i: number): void {
        this.colEditIndex = i;
        this.colEditValue = this.round(this.rows[0].Widths[i].Actual, 2);

        this.clearEditRow();
    }

    clearEditRow(): void {
        this.rowEditIndex = null;
        this.rowEditValue = null;
    }

    clearEditCol(): void {
        this.colEditIndex = null;
        this.colEditValue = null;
    }

    clearUValue(): void {
        this.uValue = null;
        this.unableToCompute = false;
        this.exceedsSizeLimit = false;
    }

    getUValueDisplay(): string {
        if (!this.uValueIsSet()) {
            return null;
        }

        if (isNaN(this.uValue)) {
            return '';
        }

        return `${this.round(this.uValue, 2)} ${this.getUValueUnitOfMeasure()}`;
    }

    uValueIsSet(): boolean {
        return this.uValue !== undefined && this.uValue !== null;
    }

    updateRow(i: number, height: number): void {
        if (!(height > 0)) {
            return;
        }

        this.rows[i].Height.Actual = height;

        this.totalHeight = this.getFullHeight();
        this.setViewFromActuals();
        this.clearEditRow();
        this.clearUValue();
    }

    updateCol(i: number, width: number): void {
        if (!(width > 0)) {
            return;
        }

        this.rows.forEach((row: IProductRow) => {
            row.Widths[i].Actual = width;
        });

        this.totalWidth = this.getFullWidth();
        this.setViewFromActuals();
        this.clearEditCol();
        this.clearUValue();
    }

    rowKeyPress(i: number, event: KeyboardEvent): void {
        if (event.keyCode === 27 /* ESCAPE */) {
            this.updateRow(i, this.rowEditValue);
        }

        if (event.keyCode === 9 /* TAB */) {
            this.updateRow(i, this.rowEditValue);
            const numRows = this.rows.length;

            const nextRowIndex = event.shiftKey ? (i - 1) : (i + 1);
            if (nextRowIndex < numRows && nextRowIndex >= 0) {
                this.editRow(nextRowIndex);
            }
        }
    }

    colKeyPress(i: number, event: KeyboardEvent): void {
        if (event.keyCode === 27 /* ESCAPE */) {
            this.updateCol(i, this.colEditValue);
        }

        if (event.keyCode === 9 /* TAB */) {
            this.updateCol(i, this.colEditValue);
            const numCols = this.rows[0].Widths.length;

            const nextColIndex = event.shiftKey ? (i - 1) : (i + 1);
            if (nextColIndex < numCols && nextColIndex >= 0) {
                this.editCol(nextColIndex);
            }
        }
    }

    getFullWidth(): number {
        return this.round(
            this.rows[0].Widths.map((width) => width.Actual).reduce((a, b) => a + b),
            2,
        );
    }

    getFullHeight(): number {
        return this.round(
            this.rows.map((row) => row.Height.Actual).reduce((a, b) => a + b),
            2,
        );
    }

    setViewFromActuals(): void {
        const view = this.getViewDimensions();

        this.rows.forEach((row) => {
            row.Height.View = row.Height.Actual * view.Height / this.totalHeight;

            row.Widths.forEach((width) => {
                width.View = width.Actual * view.Width / this.totalWidth;
            });
        });
    }

    round(value: number, decimalDigits: number): number {
        const rounded = Math.round(+`${value}e+${decimalDigits}`);
        return +(`${rounded}e-${decimalDigits}`);
    }

    calculate(): void {
        const horizontals: ICustomProductHorizontal[] = [];

        this.rows.forEach((row) => {
            const horizontal: ICustomProductHorizontal = {
                Height: row.Height.Actual,
                Widths: row.Widths.map((width) => width.Actual),
            };
            horizontals.push(horizontal);
        });

        if (this.isImperial) {
            // if imperial, we have to convert back to metric before we send to the web API
            const convertFn = this.getUnitConversionFunction(false);

            horizontals.forEach((horizontal) => {
                horizontal.Height = convertFn(horizontal.Height);
                for (let i = 0; i < horizontal.Widths.length; i++) {
                    horizontal.Widths[i] = convertFn(horizontal.Widths[i]);
                }
            });
        }

        // can't exceed 65 sq. ft. ~= 6038697.6 sq. mm
        this.unableToCompute = false;
        this.exceedsSizeLimit = false;
        if (horizontals.some((h) => h.Widths.some((width) => h.Height * width > 6038697.6))) {
            this.unableToCompute = true;
            this.exceedsSizeLimit = true;
            return;
        }

        this.isCalculating = true;
        this.windowTotalProductService.getCustomSizeUValue(this.windowTotalProductId, horizontals)
            .pipe(finalize(() => this.isCalculating = false))
            .subscribe((uValue) => {
                this.uValue = uValue;
                this.unableToCompute = false;
                if (this.isImperial) {
                    this.uValue = this.unitsService.uFactorMetricToImp(this.uValue);
                }
            }, () => {
                this.uValue = NaN;
                this.unableToCompute = true;
            });
    }
}

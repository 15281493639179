<div class="row" *ngIf="glassProducts">
    <div class="col-md-4">
        <div class="panel panel-default">
            <div class="panel-heading">{{layerLabel}} Details</div>
            <div class="panel-body">
                <div class="well">
                    <div class="btn-group">
                        <button type="button" class="btn" [ngClass]="{'btn-default': laminated, 'btn-primary active': !laminated}" (click)="checkLaminatedValue()">
                            Monolithic
                        </button>
                        <button type="button" class="btn" [ngClass]="{'btn-default': !laminated, 'btn-primary active': laminated}" (click)="checkLaminatedValue()">
                            Laminated
                        </button>
                    </div>
                    <div class="well laminated" *ngIf="laminated">
                        <label for="interlayerType">Interlayer Type: </label>
                        <select class="form-control" id="interlayerTypeId" [(ngModel)]="interlayerTypeId"
                            (ngModelChange)="filterAvailableInterlayerThickness(); getGlassProducts();">
                            <option [value]="null"></option>
                            <option *ngFor="let item of interlayerTypeItems" [value]="item.Id">
                                {{item.Name}}
                            </option>
                        </select>
                            <div *ngIf="displayThicknessFilter">
                                <label for="interlayerThickness">Interlayer Thickness: </label>
                                <select class="form-control" id="interlayerThickness" [(ngModel)]="interlayerThickness"
                                    (ngModelChange)="filterAvailableInterlayerTypes(); getGlassProducts()">
                                    <option [value]="null"></option>
                                    <option *ngFor="let item of interlayerThicknesses" [value]="item">
                                        {{item}}"
                                    </option>
                                </select>
                            </div>
                    </div><br>
                    <button *ngIf="interlayerThickness || interlayerTypeId" class="btn btn-default"
                        (click)="clearLaminateFilter()">Reset Selection</button>
                </div>
                <div class="well">
                    <label for="glassColor">Glass Color: </label><br>
                    <label class="radio-inline" *ngFor="let item of glassColorItems" [ngStyle]="{'display': isGlassColorDisabled(item)}">
                        <input type="radio" name="{{getGlassColorLabel(item.Id)}}" [value]="item.Id" [(ngModel)]="glassColorId"
                            (ngModelChange)="getGlassProducts()" ><span class="color-filter" [title]="item.Name"
                            [ngStyle]="{'background-color': getSwatchColor(item.Name), 'display': isGlassColorDisabled(item)}"></span>
                    </label>
                    <div>
                        <button class="btn btn-default" *ngIf="glassColorItems && glassColorItems.length > 1 && !spandrel" (click)="clearGlassColorFilter()">Reset Selection</button>
                    </div>
                </div>
                <div class="well">
                    <label for="glassType">Glass Type: </label>
                    <select class="form-control" id="glassType" [(ngModel)]="glassTypeId"
                        (ngModelChange)="onGlassTypeChange();">
                        <option [value]="0">All Glass Types</option>
                        <option *ngFor="let item of glassTypeItems" [value]="item.Id">
                            {{item.Name}}
                        </option>
                        <option *ngIf="isSilkScreenDisabled()" [disabled]="true">
                            Only one lite in a Glass Product may be Silk-Screened, and you have already selected this option for another lite.
                        </option>
                    </select>
                    <div class="well" *ngIf="spandrel">
                        <label for="spandrelType">Spandrel Type:</label>
                        <select class="form-control" id="spandrelTypeId" [(ngModel)]="spandrelTypeId" (ngModelChange)="updateSpandrelSelection();">
                            <option *ngFor="let item of spandrelTypeItems" [value]="item.Id">
                                {{item.Name}}
                            </option>
                        </select>
                        <label for="spandrelColor">Spandrel Color:</label>
                        <input class="form-control" id="spandrelColor" [(ngModel)]="spandrelColor" (ngModelChange)="updateSpandrelSelection();">
                    </div>
                    <div class="well" *ngIf="glassTypeId == glassTypes.SilkScreened">
                        <label for="silkScreenColor">Silk-screened Color:</label>
                        <select class="form-control" id="silkScreenColorId" [(ngModel)]="silkScreenColorId"
                            (ngModelChange)="getGlassProducts();">
                            <option [value]="null"></option>
                            <option *ngFor="let item of silkScreenColorItems" [value]="item.Id">
                                {{item.Name}}
                            </option>
                        </select>
                        <label for="silkScreenPattern">Silk-screened Pattern: </label>
                        <select class="form-control" id="silkScreenPatternId" [(ngModel)]="silkScreenPatternId"
                            (ngModelChange)="getGlassProducts()">
                            <option [value]="null"></option>
                            <option *ngFor="let item of silkScreenPatternItems" [value]="item.Id">
                                {{item.Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="well">
                    <label>Vendors:</label>
                        <form *ngIf="vendorFilterItems">
                            <label *ngFor="let item of vendorFilterItems" class="checkbox-inline">
                                <input type="checkbox" [name]="item.Item.Name" [(ngModel)]="item.Selected" (change)="getGlassProducts()">{{item.Item.Name}}
                            </label>
                        </form>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <app-solid-layer-product-select [glassProducts]="filteredGlassProducts" [layerLabel]="layerLabel"
            (updateSelectedGlassProduct)="emitUpdatedGlassProduct($event)" [glassId]="solidLayer.GlassId"></app-solid-layer-product-select>
    </div>
</div>

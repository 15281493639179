<div class="row">
    <div class="col-md-2 col-sm-3 col-xs-6">
        <label for="thickness">Air Space:</label>
        <select class="form-control" id="thickness" [(ngModel)]="thickness" (ngModelChange)="updateGasLayer()">
            <option [ngValue]="null"></option>
            <option *ngFor="let item of airSpaceItems" [value]="item.Thickness">
                {{item.InchesLabel}} {{item.MetricLabel}}
            </option>
        </select>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-6">
        <label for="gasType">Gas Content:</label>
        <select class="form-control" id="gasType" [(ngModel)]="gasTypeId" (ngModelChange)="updateGasLayer()">
            <option [ngValue]="null"></option>
            <option *ngFor="let item of gasTypeItems" [value]="item.Id">
                {{item.Name}}
            </option>
        </select>
    </div>
</div>

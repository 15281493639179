import { IEntity } from './base';

import { IFramingSystem } from './framing-system';
import { IGlazingSystem } from './glazing-system';
import { IUser } from './user';

export interface ITotalProduct extends IEntity {
    FramingSystemId: number;
    GlazingSystem1Id: number;
    GlazingSystem2Id?: number;
    UFactor: number;
    Shgc: number;
    VisibleTransmittance: number;
    CondensationResistance: number;
    UserId: number;

    // foreign keys
    FramingSystem?: IFramingSystem;
    GlazingSystem1?: IGlazingSystem;
    GlazingSystem2?: IGlazingSystem;
    User?: IUser;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LeedReportBuilderFormSteps, IProject } from '@system-select/model';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';

@Component({
    selector: 'leed-report-project',
    templateUrl: 'leed-report-project.component.html',
})

export class LeedReportProjectComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    currentFormStep: LeedReportBuilderFormSteps;
    completedFormSteps: LeedReportBuilderFormSteps[];

    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
    ) { }

    ngOnInit(): void {
        this.initializeSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private initializeSubscriptions(): void {
        this.subscriptions.add(
            this.stateService.currentFormStep$.subscribe((value) => {
                this.currentFormStep = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
    }

    onProjectSelected(project: IProject): void {
        this.stateService.project$.next(project);
        this.stateService.markStepCompleted(LeedReportBuilderFormSteps.Project);
        this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.ChoosePath);
    }
}

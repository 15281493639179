import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UnitsService } from '@system-select/common';
import { IClimateZone, IProductCategory, IWindowTotalProductFilter } from '@system-select/model';
import { ClimateZoneService, ProductCategoryService } from '@system-select/web-services';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'climate-zone-filter',
    styles: [`
        .zone-map {
            max-height: 400px;
            max-width: 800px;
            display: block;
            margin: 0 auto;
        }
        .zone-selection {
            max-width: 500px;
            display: block;
            margin: 0 auto;
            border-radius: 0;
        }
        .range-error {
            color: red;
        }
        #cog-label {
            font-size: 14px;
            color: #00573d;
        }
        .divider-text {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        @media only screen and (min-width: 992px) {
            .divider-text {
                text-align: center;
                display: block;
                margin: 5px auto;
            }
        }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            .divider-text {
                margin-left: 30px;
            }
        }

    `],
    templateUrl: 'climate-zone-filter.component.html',
})

export class ClimateZoneFilterComponent implements OnInit {
    @Output() emitUpdatedFilter: EventEmitter<IWindowTotalProductFilter> = new EventEmitter<IWindowTotalProductFilter>();
    @Output() emitNullSelection: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onProductCategoryUpdate: EventEmitter<IProductCategory> = new EventEmitter<IProductCategory>();
    useActualGlazingProduct: boolean;
    useGenericCOGValue: boolean;
    climateZones: IClimateZone[];
    filteredClimateZones: IClimateZone[];
    selectedZone: IClimateZone;
    productCategories: IProductCategory[];
    selectedProductCategory: IProductCategory;
    minUFactorValue = 0;
    maxUFactorValue = 1;
    minShgcValue = 0;
    maxShgcValue = 1;
    minVisibleTransmittanceValue = 0;
    maxVisibleTransmittanceValue = 1;
    minCondensationResistanceValue = 0;
    maxCondensationResistanceValue = 100;
    condendationResistanceOptions: Options = {
        ceil: 100,
        floor: 0,
        step: 1,
    };
    visibleTransmittanceOptions: Options = {
        ceil: 1,
        disabled: false,
        floor: 0,
        step: 0.01,
    };
    uFactorOptions: Options = {
        ceil: 1,
        floor: 0,
        step: 0.01,
    };
    shgcOptions: Options = {
        ceil: 1,
        disabled: false,
        floor: 0,
        step: 0.01,
    };
    windowTotalProductFilter: IWindowTotalProductFilter = {
        ClimateZoneId: 0,
        CondensationResistanceHighValue: '',
        CondensationResistanceLowValue: '',
        ProductCategoryId: 0,
        ShgcHighValue: '',
        ShgcLowValue: '',
        UFactorHighValue: '',
        UFactorLowValue: '',
        UseActualGlazingProduct: false,
        UseGenericCOGValue: false,
        VisibleTransmittanceHighValue: '',
        VisibleTransmittanceLowValue: '',
    };

    constructor(private climateZoneService: ClimateZoneService, private productCategoryService: ProductCategoryService, private unitsService: UnitsService) { }

    ngOnInit(): void {
        forkJoin([
            this.climateZoneService.getAll(),
            this.productCategoryService.getAll(),
        ]).subscribe((answer) => {
            const [climateZones, productCategories] = [...answer];
            this.climateZones = climateZones;
            this.productCategories = productCategories.filter((pc) => pc.ParentId === null && !pc.IsLeed);
        });
    }

    updateSelectedProductCategory(): void {
        this.selectedZone = null;
        if (this.selectedProductCategory) {
            this.filteredClimateZones = this.climateZones.filter((cz) => {
                return this.selectedProductCategory && cz.ProductCategoryId === this.selectedProductCategory.Id;
            });
            this.useActualGlazingProduct = false;
            this.useGenericCOGValue = false;
        } else {
            this.useActualGlazingProduct = false;
            this.useGenericCOGValue = false;
            this.emitNullSelection.emit(true);
        }
        this.onProductCategoryUpdate.emit(this.selectedProductCategory);
    }

    updateSelectedZone(): void {
        if (this.selectedZone) {
            this.convertUFactorsToImperial(this.selectedZone);
            this.setFilterValues();
            this.setSearchFilterValues();
        } else {
            this.useActualGlazingProduct = false;
            this.useGenericCOGValue = false;
            this.emitNullSelection.emit(true);
        }
    }

    // Convert UFactors to Imperial from DB metric values
    private convertUFactorsToImperial(climateZone: IClimateZone & { _isImperial?: boolean }): void {
        if (!climateZone._isImperial) {
            climateZone.UFactorHigh = +this.unitsService.uFactorMetricToImp(climateZone.UFactorHigh).toFixed(2);
            climateZone.UFactorLow = +this.unitsService.uFactorMetricToImp(climateZone.UFactorLow).toFixed(2);
            climateZone._isImperial = true;
        }
    }

    private setFilterValues(): void {
        this.minUFactorValue = this.selectedZone.UFactorLow;
        this.maxUFactorValue = this.selectedZone.UFactorHigh;
        this.minVisibleTransmittanceValue = this.selectedZone.VisibleTransmittanceLow;
        this.maxVisibleTransmittanceValue = this.selectedZone.VisibleTransmittanceHigh;
        this.minShgcValue = this.selectedZone.ShgcLow;
        this.maxShgcValue = this.selectedZone.ShgcHigh;
        this.minCondensationResistanceValue = this.selectedZone.CondensationResistanceLow;
        this.maxCondensationResistanceValue = this.selectedZone.CondensationResistanceHigh;
    }

    getWarningVisibility(): string {
        return this.isOutsideOfZoneValues() ? 'visible' : 'hidden';
    }

    private isOutsideOfZoneValues(): boolean {
        if (this.selectedZone) {
            return this.isFilterOutsideOfRange(this.minUFactorValue, this.maxUFactorValue, this.selectedZone.UFactorLow, this.selectedZone.UFactorHigh)
                || this.isFilterOutsideOfRange(this.minShgcValue, this.maxShgcValue, this.selectedZone.ShgcLow, this.selectedZone.ShgcHigh, this.useGenericCOGValue)
                || this.isFilterOutsideOfRange(this.minVisibleTransmittanceValue, this.maxVisibleTransmittanceValue, this.selectedZone.VisibleTransmittanceLow, this.selectedZone.VisibleTransmittanceHigh, this.useGenericCOGValue)
                || this.isFilterOutsideOfRange(this.minCondensationResistanceValue, this.maxCondensationResistanceValue, this.selectedZone.CondensationResistanceLow, this.selectedZone.CondensationResistanceHigh);
        }
        return false;
    }

    isFilterOutsideOfRange(minValue: number, maxValue: number, filterMin: number, filterMax: number, isCog = false): boolean {
        return !isCog && (minValue < +(filterMin.toFixed(2)) || maxValue > +(filterMax.toFixed(2)));
    }

    setSearchFilterValues(): void {
        this.windowTotalProductFilter.UFactorLowValue = this.minUFactorValue.toString();
        this.windowTotalProductFilter.UFactorHighValue = this.maxUFactorValue.toString();
        this.windowTotalProductFilter.ShgcLowValue = this.minShgcValue.toString();
        this.windowTotalProductFilter.ShgcHighValue = this.maxShgcValue.toString();
        this.windowTotalProductFilter.VisibleTransmittanceLowValue = this.minVisibleTransmittanceValue.toString();
        this.windowTotalProductFilter.VisibleTransmittanceHighValue = this.maxVisibleTransmittanceValue.toString();
        this.windowTotalProductFilter.CondensationResistanceLowValue = this.minCondensationResistanceValue.toString();
        this.windowTotalProductFilter.CondensationResistanceHighValue = this.maxCondensationResistanceValue.toString();
        this.windowTotalProductFilter.UseGenericCOGValue = this.useGenericCOGValue;
        this.windowTotalProductFilter.UseActualGlazingProduct = this.useActualGlazingProduct;
        this.windowTotalProductFilter.ProductCategoryId = this.selectedProductCategory.Id;
        this.windowTotalProductFilter.ClimateZoneId = this.selectedZone.Id;
        // Only search if we have a value set for this filter
        if (this.useActualGlazingProduct || this.useGenericCOGValue) {
            this.emitUpdatedFilter.emit(this.windowTotalProductFilter);
        }
    }

    setUseActualGlazingProduct(): void {
        // Only reset sliders if we are switching back from COG
        if (this.useGenericCOGValue) {
            this.minVisibleTransmittanceValue = this.selectedZone.VisibleTransmittanceLow;
            this.maxVisibleTransmittanceValue = this.selectedZone.VisibleTransmittanceHigh;
            this.minShgcValue = this.selectedZone.ShgcLow;
            this.maxShgcValue = this.selectedZone.ShgcHigh;
        }
        if (!this.useActualGlazingProduct) {
            this.useActualGlazingProduct = true;
            this.useGenericCOGValue = false;
            this.shgcOptions = Object.assign({}, this.shgcOptions, { disabled: false });
            this.visibleTransmittanceOptions = Object.assign({}, this.visibleTransmittanceOptions, { disabled: false });
        }
        if (this.selectedZone) {
            this.setSearchFilterValues();
        }
    }

    setUseGenericCOGValue(): void {
        if (!this.useGenericCOGValue) {
            this.useGenericCOGValue = true;
            this.useActualGlazingProduct = false;
            this.shgcOptions = Object.assign({}, this.shgcOptions, { disabled: true });
            this.visibleTransmittanceOptions = Object.assign({}, this.visibleTransmittanceOptions, { disabled: true });
            this.minVisibleTransmittanceValue = this.visibleTransmittanceOptions.floor;
            this.maxVisibleTransmittanceValue = this.visibleTransmittanceOptions.ceil;
            this.minShgcValue = this.shgcOptions.floor;
            this.maxShgcValue = this.shgcOptions.ceil;
        }
        if (this.selectedZone) {
            this.setSearchFilterValues();
        }
    }
}

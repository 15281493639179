<h2>Glass Product Builder</h2>
<p>Select product details for available glass systems</p>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-default">
            <div class="panel-heading">Glass Product Type</div>
            <div class="panel-body glazing-header-panel">
                <div class="btn-group">
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                            'btn-default': numberOfLayers !== 1,
                            'btn-primary active': numberOfLayers === 1
                        }"
                        (click)="changeNumberOfLayers(1)"
                    >
                        Monolithic
                    </button>
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                            'btn-default': numberOfLayers !== 2,
                            'btn-primary active': numberOfLayers === 2
                        }"
                        (click)="changeNumberOfLayers(2)"
                    >
                        Double Pane
                    </button>
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                            'btn-default': numberOfLayers !== 3,
                            'btn-primary active': numberOfLayers === 3
                        }"
                        (click)="changeNumberOfLayers(3)"
                    >
                        Triple Pane
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <cdk-accordion multi>
        <cdk-accordion-item
            #accordionItem="cdkAccordionItem"
            role="button"
            tabindex="0"
        >
            <div class="panel panel-default">
                <div class="panel-heading" (click)="accordionItem.toggle()">
                    <div class="pull-left">
                        {{ setLayerLabel(null)
                        }}<span
                            *ngIf="
                                defaultSolidLayer && defaultSolidLayer.GlassId
                            "
                            >: {{ displayGlassName(null, true) }}</span
                        >
                    </div>
                    <div class="pull-right">
                        <i
                            class="fa"
                            [ngClass]="
                                accordionItem.expanded
                                    ? 'fa-chevron-down'
                                    : 'fa-chevron-right'
                            "
                        ></i>
                    </div>
                    <div class="clearfix"></div>
                </div>

                <div
                    role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    class="panel-body"
                >
                    <app-solid-layer-edit
                        [layer]="1"
                        [numberOfLayers]="numberOfLayers"
                        [solidLayer]="defaultSolidLayer"
                        [glassColorItems]="glassColorItems"
                        [glassTypeItems]="glassTypeItems"
                        [silkScreenColorItems]="silkScreenColorItems"
                        [silkScreenPatternItems]="silkScreenPatternItems"
                        [realSurfaceLayers]="productSurfaceLayers"
                        [interlayerTypeItems]="interlayerTypeItems"
                        [silkScreenSelectedDTO]="silkScreenSelectedDTO"
                        [vendorItems]="vendorItems"
                        (updateSelectedGlassProduct)="
                            updateSelectedGlassProductsList($event)
                        "
                        (updateSilkScreenSelected)="
                            updateSelectedSilkScreenLayer($event)
                        "
                        (updateRealSurfaceCount)="
                            updateProductSurfaceCount($event)
                        "
                        (emitUpdatedSpandrelSelection)="
                            updateSpandrelSelection($event)
                        "
                    >
                    </app-solid-layer-edit>
                </div>
            </div>
        </cdk-accordion-item>
        <div *ngFor="let layer of solidLayers; let i = index">
            <cdk-accordion-item
                #accordionItem1="cdkAccordionItem"
                role="button"
                tabindex="0"
            >
                <div class="panel panel-default">
                    <div class="panel-heading" (click)="accordionItem1.toggle()">
                        <div class="pull-left">
                            Air Space<span
                                *ngIf="
                                    gasLayers[i].GasTypeId &&
                                    gasLayers[i].Thickness
                                "
                                >: {{ displayGasLayer(i) }}</span
                            >
                        </div>
                        <div class="pull-right">
                            <i
                                class="fa"
                                [ngClass]="
                                    accordionItem.expanded
                                        ? 'fa-chevron-down'
                                        : 'fa-chevron-right'
                                "
                            ></i>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div
                        role="region"
                        [style.display]="accordionItem1.expanded ? '' : 'none'"
                        class="panel-body"
                    >
                        <app-gas-layer-edit
                            [gasLayer]="gasLayers[i]"
                            [layer]="i + 1"
                            [gasTypeItems]="gasTypeItems"
                            (emitUpdatedGasLayer)="
                                updateGasLayerList($event, i)
                            "
                        >
                        </app-gas-layer-edit>
                    </div>
                </div>
            </cdk-accordion-item>
            <cdk-accordion-item
                #accordionItem2="cdkAccordionItem"
                role="button"
                tabindex="0"
            >
                <div class="panel panel-default">
                    <div class="panel-heading" (click)="accordionItem2.toggle()">
                        <div class="pull-left">
                            {{ setLayerLabel(i)
                            }}<span *ngIf="solidLayers[i].GlassId"
                                >: {{ displayGlassName(i, false) }}</span
                            >
                        </div>
                        <div class="pull-right">
                            <i
                                class="fa"
                                [ngClass]="
                                    accordionItem.expanded
                                        ? 'fa-chevron-down'
                                        : 'fa-chevron-right'
                                "
                            ></i>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div
                        role="region"
                        [style.display]="accordionItem2.expanded ? '' : 'none'"
                        class="panel-body"
                    >
                        <app-solid-layer-edit
                            [solidLayer]="solidLayers[i]"
                            [layer]="i + 2"
                            [numberOfLayers]="numberOfLayers"
                            [glassColorItems]="glassColorItems"
                            [glassTypeItems]="glassTypeItems"
                            [realSurfaceLayers]="productSurfaceLayers"
                            [silkScreenColorItems]="silkScreenColorItems"
                            [silkScreenPatternItems]="silkScreenPatternItems"
                            [interlayerTypeItems]="interlayerTypeItems"
                            [silkScreenSelectedDTO]="silkScreenSelectedDTO"
                            [vendorItems]="vendorItems"
                            (updateSelectedGlassProduct)="
                                updateSelectedGlassProductsList($event, i)
                            "
                            (updateSilkScreenSelected)="
                                updateSelectedSilkScreenLayer($event)
                            "
                            (updateRealSurfaceCount)="
                                updateProductSurfaceCount($event)
                            "
                            (emitUpdatedSpandrelSelection)="
                                updateSpandrelSelection($event)
                            "
                        >
                        </app-solid-layer-edit>
                    </div>
                </div>
            </cdk-accordion-item>
        </div>
    </cdk-accordion>
</div>

<app-glass-system-performance
    [isMissingOpticsFile]="isMissingOpticsFile"
    [glazingResult]="glazingResult"
    [glassLayers]="selectedGlassLayers"
    [isSpandrel]="isSpandrel"
>
    <ngx-loading [show]="isLoadingPerformance"></ngx-loading>
    <div class="glazing-header-panel">
        <div *ngIf="defaultSolidLayer && defaultSolidLayer.GlassId">
            {{ displaySolidLayer(null) }}
            <ng-container
                *ngIf="isLeedReportEmbedded && defaultSolidLayer.Glass"
            >
                <b>LEED Score: {{ defaultSolidLayer.Glass.LeedPoints }}</b>
            </ng-container>
            <button
                *ngIf="layerHasLeedDocuments(defaultSolidLayer)"
                (click)="downloadLeedDocuments(defaultSolidLayer.Glass)"
                class="btn btn-xs btn-default"
                title="Download LEED Documents"
            >
                <i class="fa fa-file-pdf-o"></i>
            </button>
        </div>
        <div *ngFor="let layer of solidLayers; let i = index">
            <div
                *ngIf="
                    gasTypeItems &&
                    gasLayers.length > 0 &&
                    gasLayers[0].GasTypeId != null
                "
            >
                {{ displayGasLayer(i) }}
            </div>
            <div *ngIf="solidLayers">
                {{ displaySolidLayer(i) }}
                <ng-container *ngIf="isLeedReportEmbedded && layer.Glass">
                    <b>LEED Score: {{ layer.Glass.LeedPoints }}</b>
                </ng-container>
                <button
                    *ngIf="layerHasLeedDocuments(layer) && isLeedReportEmbedded"
                    (click)="downloadLeedDocuments(layer.Glass)"
                    class="btn btn-xs btn-default"
                    title="Download LEED Documents"
                >
                    <i class="fa fa-file-pdf-o"></i>
                </button>
            </div>
        </div>
    </div>
    <br
        *ngIf="
            (defaultSolidLayer && defaultSolidLayer.GlassId) ||
            (solidLayers && solidLayers.length)
        "
    />
</app-glass-system-performance>

<div class="builder-actions" *ngIf="glazingResult">
    <div class="pull-right">
        <button
            class="btn btn-default"
            [style.margin-right]="'10px'"
            (click)="downloadDataSheet()"
            [disabled]="isGeneratingDataSheet"
        >
            {{ getDatasheetDownloadLabel() }}
        </button>
        <button class="btn btn-primary" (click)="saveGlazingSystemToProject()">
            {{
                !isLeedReportEmbedded
                    ? "Save to My Glass Products"
                    : "Save to LEED Report"
            }}
        </button>
    </div>
    <div class="clearfix"></div>
</div>

<save-to-project
    [displayForm]="isSavingToProject"
    [isAuthenticated]="isAuthenticated"
    (emitSaveProjectId)="updateSaveProjectId($event)"
    (onModalClosed)="saveToProjectModalClosed()"
>
</save-to-project>

<spec-sheet-input-form
    [displayForm]="isGeneratingDataSheet"
    [productListType]="productListTypes.GlassProducts"
    [glazingPerformanceRequest]="glazingPerformanceRequest"
    (emitSuccessfulDownload)="onSuccessfulDownload()"
>
</spec-sheet-input-form>

<mt-modal-wrapper
    title="Your glass product has been saved successfully."
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createGlazingSystemModalApi = $event"
>
    <div class="m-t-sm text-center">
        <div class="row m-t-md">
            <div class="col-xs-8 col-xs-offset-2 actions">
                <a
                    class="btn btn-primary swal-btn btn-margin-bottom"
                    [routerLink]="['/my-products']"
                >
                    Go to My Saved Products.
                </a>
                <button
                    type="button"
                    (click)="closeDialog(); clearForNext()"
                    class="btn btn-primary swal-btn"
                >
                    Make another glass product
                </button>
            </div>
        </div>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
        ></create-account>
    </div>
</mt-modal-wrapper>

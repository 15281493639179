import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { ProjectService } from '@system-select/web-services';
import { AuthService } from '@mt-ng2/auth-module';
import { IProject } from '@system-select/model';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'save-to-project',
    styles: [`
        .btn {
            width: 100%;
        }
    `],
    templateUrl: 'save-to-project.component.html',
})

export class SaveToProjectComponent implements OnInit, OnChanges {
    saveToProjectModalApi: IModalWrapperApi;
    @Input() displayForm: boolean;
    @Output() emitSaveProjectId: EventEmitter<number> = new EventEmitter<number>();
    @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();
    defaultProject: IProject;
    displayProjectList: boolean;
    @Input() isAuthenticated: boolean;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        didClose: () => this.onModalClosed.emit(),
        showCloseButton: true,
    };

    constructor(
        private projectService: ProjectService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.getDefaultProject();
    }

    getDefaultProject(): void {
        this.projectService.getDefaultProjectByUserId(this.authService.currentUser.getValue().Id)
            .subscribe((defaultProject) => {
                this.defaultProject = defaultProject;
            });
    }

    ngOnChanges(): void {
        if (this.displayForm) {
            setTimeout(() => {
                this.saveToProjectModalApi.show();
            }, 0);
        }
        if (this.isAuthenticated) {
            this.getDefaultProject();
        }
    }

    chooseProject(): void {
        this.saveToProjectModalApi.close();
        this.displayProjectList = true;
    }

    useDefaultProject(): void {
        this.emitSaveProjectId.emit(this.defaultProject.Id);
        this.saveToProjectModalApi.close();
    }

    useNoProject(): void {
        this.emitSaveProjectId.emit(null);
        this.saveToProjectModalApi.close();
    }

    useProject(project: IProject): void {
        this.emitSaveProjectId.emit(project.Id);
        this.saveToProjectModalApi.close();
        this.displayProjectList = false;
    }

    chooseProjectModalClosed(): void {
        this.displayProjectList = false;
        this.saveToProjectModalApi.show();
    }
}

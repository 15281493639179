import { IEntity } from './base';

import { IGlazingSystemGasLayer } from './glazing-system-gas-layer';
import { IGlazingSystemSolidLayer } from './glazing-system-solid-layer';
import { ILeedReport } from './leed-report';
import { ITotalProduct } from './total-product';
import { IProject } from './project';
import { IUser } from './user';

export interface IGlazingSystem extends IEntity {
    Layers: number;
    Thickness: number;
    Width: number;
    Height: number;
    VisibleTransmittance: number;
    VisibleReflectanceOutside: number;
    VisibleReflectanceInside: number;
    UvTransmittance: number;
    SolarTransmittance: number;
    SolarReflectanceOutside: number;
    UFactorWinter: number;
    UFactorSummer: number;
    ShadingCoefficient: number;
    Shgc: number;
    RelativeHeatGain: number;
    LightToSolarGain: number;
    UserId?: number;
    UserSaveDate?: Date;
    ProjectId?: number;
    ComponentKey?: string;
    IsProcessed: boolean;

    // reverse nav
    GlazingSystemGasLayers?: IGlazingSystemGasLayer[];
    GlazingSystemSolidLayers?: IGlazingSystemSolidLayer[];
    LeedReports?: ILeedReport[];
    TotalProducts_GlazingSystem1Id?: ITotalProduct[];
    TotalProducts_GlazingSystem2Id?: ITotalProduct[];

    // foreign keys
    Project?: IProject;
    User?: IUser;
}

<div class="row">
    <div class="col-md-7">
        <div class="miles-card padded">
            <h4>Navigation</h4>
            <div class="row">
                <div class="col-md-2" *ngIf="canNavigateBackwards()">
                    <div class="nav-option">
                        <button
                            class="btn btn-default"
                            (click)="navigateBackwards()"
                        >
                            <i class="fa fa-2x fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
                <div class="col-md-2" *ngIf="canNavigateForwards()">
                    <div class="nav-option">
                        <button
                            class="btn btn-default"
                            (click)="moveForwardInBuilder()"
                        >
                            <i class="fa fa-2x fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

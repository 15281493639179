import { Component, Input, SimpleChanges } from '@angular/core';
import { GlazingSystemService } from '@system-select/web-services';
import { IGlazingSystem, IProductRecommendationParams } from '@system-select/model';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { UnitsService, FormatService } from '@system-select/common';
import { MyProductsService } from 'src/public/my-products/my-products.service';
import { GlazingSystemsFullListConfig } from './glazing-systems-full.entity-list-config';
import { AuthService } from '@mt-ng2/auth-module';
import { TargetSystemTypes, MyProductsListTypes } from 'src/app/model/public-api';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ProjectService } from '@system-select/web-services';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'glazing-systems-full-detail-list',
    templateUrl: 'glazing-systems-full-detail-list.component.html',
})
export class GlazingSystemsFullDetailListComponent {
    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;
    @Input() _outboardParams: ExtraSearchParams[];
    @Input() _inboardParams: ExtraSearchParams[];
    @Input() _centerGlassParams: ExtraSearchParams[];
    @Input() _overallParams: ExtraSearchParams[];
    @Input() _targetSystemType: number;
    @Input() _performanceTargetParams: ExtraSearchParams[];
    recommendedProducts: IGlazingSystem[];
    entityListConfig: GlazingSystemsFullListConfig;
    order: string;
    orderDirection: string;
    total: number;
    currentPage = 1;
    itemsPerPage = 10;
    layerParams: ExtraSearchParams[][];
    isAuthenticated: boolean;
    selectedGlazingSystem: IGlazingSystem;
    isSaving: boolean;
    isSavingToProject: boolean;
    selectedProjectId: number;
    isGeneratingDataSheet: boolean;
    selectedProductId: number;
    productListTypes = MyProductsListTypes;
    needsAuthenticationForDatasheet: boolean;
    needsRedirect: boolean;
    userHasProjects: boolean;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(
        private glazingSystemService: GlazingSystemService,
        private myProductsService: MyProductsService,
        private unitsService: UnitsService,
        private formatService: FormatService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private router: Router,
        private projectService: ProjectService,
    ) {
        this.entityListConfig = new GlazingSystemsFullListConfig(unitsService, formatService, authService);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.projectService.getAll().subscribe((answer) => this.userHasProjects = answer.length > 0);
        });
        this.entityListConfig.onDataSheetDownload.subscribe((selectedProduct) => {
            this.selectedProductId = selectedProduct.Id;
            this.downloadDataSheet();
        });
        this.entityListConfig.onGlazingSystemSave.subscribe((selectedProduct) => {
            this.selectedGlazingSystem = selectedProduct;
            this.saveGlazingSystemToProject();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes._targetSystemType) {
            this._targetSystemType = changes._targetSystemType.currentValue;
            this.resetSecondaryFilters();
        }
        if (changes._performanceTargetParams) {
            this._performanceTargetParams = changes._performanceTargetParams.currentValue;
        }
        if (changes._outboardParams) {
            this._outboardParams = changes._outboardParams.currentValue;
        }
        if (changes._inboardParams) {
            this._inboardParams = changes._inboardParams.currentValue;
        }
        if (changes._centerGlassParams) {
            this._centerGlassParams = changes._centerGlassParams.currentValue;
        }
        if (changes._overallParams) {
            this._overallParams = changes._overallParams.currentValue;
        }

        this.getGlazingSystems();
    }

    updateAuthenticationStatus(): void {
        this.loginPopupModalApi.close();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.projectService.getAll().subscribe(() => {
                if (this.isSaving) {
                    this.needsRedirect = true;
                    this.saveGlazingSystemToProject();
                }
            });
            if (this.needsAuthenticationForDatasheet) {
                this.downloadDataSheet();
            }
        });
    }

    downloadDataSheet(): void {
        if (this.isAuthenticated) {
            this.isGeneratingDataSheet = true;
        } else {
            this.needsAuthenticationForDatasheet = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }

    saveGlazingSystemToProject(): void {
        if (this.isAuthenticated) {
            if (this.userHasProjects) {
                this.isSavingToProject = true;
            } else {
                this.updateSaveProjectId(null);
            }
        } else {
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    updateSaveProjectId(projectId: number): void {
        this.selectedProjectId = projectId;
        this.saveGlazingSystem(this.selectedGlazingSystem);
    }

    resetSecondaryFilters(): void {
        if (this._targetSystemType === TargetSystemTypes.Monolithic) {
            this._inboardParams = [];
            this._centerGlassParams = [];
        } else if (this._targetSystemType === TargetSystemTypes.DoublePane) {
            this._centerGlassParams = [];
        }
    }

    getGlazingSystems(): void {
        this.layerParams = [];
        this.layerParams.push(this._outboardParams);
        this.layerParams.push(this._inboardParams);
        this.layerParams.push(this._centerGlassParams);

        const searchEntity: IProductRecommendationParams = {
            extraParams: this._performanceTargetParams,
            layerParams: this.layerParams,
            order: this.order,
            orderDirection: this.orderDirection,
            overallParams: this._overallParams,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
            targetsystemType: this._targetSystemType,
        };

        this.glazingSystemService.getProductRecommendations(searchEntity).subscribe(
            (answer) => {
                this.recommendedProducts = answer.body;
                this.recommendedProducts.forEach((glz) => {
                    glz.GlazingSystemSolidLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                    glz.GlazingSystemGasLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                });
                this.recommendedProducts.forEach((rp) => {
                    let realSurfaceCount = 0;
                    for (let index = 0; index < rp.GlazingSystemSolidLayers.length; index++) {
                        let previousLayer;
                        const currentLayer = rp.GlazingSystemSolidLayers[index];
                        if (index > 0 && currentLayer.Layer > 1) {
                            previousLayer = rp.GlazingSystemSolidLayers[index - 1];
                            const surfaceCount = previousLayer.Glass.Laminated ? 4 : 2;
                            realSurfaceCount += surfaceCount;
                            currentLayer.Glass.Name = this.formatService.formatRealSurfaceLayerLabel(currentLayer.Glass.Name, realSurfaceCount, currentLayer.Layer);
                        }
                    }
                });
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    saveGlazingSystem(item: IGlazingSystem): void {
        if (this.isAuthenticated && !this.needsRedirect) {
            const payload = { ...item };
            payload.UserId = this.authService.currentUser.getValue().Id;
            payload.ProjectId = this.selectedProjectId;
            this.glazingSystemService.createGlazingSystemCopy(payload).subscribe(
                () => {
                    this.isSaving = false;
                    this.isSavingToProject = false;
                    Swal.fire(
                        'Success!',
                        'You have saved this product to your glass systems.',
                        'success',
                    ).catch(() => null);
                }, () => {
                    this.notificationsService.error('Unable to create glass system.');
                });
        } else if (this.isAuthenticated && this.needsRedirect) {
            item.UserId = this.authService.currentUser.getValue().Id;
            item.ProjectId = this.selectedProjectId;
            this.glazingSystemService.create(item).subscribe(
                () => {
                    this.isSaving = false;
                    this.isSavingToProject = false;
                    this.router.navigate(['/my-products']).catch(() => null);
                }, () => {
                    this.notificationsService.error('Unable to create glass system.');
                });
        } else {
            this.selectedGlazingSystem = item;
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getGlazingSystems();
    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

    saveToProjectModalClosed(): void {
        this.isSavingToProject = false;
    }
}

import {
    DynamicConfig,
    IDynamicConfig,
    IDynamicFormConfig,
} from "@mt-ng2/dynamic-form";

import {
    PublicUserDetailDynamicControlsPartial,
    IPublicUserDetail,
    IPublicUserDetailDynamicControlsParametersPartial,
    IState,
} from "@system-select/model";

export class PublicUserDynamicConfig<T extends IPublicUserDetail>
    extends DynamicConfig<T>
    implements IDynamicConfig<T>
{
    constructor(
        private publicUserDetail: T,
        private states: Partial<IState>[],
        private configControls?: string[]
    ) {
        super();
        const additionalParameters: IPublicUserDetailDynamicControlsParametersPartial =
            {
                states: this.states,
            };
        const dynamicControls = new PublicUserDetailDynamicControlsPartial(
            this.publicUserDetail,
            additionalParameters
        );
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ["CompanyName", "City", "State"];
        }

        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
        };
    }
}

import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFramingSystem } from '../interfaces/framing-system';
import { IFramingSystemCategory } from '../interfaces/framing-system-category';
import { IFramingSystemType } from '../interfaces/framing-system-type';

export interface IFramingSystemDynamicControlsParameters {
    formGroup?: string;
    framingSystemCategories?: IFramingSystemCategory[];
    framingSystemTypes?: IFramingSystemType[];
}

export class FramingSystemDynamicControls {

    formGroup: string;
    framingSystemCategories: IFramingSystemCategory[];
    framingSystemTypes: IFramingSystemType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private framingsystem?: IFramingSystem, additionalParameters?: IFramingSystemDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FramingSystem';
        this.framingSystemCategories = additionalParameters && additionalParameters.framingSystemCategories || undefined;
        this.framingSystemTypes = additionalParameters && additionalParameters.framingSystemTypes || undefined;

        this.Form = {
            FramingSystemCategoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Framing System Category',
                name: 'FramingSystemCategoryId',
                options: this.framingSystemCategories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystem && this.framingsystem.FramingSystemCategoryId || null,
            }),
            FramingSystemTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Framing System Type',
                name: 'FramingSystemTypeId',
                options: this.framingSystemTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystem && this.framingsystem.FramingSystemTypeId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.framingsystem && this.framingsystem.hasOwnProperty('Name') && this.framingsystem.Name !== null ? this.framingsystem.Name.toString() : '',
            }),
        };

        this.View = {
            FramingSystemCategoryId: new DynamicLabel({
			    label: 'Framing System Category',
			    value: getMetaItemValue(this.framingSystemCategories as unknown as IMetaItem[], this.framingsystem && this.framingsystem.hasOwnProperty('FramingSystemCategoryId') && this.framingsystem.FramingSystemCategoryId !== null ? this.framingsystem.FramingSystemCategoryId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FramingSystemTypeId: new DynamicLabel({
			    label: 'Framing System Type',
			    value: getMetaItemValue(this.framingSystemTypes as unknown as IMetaItem[], this.framingsystem && this.framingsystem.hasOwnProperty('FramingSystemTypeId') && this.framingsystem.FramingSystemTypeId !== null ? this.framingsystem.FramingSystemTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.framingsystem && this.framingsystem.hasOwnProperty('Name') && this.framingsystem.Name !== null ? this.framingsystem.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IGlass, IEntity, IGlazingSystem } from '@system-select/model';
import { Component, EventEmitter, Output, Injectable } from '@angular/core';
import { UnitsService, FormatService, IGlazingSystemLayer } from '@system-select/common';
import { AuthService } from '@mt-ng2/auth-module';
@Injectable()
export class GlazingSystemsFullListConfig extends EntityListConfig {

    @Output('onItemDeleted') onItemDeleted = new EventEmitter<IGlazingSystem>();
    @Output('onGlazingSystemSave') onGlazingSystemSave = new EventEmitter<IGlazingSystem>();
    @Output('onDataSheetDownload') onDataSheetDownload = new EventEmitter<IGlazingSystem>();

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
        private authService: AuthService,
    ) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    component: SavedGlazingSystemsGlassCellComponent,
                    name: 'Glass System',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${this.unitsService.mmToIn(x.Thickness).toFixed(3)} / ${x.Thickness.toFixed(3)}`;
                    },
                    name: 'Thickness (in) / (mm)',
                    sort: { sortProperty: 'Thickness' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleTransmittance.toString(),
                    name: 'Visible Trans (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleReflectanceOutside.toString(),
                    name: 'Visible Refl. Out (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleReflectanceOutside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.VisibleReflectanceInside.toString(),
                    name: 'Visible Refl. In (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'VisibleReflectanceInside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        const glassLayers = x.GlazingSystemSolidLayers.map((l) => {
                            return l.Glass;
                        });
                        return this.formatService.formatUVTransmittanceDisplay(glassLayers, x.UvTransmittance);
                    },
                    name: 'UV Trans. (%)',
                    sort: { sortProperty: 'UvTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.SolarTransmittance.toString(),
                    name: 'Solar Trans. (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'SolarTransmittance' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.SolarReflectanceOutside.toString(),
                    name: 'Solar Refl. Out (%)',
                    pipes: ['percent:\'1.0-0\''],
                    sort: { sortProperty: 'SolarReflectanceOutside' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${this.unitsService.uFactorMetricToImp(x.UFactorWinter).toFixed(2)}`;
                    },
                    name: 'Winter U-Factor (Btu/h·ft2·F)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => {
                        return `${x.UFactorWinter.toFixed(2)}`;
                    },
                    name: 'Winter U-Factor (W/m2·K)',
                    sort: { sortProperty: 'UFactorWinter' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.ShadingCoefficient.toString(),
                    name: 'Shading Coeff.',
                    pipes: ['number:\'1.2-2\''],
                    sort: { sortProperty: 'ShadingCoefficient' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.Shgc.toString(),
                    name: 'Solar Heat Gain Coeff.',
                    pipes: ['number:\'1.2-2\''],
                    sort: { sortProperty: 'Shgc' },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IGlazingSystem) => x.LightToSolarGain.toString(),
                    name: 'Light to Solar Gain',
                    pipes: ['number:\'1.2-2\''],
                    sort: { sortProperty: 'LightToSolarGain' },
                }),
                new EntityListColumn({
                    component: SavedGlazingSystemsActionComponent,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });

        this.getDefaultSortProperty = () => 'Id';

    }
}

@Component({
    template: `
        <div class="d-tc v-align-top" [style.padding-right]="'5px'">
            <div class="color-swatch" [style.background-color]="'rgb(' + glass.ColorR + ', ' + glass.ColorG + ', ' + glass.ColorB + ')'"></div>
        </div>
        <div class="d-tc v-align-top">
            <div *ngFor="let layer of layers">
                {{layer.Prefix}}: {{layer.Name}}
            </div>
        </div>
    `,
})
export class SavedGlazingSystemsGlassCellComponent implements IEntityListDynamicCellComponent {
    item: IGlazingSystem;
    glass: IGlass;
    entityListComponentMembers: IEntityListComponentMembers;
    layers: IGlazingSystemLayer[];

    constructor(
        private formatService: FormatService,
    ) { }

    set entity(value: IEntity) {
        this.item = value as IGlazingSystem;
        this.glass = this.item.GlazingSystemSolidLayers[0].Glass;
        this.layers = this.getLayers();
    }

    getLayers(): IGlazingSystemLayer[] {
        return this.formatService.formatGlazingLayers(this.item.GlazingSystemSolidLayers, this.item.GlazingSystemGasLayers);
    }
}

@Component({
    styles: [`
        .ib-container {
            white-space: nowrap;
        }
    `],
    template: `
        <div class="ib-container p-sm">
            <button (click)="saveGlazingSystem(item)" class="btn btn-xs btn-default" title="Save">
                <i class="fa fa-save"></i>
            </button><button
                (click)="getDataSheet(item)" class="btn btn-xs btn-default" title="Download Spec Sheet">
                <i class="fa fa-file-pdf-o"></i>
            </button>
        </div>
    `,
})
export class SavedGlazingSystemsActionComponent implements IEntityListDynamicCellComponent {
    item: IGlazingSystem;
    glass: IGlass;
    entityListComponentMembers: IEntityListComponentMembers;
    isAuthenticated: boolean;
    isSaving: boolean;
    selectedGlazingSystem: IGlazingSystem;
    isDownloadingDataSheet: boolean;

    constructor(
    ) {
    }

    set entity(value: IEntity) {
        this.item = value as IGlazingSystem;
        this.glass = this.item.GlazingSystemSolidLayers[0].Glass;
    }

    getDataSheet(item: IGlazingSystem): void {
        (<GlazingSystemsFullListConfig>this.entityListComponentMembers.entityListConfig).onDataSheetDownload.emit(item);
    }

    saveGlazingSystem(item: IGlazingSystem): void {
        (<GlazingSystemsFullListConfig>this.entityListComponentMembers.entityListConfig).onGlazingSystemSave.emit(item);
    }
}

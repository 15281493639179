import { IEntity } from './base';

import { IWindowGasLayer } from './window-gas-layer';
import { IWindowSolidLayer } from './window-solid-layer';
import { IWindowTotalProduct } from './window-total-product';
import { IWindowDataSet } from './window-data-set';

export interface IWindowGlazingSystem extends IEntity {
    Name: string;
    WindowDataSetId: number;
    Layers: number;
    Thickness: number;
    VisibleTransmittance: number;
    VisibleReflectanceOutside: number;
    VisibleReflectanceInside: number;
    UvTransmittance: number;
    SolarTransmittance: number;
    SolarReflectanceOutside: number;
    UFactorWinter: number;
    UFactorSummer: number;
    ShadingCoefficient: number;
    Shgc: number;
    RelativeHeatGain: number;
    LightToSolarGain: number;

    // reverse nav
    WindowGasLayers?: IWindowGasLayer[];
    WindowSolidLayers?: IWindowSolidLayer[];
    WindowTotalProducts?: IWindowTotalProduct[];

    // foreign keys
    WindowDataSet?: IWindowDataSet;
}

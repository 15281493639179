import { IEntity, IDocument } from './base';

import { IGlassColor } from './glass-color';
import { IGlassType } from './glass-type';
import { IGlazingSystemSolidLayer } from './glazing-system-solid-layer';
import { IInterlayerType } from './interlayer-type';
import { ISilkScreenColor } from './silk-screen-color';
import { ISilkScreenPattern } from './silk-screen-pattern';
import { IVendor } from './vendor';

export interface IGlass extends IEntity {
    IgdbId: number;
    Name: string;
    Thickness?: number;
    VendorId?: number;
    Laminated: boolean;
    InterlayerThickness?: number;
    InterlayerTypeId?: number;
    SilkScreenColorId?: number;
    SilkScreenPatternId?: number;
    ColorR?: number;
    ColorG?: number;
    ColorB?: number;
    OpticsFile: string;
    CanBeUsedForMonolithic: boolean;
    CanBeUsedForDoublePaneAsOutboardLayer: boolean;
    CanBeUsedForDoublePaneAsInboardLayer: boolean;
    CanBeUsedForTriplePaneAsOutboardLayer: boolean;
    CanBeUsedForTriplePaneAsMiddleLayer: boolean;
    CanBeUsedForTriplePaneAsInboardLayer: boolean;
    NeedsGlazingSystemReCalc: boolean;
    PublishDate?: Date;
    LeedPoints?: number;
    Archived: boolean;

    // reverse nav
    Documents?: IDocument[];
    GlassColors?: IGlassColor[];
    GlassTypes?: IGlassType[];
    GlazingSystemSolidLayers?: IGlazingSystemSolidLayer[];

    // foreign keys
    InterlayerType?: IInterlayerType;
    SilkScreenColor?: ISilkScreenColor;
    SilkScreenPattern?: ISilkScreenPattern;
    Vendor?: IVendor;
}

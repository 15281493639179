import { IEntity } from './base';

import { IClimateZone } from './climate-zone';
import { IUserWindowTotalProduct } from './user-window-total-product';
import { IWindowFrame } from './window-frame';
import { IWindowFrameProductCategory } from './window-frame-product-category';
import { ICustomSizingRule } from './custom-sizing-rule';

export interface IProductCategory extends IEntity {
    Name: string;
    CustomSizingRuleId?: number;
    ParentId?: number;
    IsLeed: boolean;

    // reverse nav
    ClimateZones?: IClimateZone[];
    ProductCategories?: IProductCategory[];
    UserWindowTotalProducts?: IUserWindowTotalProduct[];
    WindowFrames?: IWindowFrame[];
    WindowFrameProductCategories?: IWindowFrameProductCategory[];

    // foreign keys
    CustomSizingRule?: ICustomSizingRule;
    ProductCategory?: IProductCategory;
}

<div class="panel panel-default">
    <div class="panel-heading">
        <h4>2. Select Glass Product Options</h4>
    </div>
    <div class="panel-body">
        <div>
            <div class="btn-group">
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': layers.length !== 1,
                        'btn-primary active': layers.length === 1
                    }"
                    (click)="toggleMonolithicFilter()"
                >
                    Monolithic
                </button>
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': layers.length !== 2,
                        'btn-primary active': layers.length === 2
                    }"
                    (click)="toggleDoublePaneFilter()"
                >
                    Double Pane
                </button>
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': layers.length !== 3,
                        'btn-primary active': layers.length === 3
                    }"
                    (click)="toggleTriplePaneFilter()"
                >
                    Triple Pane
                </button>
            </div>
        </div>
        <br />
        <cdk-accordion multi>
            <cdk-accordion-item
                *ngFor="let layer of layers"
                #accordionItem="cdkAccordionItem"
                role="button"
                tabindex="0"
            >
                <div class="panel panel-default">
                    <div class="panel-heading" (click)="accordionItem.toggle()">
                        <div class="pull-left">
                            <h4>{{ getLayerHeading(layer) }}</h4>
                        </div>
                        <div class="pull-right">
                            <i
                                class="fa"
                                style="margin-top: 50%"
                                [ngClass]="
                                    accordionItem.expanded
                                        ? 'fa-chevron-down'
                                        : 'fa-chevron-right'
                                "
                            ></i>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        class="panel-body"
                    >
                        <layer-filter
                            [layer]="layer"
                            [glassColorItems]="glassColorItems"
                            [glassTypeItems]="glassTypeItems"
                            [interlayerTypeItems]="interlayerTypeItems"
                            [silkScreenColorItems]="silkScreenColorItems"
                            [silkScreenPatternItems]="silkScreenPatternItems"
                            [vendorItems]="vendorItems"
                            (updateLayerParams)="
                                updateLayerParams($event, layer)
                            "
                        >
                        </layer-filter>
                    </div>
                </div>
            </cdk-accordion-item>
            <cdk-accordion-item
                *ngIf="layers && layers.length > 1"
                #accordionItem="cdkAccordionItem"
                role="button"
                tabindex="0"
            >
                <div class="panel panel-default">
                    <div class="panel-heading" (click)="accordionItem.toggle()">
                        <div class="pull-left">
                            <h4>Overall</h4>
                        </div>
                        <div class="pull-right">
                            <i
                                class="fa"
                                style="margin-top: 50%"
                                [ngClass]="
                                    accordionItem.expanded
                                        ? 'fa-chevron-down'
                                        : 'fa-chevron-right'
                                "
                            ></i>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        class="panel-body"
                    >
                        <overall-filter
                            [gasTypeItems]="gasTypeItems"
                            (updateOverallParams)="updateOverallParams($event)"
                        >
                        </overall-filter>
                    </div>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="panel panel-default">
            <div class="panel-heading product-performance-header">Glass Product Performance</div>
            <div class="panel-body">
                <ng-content></ng-content>
                <p *ngIf="!glazingResult">Please select a glass product for each layer and define the air in each gap to calculate performance data.</p>
                <p *ngIf="isMissingOpticsFile">The glass options you have selected have not returned Glass Product Performance results.
                    Please adjust your selections, call 1-866-OLDCASTLE (653-2278), or <a (click)="navigateToContactUs()">contact us</a> for additional assistance.
                </p>
                <p *ngIf="glazingResult && !isMissingOpticsFile">Review product performance details below based on your selections:</p>
                <table *ngIf="glazingResult && glassLayers && !isMissingOpticsFile" class="table table-bordered table-condensed table-small performance-table">
                    <tr>
                        <th>Thickness<br> (in) / (mm)</th>
                        <th>Visible Trans. (%)</th>
                        <th>Visible Refl. Out (%)</th>
                        <th>Visible Refl. In (%)</th>
                        <th>UV Trans. (%)</th>
                        <th>Solar Trans. (%)</th>
                        <th>Solar Refl. Out (%)</th>
                        <th>Winter U-factor (Btu/h·ft2·F)</th>
                        <th>Winter U-factor (W/m2·K)</th>
                        <th>Shading Coeff.</th>
                        <th>Solar Heat Gain Coeff.</th>
                        <th>Light to Solar Gain</th>
                    </tr>
                    <tr>
                        <td>{{ mmToIn(glazingResult.Thickness) | number: '1.3-3' }} / {{ glazingResult.Thickness | number: '1.3-3' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.VisibleTransmittance | percent: '1.0-0') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.VisibleReflectanceOutside | percent: '1.0-0') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.VisibleReflectanceInside | percent: '1.0-0') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? formatUvTransmittance(glazingResult.UVTransmittance) : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.SolarTransmittance | percent: '1.0-0') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.SolarReflectanceOutside | percent: '1.0-0') : 'N/A' }}</td>
                        <td>{{ uFactorMetricToImp(glazingResult.UFactorWinter).toFixed(2)}}</td>
                        <td>{{ glazingResult.UFactorWinter | number: '1.2-2' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.ShadingCoefficient | number: '1.2-2') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.Shgc | number: '1.2-2') : 'N/A' }}</td>
                        <td>{{ !isSpandrel ? (glazingResult.LightToSolarGain | number: '1.2-2') : 'N/A' }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

import { IWindowGlazingSystem } from './window-glazing-system';

export interface IWindowSolidLayer {
    WindowGlazingSystemId: number;
    Layer: number;
    GlassIgdbId: number;
    GlassName: string;
    Thickness: number;
    Flipped: boolean;

    // foreign keys
    WindowGlazingSystem?: IWindowGlazingSystem;
}

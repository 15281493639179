<h2 id="welcome-header">Welcome to SystemSelect<span class="title-restricted">&trade;</span></h2>
<p>SystemSelect&trade; from Oldcastle BuildingEnvelope® is an industry-leading proprietary tool designed to offer design professionals the freedom to fully evaluate the thermal and visual performance of entire architectural glass and metal systems.</p>
<p>SystemSelect&trade; allows you the unprecedented ability to compare these critical performance criteria across a range of fenestration solutions so you can understand and control product selection early in the design phase of projects.</p>
<p>The tool allows you to save your projects so you can easily return and adapt your selected systems to future projects.</p>
<p>Use the menu above, or buttons below, to build your glass makeup or full fenestration system today.</p>
Note:
<p>SystemSelect&trade; is designed to calculate thermal and visual performance results. Any limitation on input criteria set by this tool or output results does not qualify, limit or imply the acceptability of these materials with respect to structural performance and/or manufacturability. Review of total product application and structural limitations must be completed by qualified fenestration and/or engineering professionals. For projects requiring glass sizes larger than 65 square feet, or to review the structural limitations on specific job applications or for additional assistance, please contact 866-653-2278.</p>
<hr/>
<div class="row action-items">
    <div class="col-md-3 text-center">
        <a [routerLink]="['/total-product-builder']" title="Total Product Builder">
            <button class="btn btn-primary action-btn">Build a Total Product</button>
        </a>
        <div class="well action-description-text">
            The total product builder provides you maximum performance details based on your selected metal
            framing system and either an actual glazing product or center of glass performance.
        </div>
    </div>
    <div class="col-md-1 text-center">
    </div>
    <div class="col-md-4 text-center">
        <a [routerLink]="['/glass-builder']" title="Glass Product Builder">
            <button class="btn btn-primary action-btn">Build a Glass Product</button>
        </a>
        <div class="well action-description-text middle-block">
            The glass product builder allows you to customize a variety of glass product configurations,
            including selecting color, number of panes, special coatings, and much more.
        </div>
    </div>
    <div class="col-md-1 text-center">
    </div>
    <div class="col-md-3 text-center">
        <a [routerLink]="['/product-recommendations']" title="Glass Product Recommendations">
            <button class="btn btn-primary action-btn">Get Product Recommendations</button>
        </a>
        <div class="well action-description-text">
            The glass product recommendation tool allows you to select your performance criteria and customize your glass
            preference to see all available options that meet your parameters.
        </div>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGlazingSystemSolidLayer, IGlazingSystemGasLayer, IGasType, IGlassColor, IGlassType, IGlass, IVendor, ISilkScreenColor, ISilkScreenPattern, IInterlayerType, IGlazingSystem, ISilkScreenLayerSelectedDTO, GlassTypes, MyProductsListTypes } from '@system-select/model';
import { GasTypeService, SpandrelTypeService, ProjectService, GlassColorService, GlassTypeService, GlassService, VendorService, SilkScreenColorService, SilkScreenPatternService, GlazingSystemService, IGlazingPerformanceRequest, IGlazingGap, IGlazingPerformance, InterlayerTypeService, UserService } from '@system-select/web-services';
import { forkJoin } from 'rxjs';
import { UnitsService } from '@system-select/common';
import { MeasurementPipe } from '@system-select/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MyProductsService } from '../my-products/my-products.service';
import Swal from 'sweetalert2';
import { AuthService } from '@mt-ng2/auth-module';
import { IRealSurfaceLayer } from '../common/interfaces/real-surface-layer';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ISpandrelSelectionDTO } from '@system-select/model';
import { ISpandrelType } from '@system-select/model';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { FileService } from '@system-select/common';
import { ProductDocumentService } from '@system-select/web-services';

@Component({
    selector: 'app-glass-builder',
    styles: [`
        .well {
            padding: 11px !important;
        }
        .swal-btn {
            font-size: 12px;
            width: 120%;
        }
        .col-xs-offset-2.actions {
            margin-left: 10.66666667%;
        }
    `],
    templateUrl: './glass-builder.component.html',
})

export class GlassBuilderComponent implements OnInit {
    @Input() isLeedReportEmbedded: boolean;
    @Output() onGlazingSystemSelected: EventEmitter<IGlazingSystem> = new EventEmitter<IGlazingSystem>();

    createGlazingSystemModalApi: IModalWrapperApi;
    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;

    glassSystemId: number;
    currentUserId: number;
    windowGlazingSystemId: number;
    glazingSystem: IGlazingSystem;
    numberOfLayers: number;
    solidLayers: IGlazingSystemSolidLayer[];
    gasLayers: IGlazingSystemGasLayer[];
    gasTypeItems: IGasType[];
    glassColorItems: IGlassColor[];
    glassTypeItems: IGlassType[];
    silkScreenColorItems: ISilkScreenColor[];
    silkScreenPatternItems: ISilkScreenPattern[];
    interlayerTypeItems: IInterlayerType[];
    vendorItems: IVendor[];
    glassProducts: IGlass[];
    filteredGlassProducts: IGlass[];
    filteredGlassProductsForAdditionalLayers: IGlass[];
    defaultSolidLayer: IGlazingSystemSolidLayer;
    glazingResult: IGlazingPerformance;
    silkScreenSelectedDTO: ISilkScreenLayerSelectedDTO = {
        Layer: 0,
        SilkScreen: false,
    };
    selectedGlassLayers: IGlass[];
    isAuthenticated: boolean;
    isSaving: boolean;
    minLayerCount = 1;
    maxLayerCount = 3;
    productSurfaceCount = 0;
    productSurfaceLayers: IRealSurfaceLayer[] = [];
    defaultGlassName: string;
    glassNames: string[] = [];
    // Default to the NRFC standards (metric)
    height = 1000;
    width = 1000;
    isMetricUnits = true;
    widthInputControl = new UntypedFormControl();
    heightInputControl = new UntypedFormControl();
    isGeneratingDataSheet = false;
    isLoadingPerformance = false;
    isMissingOpticsFile = false;
    needsAuthenticationForDatasheet = false;
    glazingPerformanceRequest: IGlazingPerformanceRequest;
    productListTypes = MyProductsListTypes;
    spandrelTypeId: number = null;
    spandrelColor = '';
    isSavingToProject = false;
    selectedProjectId: number;
    needsRedirect: boolean;
    userHasProjects: boolean;
    isSpandrel: boolean;
    spandrelTypes: ISpandrelType[];
    spandrelLayer: number;
    spandrelLayers = {
        Inboard: 2,
        Outboard: 1,
    };
    containsLaminate: boolean;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    glassDocsToDownloadAfterLogin: IGlass = null;

    constructor(
        private gasTypeService: GasTypeService,
        private glassColorService: GlassColorService,
        private glassTypeService: GlassTypeService,
        private glassService: GlassService,
        private glazingSystemService: GlazingSystemService,
        private vendorService: VendorService,
        private silkScreenColorService: SilkScreenColorService,
        private silkScreenPatternService: SilkScreenPatternService,
        private interlayerTypeService: InterlayerTypeService,
        private measurementPipe: MeasurementPipe,
        private route: ActivatedRoute,
        private myProductsService: MyProductsService,
        private unitsService: UnitsService,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        private spandrelTypeService: SpandrelTypeService,
        private productDocumentService: ProductDocumentService,
    ) { }

    ngOnInit(): void {
        this.initializeMeasurementInputControls();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.projectService.getAll().subscribe((answer) => this.userHasProjects = answer.length > 0);
        });
        this.glassSystemId = +this.route.snapshot.queryParams.id;
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.windowGlazingSystemId = +this.route.snapshot.queryParams.windowGlazingSystemId;
        if (this.glassSystemId) {
            this.glazingSystemService.getById(this.glassSystemId)
                .subscribe((gs) => {
                    this.glazingSystem = gs;
                    this.destructureExistingGlazingSystem();
                });
        } else if (this.windowGlazingSystemId) {
            this.glazingSystemService.getGlazingSystemFromWindowGlazingSystem(this.windowGlazingSystemId)
                .subscribe((gs) => {
                    this.glazingSystem = gs;
                    this.destructureExistingGlazingSystem();
                }, () => {
                    Swal.fire(
                        'Whoops!',
                        'That product does not exist in the system...',
                        'error',
                    ).catch(() => null);
                });
        } else {
            this.initializeGlazingSystem();
        }
        forkJoin(
            this.gasTypeService.getItems(),
            this.glassColorService.getItems(),
            this.glassTypeService.getItems(),
            this.vendorService.getItems(),
            this.silkScreenColorService.getItems(),
            this.silkScreenPatternService.getItems(),
            this.interlayerTypeService.getItems(),
            this.spandrelTypeService.getAll(),
        ).subscribe((data) => {
            [
                this.gasTypeItems,
                this.glassColorItems,
                this.glassTypeItems,
                this.vendorItems,
                this.silkScreenColorItems,
                this.silkScreenPatternItems,
                this.interlayerTypeItems] = [data[0], data[1], data[2], data[3], data[4], data[5], data[6]];
            this.spandrelTypes = data[7];
        });
    }

    initializeMeasurementInputControls(): void {
        this.heightInputControl
            .valueChanges.pipe(
                debounceTime(300))
            .subscribe(() => this.calculatePerformanceResults());
        this.widthInputControl
            .valueChanges.pipe(
                debounceTime(300))
            .subscribe(() => this.calculatePerformanceResults());
    }

    updateAuthenticationStatus(): void {
        this.loginPopupModalApi.close();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.currentUserId = this.authService.currentUser.getValue().Id;
            this.projectService.getAll().subscribe((answer) => {
                this.userHasProjects = answer.length > 0;
                if (this.isSaving) {
                    this.needsRedirect = true;
                    void this.saveGlazingSystemToProject();
                }
            });
            if (this.needsAuthenticationForDatasheet) {
                this.downloadDataSheet();
            } else if (this.glassDocsToDownloadAfterLogin) {
                this.downloadLeedDocuments(this.glassDocsToDownloadAfterLogin);
                this.glassDocsToDownloadAfterLogin = null;
            }
        });
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }

    getDatasheetDownloadLabel(): string {
        return this.isGeneratingDataSheet ? 'Generating Spec Sheet...' : 'Download Spec Sheet';
    }

    destructureExistingGlazingSystem(): void {
        this.glazingResult = {
            LightToSolarGain: this.glazingSystem.LightToSolarGain,
            RelativeHeatGain: this.glazingSystem.RelativeHeatGain,
            ShadingCoefficient: this.glazingSystem.ShadingCoefficient,
            Shgc: this.glazingSystem.Shgc,
            SolarReflectanceOutside: this.glazingSystem.SolarReflectanceOutside,
            SolarTransmittance: this.glazingSystem.SolarTransmittance,
            Thickness: this.glazingSystem.Thickness,
            UFactorSummer: this.glazingSystem.UFactorSummer,
            UFactorWinter: this.glazingSystem.UFactorWinter,
            UVTransmittance: this.glazingSystem.UvTransmittance,
            VisibleReflectanceInside: this.glazingSystem.VisibleReflectanceInside,
            VisibleReflectanceOutside: this.glazingSystem.VisibleReflectanceOutside,
            VisibleTransmittance: this.glazingSystem.VisibleTransmittance,
        };
        this.numberOfLayers = this.glazingSystem.GlazingSystemSolidLayers.length;
        this.solidLayers = this.glazingSystem.GlazingSystemSolidLayers;
        this.selectedGlassLayers = this.solidLayers.map((sl) => {
            return sl.Glass;
        });
        const spandrelLayers = this.solidLayers.filter((sl) => sl.SpandrelTypeId != null);
        if (spandrelLayers.length > 0) {
            this.isSpandrel = true;
            this.spandrelLayer = spandrelLayers[0].Layer;
            this.spandrelTypeId = spandrelLayers[0].SpandrelTypeId;
            this.spandrelColor = spandrelLayers[0].SpandrelColor;
        }
        this.gasLayers = this.glazingSystem.GlazingSystemGasLayers;
        this.defaultSolidLayer = this.solidLayers.find((sl) => sl.Layer === 1);
        this.solidLayers.splice(this.solidLayers.indexOf(this.defaultSolidLayer), 1);
    }

    initializeGlazingSystem(): void {
        this.numberOfLayers = 1;
        this.glazingSystem = this.glazingSystemService.getEmptyGlazingSystem();
        this.glazingResult = null;
        this.solidLayers = [];
        this.gasLayers = [];
        this.defaultSolidLayer = {
            GlassId: null,
            GlazingSystemId: 0,
            Layer: 0,
        };
    }

    checkForExistingSilkScreenedLayers(solidLayers: IGlazingSystemSolidLayer[]): void {
        const existingSilkScreenLayer = solidLayers.find((sl) => {
            return sl.Glass.GlassTypes.some((gt) => gt.Id === GlassTypes.SilkScreened);
        });
        if (existingSilkScreenLayer) {
            this.silkScreenSelectedDTO = {
                Layer: existingSilkScreenLayer.Layer,
                SilkScreen: true,
            };
            this.updateSelectedSilkScreenLayer(this.silkScreenSelectedDTO);
        }
    }

    changeNumberOfLayers(newNumberOfLayers: number): void {
        const difference = newNumberOfLayers - this.numberOfLayers;
        if (difference > 0) {
            for (let i = 0; i < difference; i++) {
                this.addLayer();
            }
        } else if (difference < 0) {
            for (let i = 0; i < Math.abs(difference); i++) {
                this.removeLayer();
            }
        }
    }

    addLayer(): void {
        this.numberOfLayers += 1;
        this.solidLayers.push({
            GlassId: null,
            GlazingSystemId: 0,
            Layer: 0,
        });

        this.gasLayers.push({
            GasTypeId: null,
            GlazingSystemId: 0,
            Layer: 0,
            Thickness: 0,
        });
    }

    removeLayer(): void {
        this.numberOfLayers -= 1;
        this.solidLayers.pop();
        this.gasLayers.pop();
        this.calculatePerformanceResults();
    }

    updateSelectedSilkScreenLayer(silkScreenSelectedDTO: ISilkScreenLayerSelectedDTO): void {
        this.silkScreenSelectedDTO = silkScreenSelectedDTO;
    }

    updateSelectedGlassProductsList(selectedGlassProduct: IGlass, index?: number): void {
        if (index != null) {
            this.solidLayers[index].GlassId = selectedGlassProduct.Id;
            this.solidLayers[index].Layer = index + 2;
            this.solidLayers[index].Glass = selectedGlassProduct;
            this.glassNames[index] = selectedGlassProduct.Name;
        } else {
            this.defaultSolidLayer.GlassId = selectedGlassProduct.Id;
            this.defaultSolidLayer.Layer = 1;
            this.defaultSolidLayer.Glass = selectedGlassProduct;
            this.defaultGlassName = selectedGlassProduct.Name;
        }
        this.containsLaminate = this.solidLayers.filter((sl) => sl.Glass && sl.Glass.Laminated).length > 0 || (this.defaultSolidLayer.Glass && this.defaultSolidLayer.Glass.Laminated);
        this.calculatePerformanceResults();
    }

    updateGasLayerList(updatedGasLayer: IGlazingSystemGasLayer, index?: number): void {
        if (index != null && updatedGasLayer) {
            this.gasLayers[index].GasTypeId = updatedGasLayer.GasTypeId;
            this.gasLayers[index].Thickness = updatedGasLayer.Thickness;
        } else {
            this.gasLayers[index].GasTypeId = null;
            this.gasLayers[index].Thickness = null;
        }

        this.calculatePerformanceResults();
    }

    updateSpandrelSelection(updatedSpandrelSelectionDTO: ISpandrelSelectionDTO): void {
        this.spandrelTypeId = updatedSpandrelSelectionDTO.SpandrelTypeId;
        this.spandrelColor = updatedSpandrelSelectionDTO.SpandrelColor;
        this.spandrelLayer = updatedSpandrelSelectionDTO.Layer;
        this.isSpandrel = this.spandrelTypeId ? true : false;
    }

    buildPerformanceRequest(): IGlazingPerformanceRequest {
        this.selectedGlassLayers = this.solidLayers.map((sl) => {
            return sl.Glass;
        });

        this.selectedGlassLayers.push(this.defaultSolidLayer.Glass);

        const glassIds = this.solidLayers.map((sl) => {
            return sl.GlassId;
        });

        glassIds.unshift(this.defaultSolidLayer.GlassId);

        if (glassIds.length === 0 || glassIds.includes(null)) {
            return null;
        }

        const gapData: IGlazingGap[] = this.gasLayers
            .map((gl) => {
                if (!gl.GasTypeId || !gl.Thickness) {
                    return null;
                }
                return {
                    GasTypeId: gl.GasTypeId,
                    Width: Math.round(gl.Thickness * 100000) / 100000, // 5 decimal points
                };
            });

        if (gapData.includes(null) && gapData.length > 0) {
            return null;
        }

        const convertedMeasurements = this.convertUnitsForPerformanceRequest();

        const glazingPerformanceRequest: IGlazingPerformanceRequest = {
            GapData: gapData,
            GlassIds: glassIds,
            Height: convertedMeasurements.Height,
            SpandrelColor: '',
            SpandrelTypeId: null,
            Width: convertedMeasurements.Width,
        };

        return glazingPerformanceRequest;
    }

    calculatePerformanceResults(): void {
        const glazingPerformanceRequest = this.buildPerformanceRequest();

        if (glazingPerformanceRequest === null) {
            this.glazingResult = null;
            return;
        }
        this.isLoadingPerformance = true;

        this.glazingSystemService.getPerformance(glazingPerformanceRequest)
            .subscribe((answer) => {
                this.glazingResult = answer;
                this.isLoadingPerformance = false;
                this.isMissingOpticsFile = false;
            }, () => {
                this.isMissingOpticsFile = true;
                this.isLoadingPerformance = false;
            });
    }

    downloadDataSheet(): void {
        if (this.isAuthenticated) {
            const glazingPerformanceRequest = this.buildPerformanceRequest();
            glazingPerformanceRequest.SpandrelTypeId = this.spandrelTypeId;
            glazingPerformanceRequest.SpandrelColor = this.spandrelColor;
            if (glazingPerformanceRequest === null) {
                return;
            }
            // Use as input to spec sheet input forms
            this.glazingPerformanceRequest = glazingPerformanceRequest;
            this.isGeneratingDataSheet = true;
        } else {
            this.needsAuthenticationForDatasheet = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }

    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

    convertUnitsForPerformanceRequest() {
        return {
            Height: this.isMetricUnits ? this.height : this.unitsService.inToMm(this.height),
            Width: this.isMetricUnits ? this.width : this.unitsService.inToMm(this.width),
        };
    }

    async saveGlazingSystemToProject(): Promise<void> {
        // If this view is part of the LEED Report builder just output the glazing system
        if (this.glazingResult && this.isLeedReportEmbedded) {
            await this.assignGlazingSystemValues(false);
            this.onGlazingSystemSelected.emit(this.glazingSystem);
            return;
        }
        if (this.isAuthenticated) {
            if (this.userHasProjects) {
                this.isSavingToProject = true;
            } else {
                this.updateSaveProjectId(null);
            }
        } else {
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    updateSaveProjectId(projectId: number): void {
        this.selectedProjectId = projectId;
        void this.saveGlazingSystem();
    }

    async saveGlazingSystem(): Promise<void> {
        if (this.isAuthenticated) {
            if (this.glazingResult) {
                await this.assignGlazingSystemValues(true);
                this.glazingSystem.UserSaveDate = new Date();
                this.glazingSystem.ProjectId = this.selectedProjectId;
                // if it comes from a recommended product, make a copy of the system
                if (this.glazingSystem.Id > 0) {
                    this.glazingSystemService.createGlazingSystemCopy(this.glazingSystem).subscribe(() => {
                        this.isSavingToProject = false;
                        setTimeout(() => {
                            this.createAccountModalApi.show();
                        }, 0);
                    });
                } else {
                    this.glazingSystem.UserId = this.currentUserId;
                    this.glazingSystemService.createWithFks(this.glazingSystem).subscribe(() => {
                        this.myProductsService.productSaved.emit();
                        this.isSaving = false;
                        this.isSavingToProject = false;
                        if (this.needsRedirect) {
                            this.router.navigate(['/my-products']).catch(() => null);
                        } else {
                            setTimeout(() => {
                                this.createGlazingSystemModalApi.show();
                            }, 0);
                        }
                    });
                }
            }
        } else {
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    private async assignGlazingSystemValues(clearNav: boolean): Promise<void> {
        this.glazingSystem = this.mapObjectKeys(this.glazingResult, this.glazingSystem) as any;

        const dimensions = this.convertUnitsForPerformanceRequest();

        this.glazingSystem.Width = dimensions.Width;
        this.glazingSystem.Height = dimensions.Height;

        // Property not caught by the mapObjectKeys method (asked NGIN to fix the model generator for this bug)
        this.glazingSystem.UvTransmittance = this.glazingResult.UVTransmittance;
        this.glazingSystem.Layers = this.numberOfLayers;
        this.glazingSystem.GlazingSystemGasLayers = this.gasLayers.slice();
        this.glazingSystem.GlazingSystemGasLayers.forEach((gl) => {
            gl.GasType = this.gasTypeItems.find((gti) => gti.Id === gl.GasTypeId);
        });
        this.glazingSystem.GlazingSystemSolidLayers = this.solidLayers.slice();
        this.glazingSystem.GlazingSystemSolidLayers.push(this.defaultSolidLayer);
        if (this.spandrelTypeId && this.numberOfLayers <= 2) {
            const spandrelLayer = this.glazingSystem.GlazingSystemSolidLayers.filter((sl) => sl.Layer === this.numberOfLayers)[0];
            spandrelLayer.SpandrelTypeId = this.spandrelTypeId;
            spandrelLayer.SpandrelColor = this.spandrelColor;
        }
        if (this.checkForMultipleSilkScreenedLayers(this.glazingSystem.GlazingSystemSolidLayers)) {
            await Swal.fire(
                'Whoops!',
                'Only one lite in a Glass System may be Silk-Screened, and you have already selected this option for another lite.',
                'error',
            );
            return;
        }
        // clear nav properties (if necessary)
        if (clearNav) {
            this.glazingSystem.GlazingSystemGasLayers.forEach((item) => {
                item.Thickness = Math.round(item.Thickness * 100000) / 100000; // 5 decimal points
                item.GasType = null;
            });
            this.glazingSystem.GlazingSystemSolidLayers.forEach((item) => item.Glass = null);
        }
    }

    mapObjectKeys(objectA: object, objectB: object) {
        for (const prop in objectA) {
            if (prop in objectB) {
                objectB[prop] = objectA[prop];
            }
        }
        return objectB;
    }

    checkForMultipleSilkScreenedLayers(solidLayers: IGlazingSystemSolidLayer[]): boolean {
        const layerGlassTypeIds = solidLayers
            .map((sl) => sl.Glass.GlassTypes)
            .filter((glassTypes) => glassTypes.some((gt) => gt.Id === GlassTypes.SilkScreened));
        return layerGlassTypeIds.length > 1;
    }

    updateProductSurfaceCount(realSurfaceLayer: IRealSurfaceLayer): void {
        const existingRealSurfaceLayer = this.getExistingRealSurfaceLayer(realSurfaceLayer);
        if (existingRealSurfaceLayer) {
            this.productSurfaceLayers.splice(this.productSurfaceLayers.indexOf(existingRealSurfaceLayer), 1);
        }
        this.productSurfaceLayers.push(realSurfaceLayer);
        this.productSurfaceLayers = this.productSurfaceLayers.slice();
    }

    getExistingRealSurfaceLayer(realSurfaceLayer: IRealSurfaceLayer): IRealSurfaceLayer {
        return this.productSurfaceLayers.find((sl) => {
            return sl.Layer === realSurfaceLayer.Layer;
        });
    }

    closeDialog(): void {
        this.createGlazingSystemModalApi.close();
    }

    clearForNext(): void {
        this.initializeGlazingSystem();
    }

    getUnitsOfMeasurement(): string {
        return this.isMetricUnits ? '(mm)' : '(in)';
    }

    getLabelOfMeasurement(): string {
        return this.isMetricUnits ? 'Metric' : 'Imperial';
    }

    toggleUnitsOfMeasurement(): void {
        this.isMetricUnits = !this.isMetricUnits;
        this.calculatePerformanceResults();
    }

    // === Methods to format displays === //

    displaySolidLayer(index?: number): string {
        if (index !== 0 && !index) {
            return this.setLayerLabel(index) + ': ' + this.displayGlassName(null, true);
        } else if (this.solidLayers[index].GlassId) {
            return this.setLayerLabel(index) + ': ' + this.displayGlassName(index, false);
        }
        return '';
    }

    displayGasLayer(index: number): string {
        const thickness = this.measurementPipe.transform(this.unitsService.mmToIn(this.gasLayers[index].Thickness));
        if (this.gasLayers && this.gasLayers[index].GasTypeId && this.gasLayers[index].Thickness) {
            if (this.gasTypeItems) {
                const airType = this.gasTypeItems.find((gt) => {
                    return gt.Id === this.gasLayers[index].GasTypeId * 1;
                });
                return 'AS:' + thickness + ' (' + airType.Name + ')';
            }
        }
        return '';
    }

    setLayerLabel(index?: number): string {
        if (index == null) {
            return 'Outboard';
        } else {
            if (index === this.solidLayers.length - 1) {
                return 'Inboard';
            } else {
                return 'Center Glass';
            }
        }
    }

    displayGlassName(index: number, isDefault: boolean): string {
        if (isDefault) {
            if (this.isSpandrel) {
                const spandrel = this.spandrelTypes.find((type) => type.Id === +this.spandrelTypeId);
                if (this.spandrelLayer === this.spandrelLayers.Outboard) {
                    return this.formatSpandrelGlassName(this.defaultGlassName, '#2', spandrel);
                }
            }
            return this.defaultGlassName;
        } else {
            if (this.isSpandrel) {
                const spandrel = this.spandrelTypes.find((type) => type.Id === +this.spandrelTypeId);
                if (this.spandrelLayer === this.spandrelLayers.Inboard) {
                    return !this.containsLaminate ?
                        this.formatSpandrelGlassName(this.glassNames[index], '#4', spandrel) :
                        this.formatSpandrelGlassName(this.glassNames[index], '#6', spandrel);
                }
            }
            return this.glassNames[index];
        }
    }

    formatSpandrelGlassName(glassName: string, spandrelSurfaceNumber: string, spandrel: ISpandrelType): string {
        return `${glassName} with ${spandrel.Name} ${spandrelSurfaceNumber} \n Color: ${this.spandrelColor ? this.spandrelColor : ''}`;
    }

    saveToProjectModalClosed(): void {
        this.isSavingToProject = false;
    }

    // Gas layers won't ever have LEED documents so first check whether the ID matches a Glass layer
    layerHasLeedDocuments(layer: IGlazingSystemSolidLayer): boolean {
        return layer.Glass && layer.Glass.Documents.length > 0;
    }

    downloadLeedDocuments(glass: IGlass): void {
        if (!this.isAuthenticated) {
            this.glassDocsToDownloadAfterLogin = glass;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
            return;
        }

        if (glass.Documents && glass.Documents.length === 1) {
            this.productDocumentService.downloadLeedDocuments(glass.Documents);
        } else {
            this.glassService.getProductDocumentsZipped(glass.Id).subscribe((resp) => {
                FileService.save(resp, 'Documents.zip', 'application/zip', false);
            });
        }
    }
}

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { GlassBuilderComponent } from './glass-builder.component';
import { AuthGuard } from '@mt-ng2/auth-module';

const glassBuilderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: GlassBuilderComponent,
        path: 'glass-builder',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            glassBuilderRoutes,
        ),
    ],
})
export class GlassBuilderRoutingModule {

}

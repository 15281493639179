<div class="products-container">
    <ng-container *ngIf="recommendedProducts && recommendedProducts.length">
        <mt-entity-list class="performance-table saved-glazings-table table-xs first-header-green"
            [entities]="recommendedProducts"
            [(itemsPerPage)]="itemsPerPage"
            [total]="total"
            [(currentPage)]="currentPage"
            (pageChanged)="getGlazingSystems()"
            (columnSortedEvent)="columnSorted($event)"
            [emptyMessage]=""
            [entityListConfig]="entityListConfig">
        </mt-entity-list>
    </ng-container>
    <div *ngIf="!recommendedProducts || !recommendedProducts.length">
        <br>
        No results for the options you have selected, please adjust your selections,
        call 1-866-OLDCASTLE (653-2278), or
        <a [routerLink]="[]" (click)="navigateToContactUs()">contact us</a> for additional assistance
    </div>
</div>

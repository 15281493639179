export interface IWindowTotalProductFilter {
    ClimateZoneId: number;
    UFactorLowValue: string,
    UFactorHighValue: string,
    ShgcLowValue: string,
    ShgcHighValue: string,
    VisibleTransmittanceLowValue: string,
    VisibleTransmittanceHighValue: string,
    CondensationResistanceLowValue: string,
    CondensationResistanceHighValue: string,
    UseActualGlazingProduct: boolean;
    UseGenericCOGValue: boolean;
    ProductCategoryId: number;
}

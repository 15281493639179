import { LoginModule } from '../login/login.module';
import { CreateAccountModule } from '../create-account/create-account.module';
import { ProjectModule } from '../projects/project.module';
import { SpecSheetModule } from '../data-sheets/spec-sheet.module';
import { ModalModule } from '@mt-ng2/modal-module';
import { SharedPublicModule } from '../common/shared.module';
import { CommonModule as LocalCommonModule } from '@system-select/common';
import { LeedReportBuilderRoutingModule } from './leed-report-builder-routing.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LeedReportBuilderInterfaceComponent } from './leed-report-builder-interface/leed-report-builder-interface.component';
import { LeedReportBuilderNavigationComponent } from './leed-report-builder-navigation/leed-report-builder-navigation.component';
import { CommonModule } from '@angular/common';
import { LeedReportProjectComponent } from './leed-report-project/leed-report-project.component';
import { LeedReportService, CustomProductInquiryService } from '@system-select/web-services';
import { CustomProductInquiryComponent } from './custom-product-inquiry/custom-product-inquiry.component';
import { LeedReportChoosePathComponent } from './leed-report-choose-path/leed-report-choose-path.component';
import { LeedReportGlassBuilderComponent } from './leed-report-glass-builder/leed-report-glass-builder.component';
import { GlassBuilderModule } from '../glass-builder/glass-builder.module';
import { LeedReportMetalBuilderComponent } from './leed-report-metal-builder/leed-report-metal-builder.component';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { NgxPaginationModule } from 'ngx-pagination';
import { LeedReportCompleteComponent } from './leed-report-complete/leed-report-complete.component';
import { SharedModule } from 'src/app/common/shared.module';
import { LeedReportSummaryComponent } from './leed-report-complete/leed-report-summary.component';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
    declarations: [
        LeedReportBuilderInterfaceComponent,
        LeedReportBuilderNavigationComponent,
        LeedReportProjectComponent,
        CustomProductInquiryComponent,
        LeedReportChoosePathComponent,
        LeedReportGlassBuilderComponent,
        LeedReportMetalBuilderComponent,
        LeedReportCompleteComponent,
        LeedReportSummaryComponent,
    ],
    imports: [
        LeedReportBuilderRoutingModule,
        SharedPublicModule,
        LocalCommonModule,
        LoginModule,
        CreateAccountModule,
        ProjectModule,
        GlassBuilderModule,
        SpecSheetModule,
        ModalModule,
        CommonModule,
        MultiselectControlModule,
        NgxPaginationModule,
        SharedModule,
        NgxLoadingModule,
    ],
})
export class LeedReportBuilderModule {
    static forRoot(): ModuleWithProviders<LeedReportBuilderModule> {
        return {
            ngModule: LeedReportBuilderModule,
            providers: [LeedReportService, CustomProductInquiryService],
        };
    }
}

export enum GlassColors {
    LowIron = 1,
    Clear = 2,
    White = 3,
    Green = 4,
    BlueGreen = 5,
    Blue = 6,
    Gray = 7,
    Bronze = 8,
    Silver = 0,
}

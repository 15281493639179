import { IEntity } from './base';

import { IWindowFrame } from './window-frame';

export interface ISystemDepth extends IEntity {
    Name: string;

    // reverse nav
    WindowFrames?: IWindowFrame[];
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGlass } from '@system-select/model';
import { GlassService } from '@system-select/web-services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-solid-layer-product-select',
    styles: [`
    .product-pagination ::ng-deep .ngx-pagination .current {
        background: #00573d !important;
    }

    button.active, button.active:hover {
        background-color: #00573d;
        color: white;
    }
        /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .glass-description {
            width: 220px;
            vertical-align: top;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .glass-description {
            width: 400px;
            vertical-align: top;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .glass-description {
            width: 500px;
            vertical-align: top;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .glass-description {
            width: 490px;
            vertical-align: top;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .glass-description {
            width: 590px;
            vertical-align: top;
        }
    }
    `],
    templateUrl: './solid-layer-product-select.component.html',
})
export class SolidLayerProductSelectComponent implements OnInit {
    @Input() glassProducts: IGlass[];
    @Input() layerLabel: string;
    @Input() glassId?: number;
    @Output() updateSelectedGlassProduct: EventEmitter<IGlass> = new EventEmitter<IGlass>();

    selectedGlassProduct: IGlass;
    currentPage = 1;

    constructor(
        private glassService: GlassService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.selectedGlassProduct = this.glassService.getEmptyGlass();
        if (this.glassId) {
           this.initializeSelectedGlassProduct();
        }
    }

    initializeSelectedGlassProduct(): void {
        this.selectedGlassProduct = this.glassProducts.find((gp) => gp.Id === this.glassId);
        this.updateSelectedGlassProduct.emit(this.selectedGlassProduct);
    }

    isActiveProduct(productId: number): boolean {
        return this.selectedGlassProduct && productId === this.selectedGlassProduct.Id;
    }

   selectGlassProduct(selectedGlassProduct: IGlass): void {
       this.selectedGlassProduct = selectedGlassProduct;
       this.updateSelectedGlassProduct.emit(this.selectedGlassProduct);
   }

   getSwatchColor(product: IGlass): string {
        const value = `rgb(${product.ColorR}, ${product.ColorG}, ${product.ColorB})`;
        return value;
   }

   async navigateToContactUs(): Promise<void> {
    await this.router.navigate(['/contact-us']);
}
}

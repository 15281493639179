import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IGasType, IVendor } from '@system-select/model';
import { UnitsService } from '@system-select/common';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
@Component({
    selector: 'overall-filter',
    templateUrl: './overall-filter.component.html',
})
export class OverallFilterComponent {
    @Input() gasTypeItems: IGasType[];
    @Input() vendorItems: IVendor[];
    @Output() updateOverallParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();

    gasTypeId: number;
    thickness: number;
    airSpaceItems = [0.25, 0.5, 0.75, 1];
    vendorId: number;

    constructor(
        private unitsService: UnitsService,
    ) { }

    buildOverallParams(): void {
        const _overallParams: ExtraSearchParams[] = [];
        if (this.thickness) {
            _overallParams.push(new ExtraSearchParams({
                name: 'AirSpace',
                value: this.thickness.toString(),
            }));
        }
        if (this.gasTypeId) {
            _overallParams.push(new ExtraSearchParams({
                name: 'GasContent',
                value: this.gasTypeId.toString(),
            }));
        }
        this.updateOverallParams.emit(_overallParams);
    }

    clearGasTypeFilter(): void {
        this.gasTypeId = null;
        this.buildOverallParams();
    }

    clearThicknessFilter(): void {
        this.thickness = null;
        this.buildOverallParams();
    }

}

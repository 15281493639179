import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { WindowTotalProductService } from '@system-select/web-services';
import { FileService } from '@system-select/common';
import { GlazingSystemService } from '@system-select/web-services';
import { IGlazingPerformanceRequest } from '@system-select/web-services';
import { ISpecSheetParams, MyProductsListTypes } from '@system-select/model';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'spec-sheet-input-form',
    styles: [`
        .form-error {
            color: red;
        }
        .form-control {
            border-radius: 0;
            margin-top: 10px;
        }
    `],
    templateUrl: 'spec-sheet-input-form.component.html',
})

export class SpecSheetInputFormComponent implements OnInit, OnChanges {
    inputFormModalApi: IModalWrapperApi;
    @Input() displayForm: boolean;
    @Input() selectedProductId: number;
    @Input() productListType: MyProductsListTypes;
    @Input() glazingPerformanceRequest: IGlazingPerformanceRequest;
    @Output() emitSuccessfulDownload: EventEmitter<boolean> = new EventEmitter<boolean>();
    specSheetParams: ISpecSheetParams;
    displayEmailForm = false;
    loadingResult = false;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(private windowTotalProductService: WindowTotalProductService, private glazingSystemService: GlazingSystemService) { }

    ngOnInit(): void {
        this.specSheetParams = {
            Customer: '',
            Project: '',
        };
    }

    ngOnChanges(): void {
        if (this.displayForm) {
            setTimeout(() => {
                this.inputFormModalApi.show();
                // let cancelButton = this.specSheetInputFormSwal.nativeSwal.getCancelButton();
                // cancelButton.addEventListener('click', () => {
                //     this.emitSuccessfulDownload.emit(false);
                // });
            }, 0);
        }
    }

    save(): void {
        this.loadingResult = true;
        if (this.selectedProductId) {
            this.getSpecSheetBySelectedProductId();
        }
        if (this.glazingPerformanceRequest) {
            this.getSpecSheetByPerformanceRequest();
        }
    }

    getSpecSheetBySelectedProductId(): void {
        if (this.productListType === MyProductsListTypes.GlassProducts) {
            this.glazingSystemService.getDataSheetById(this.selectedProductId, this.specSheetParams)
                .subscribe((answer) => {
                    FileService.save(answer.body, 'SystemSelect Spec Sheet.pdf', 'application/pdf', true);
                    this.success();
                });
        } else if (this.productListType === MyProductsListTypes.TotalProducts) {
            this.windowTotalProductService.getDataSheetById(this.selectedProductId, this.specSheetParams)
                .subscribe((answer) => {
                    FileService.save(answer.body, 'SystemSelect Spec Sheet.pdf', 'application/pdf', true);
                    this.success();
                });
        }
    }

    getSpecSheetByPerformanceRequest(): void {
        this.glazingSystemService.getDataSheet({
            DataSheetParams: {
                EmailParams: null,
                SpecSheetParams: this.specSheetParams,
            },
            GlazingPerformanceRequest: this.glazingPerformanceRequest,
        }).subscribe((answer) => {
            FileService.save(answer, 'SystemSelect Spec Sheet.pdf', 'application/pdf', true);
            this.success();
        });
    }

    toggleDisplayEmail(): void {
        this.displayEmailForm = !this.displayEmailForm;
    }

    success(): void {
        this.loadingResult = false;
        this.inputFormModalApi.close();
        this.emitSuccessfulDownload.emit();
    }

    onSuccessfulEmail(isEmailSent: boolean): void {
        if (isEmailSent) {
            this.displayEmailForm = false;
            this.emitSuccessfulDownload.emit(true);
        } else {
            this.toggleDisplayEmail();
            setTimeout(() => {
                this.inputFormModalApi.show();
            }, 0);
        }
    }
}

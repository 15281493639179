<table class="table table-hover" *ngIf="projects && projects.length > 0">
    <thead>
        <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Default</th>
            <th *ngIf="!isLeedReportEmbedded">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr
            class="project-list-item"
            *ngFor="
                let project of projects
                    | paginate
                        : {
                              itemsPerPage: 5,
                              currentPage: currentProjectsPage,
                              id: 'projects-list-pagination'
                          }
            "
            [ngClass]="{ selected: selectedProject?.Id === project.Id }"
            (click)="updateSelectedProject(project)"
        >
            <td>{{ project.Name }}</td>
            <td>{{ project.Description }}</td>
            <td>{{ project.Default | yesNo }}</td>
            <td *ngIf="!isLeedReportEmbedded">
                <button
                    (click)="editProject(project)"
                    class="btn btn-xs btn-default"
                    title="Edit"
                >
                    <i class="fa fa-edit"></i>
                </button>
                <button
                    (click)="deleteProject(project)"
                    class="btn btn-xs btn-default"
                    title="Delete"
                >
                    <i class="fa fa-close"></i>
                </button>
            </td>
        </tr>
    </tbody>
    <pagination-controls
        class="project-pagination"
        (pageChange)="currentProjectsPage = $event"
        id="projects-list-pagination"
    ></pagination-controls>
</table>
<button class="btn btn-default" (click)="addNewProject()">
    <i class="fa fa-plus"></i> Add New Project
</button>
<button
    class="btn btn-default"
    *ngIf="selectedProject"
    (click)="clearSelectedProject()"
>
    Clear Selected Project
</button>
<!--For adding projects-->
<project-basic-info
    [displayForm]="isAddingNewProject"
    [isLeedReportEmbedded]="isLeedReportEmbedded"
    [project]="emptyProject"
    (endEditing)="endAddingProject($event)"
    (onProjectCreated)="updateSelectedProject($event)"
></project-basic-info>
<!--For editing projects-->
<project-basic-info
    [displayForm]="isEditingProject"
    [project]="selectedProject"
    (endEditing)="endEditingProject($event)"
></project-basic-info>

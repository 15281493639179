import { ModuleWithProviders, NgModule } from '@angular/core';
import { CreateAccountComponent } from './create-account.component';
import { CreateAccountFormComponent } from './create-account-form/create-account-form.component';
import { SharedPublicModule } from '../common/shared.module';
import { CreateAccountRoutingModule } from './create-account.routing.module';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { AuthEntityService, UserService } from '@system-select/web-services';
import { AuthService } from '@mt-ng2/auth-module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CreateAccountComponent,
        CreateAccountFormComponent,
    ],
    exports: [
        CreateAccountComponent,
    ],
    imports: [
        CreateAccountRoutingModule,
        SharedPublicModule,
        DynamicFormModule,
        CommonModule,
        FormsModule,
    ],
    providers: [
        AuthEntityService,
        UserService,
        AuthService,
    ],
})
export class CreateAccountModule {
  static forRoot(): ModuleWithProviders<CreateAccountModule> {
    return {
        ngModule: CreateAccountModule,
        providers: [
        ],
    };
  }
}

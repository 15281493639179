import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IProductCategory, IWindowFrame, LeedReportBuilderFormSteps } from '@system-select/model';
import { ProductCategoryService, WindowFramesService } from '@system-select/web-services';
import { ProductDocumentService } from '@system-select/web-services';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { AuthService } from '@mt-ng2/auth-module';
import { FileService } from '@system-select/common';

@Component({
    selector: 'leed-report-metal-builder',
    styleUrls: ['./leed-report-metal-builder.component.css'],
    templateUrl: 'leed-report-metal-builder.component.html',
})

export class LeedReportMetalBuilderComponent implements OnInit {
    productCategories: IProductCategory[];
    selectedProductCategory: IProductCategory;
    selectedFrame: IWindowFrame;
    currentPage: number;
    query = '';
    itemsPerPage = 10000;
    windowFrames: IWindowFrame[] = [];
    total: number;

    isAuthenticated: boolean;
    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;

    downloadLeedDocumentsAfterLogin = false;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(
        private productCategoryService: ProductCategoryService,
        private windowFrameService: WindowFramesService,
        private router: Router,
        private stateService: LeedReportBuilderInterfaceStateService,
        private productDocumentService: ProductDocumentService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
        });
        this.productCategoryService.getAll().subscribe((pc) => {
            this.productCategories = pc.filter((p) => p.ParentId === null && p.IsLeed);
        });
    }

    updateSelectedProductCategory(): void {
        this.currentPage = 1;
        this.getWindowFrames();
    }

    clearSelectedFrame(): void {
        this.selectedFrame = null;
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProductCategoryId',
            value: this.selectedProductCategory.Id.toString(),
        }));
        return _extraSearchParams;
    }

    getWindowFrames(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Name',
            orderDirection: 'asc',
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.windowFrameService.get(searchparams).subscribe(
            (answer) => {
                this.windowFrames = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    onFrameSelected(): void {
        this.stateService.windowFrame$.next(this.selectedFrame);
        this.stateService.markStepCompleted(LeedReportBuilderFormSteps.Metal);
        this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Complete);
    }

    navigateToContactUs(): void {
        void this.router.navigate(['/contact-us']);
    }

    hasLeedDocuments(): boolean {
        return this.selectedFrame && this.selectedFrame.Documents.length > 0;
    }

    downloadLeedDocuments(): void {
        if (!this.isAuthenticated) {
            this.downloadLeedDocumentsAfterLogin = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);

            return;
        }

        if (this.selectedFrame.Documents.length === 1) {
            this.productDocumentService.downloadLeedDocuments(this.selectedFrame.Documents);
        } else {
            this.windowFrameService.getProductDocumentsZipped(this.selectedFrame.Id).subscribe((zip) => {
                FileService.save(zip as unknown as string, 'Documents.zip', 'application/zip', false);
            });
        }
    }

    updateAuthenticationStatus(): void {
        this.loginPopupModalApi.close();
        this.createAccountModalApi.close();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;

            if (this.downloadLeedDocumentsAfterLogin) {
                this.downloadLeedDocuments();
            }
        });
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }
}

import { IFrame } from './frame';

export interface IFrameMeshNode {
    FrameId: number;
    MeshNodeId: number;
    X: number;
    Y: number;
    Temperature: number;
    IsTransition: boolean;
    IsEdgeNode: boolean;
    Sort: number;

    // foreign keys
    Frame?: IFrame;
}

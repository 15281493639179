<p *ngIf="isEmbedded">
    Please sign in or create an account to save your selection and access spec
    sheets.
</p>
<h2>Login</h2>
<div class="login-box">
    <div class="login-box-body">
        <div *ngIf="!config.hideRegularSignIn">
            <form [formGroup]="loginForm" (submit)="onLogin()">
                <div
                    class="form-group has-feedback"
                    [class.has-error]="isControlInvalid('username', 'required')"
                >
                    <input
                        type="text"
                        autofocus
                        class="form-control"
                        placeholder="Email"
                        formControlName="username"
                    />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div
                        *ngIf="isControlInvalid('username', 'required')"
                        class="small errortext"
                        [style.position]="'absolute'"
                    >
                        Email is required
                    </div>
                </div>
                <div
                    class="form-group has-feedback"
                    [class.has-error]="isControlInvalid('password', 'required')"
                >
                    <input
                        #Password
                        type="password"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Password"
                        formControlName="password"
                    />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div
                        *ngIf="isControlInvalid('password', 'required')"
                        class="small errortext"
                        [style.position]="'absolute'"
                    >
                        Password is required
                    </div>
                </div>
                <div
                    class="padded block-parent"
                    [ngStyle]="{ 'text-align': isEmbedded ? 'left' : '' }"
                >
                    <div
                        *ngIf="config.rememberFeature.rememberOptions"
                        class="form-check"
                    >
                        <label class="form-check-label" for="rememberMe">
                            <input
                                type="checkbox"
                                id="rememberMe"
                                class="form-check-input"
                                formControlName="rememberMe"
                            />
                            Remember Me</label
                        ><br />
                        <a
                            routerLink="/forgotpassword"
                            [target]="isEmbedded ? '_blank' : '_self'"
                            [ngClass]="{ 'embedded-padding': isEmbedded }"
                            >Forgot Password</a
                        >
                    </div>
                    <button
                        [disabled]="!loginForm.valid"
                        type="submit"
                        class="btn btn-primary btn-flat inline-block block-right"
                        [ngClass]="{ 'embedded-padding': isEmbedded }"
                    >
                        {{ config.messageOverrides.signInButtonText }}
                    </button>
                    &nbsp;
                    <button
                        (click)="handleCreateAccount()"
                        class="btn btn-primary btn-flat inline-block block-right"
                        [ngClass]="{ 'embedded-padding': isEmbedded }"
                    >
                        Create Account
                    </button>
                </div>
            </form>
            <hr />
            <p id="feedback">Have Feedback?</p>
            <p>
                Take our
                <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSekWB4LINApg82Y6p-9t1Jz404Tsg1VzfGj_ClG3h7OEbfWtg/viewform?usp=sf_link"
                    target="_blank"
                    alt="Survey"
                    >quick survey</a
                >
                to provide your input.
            </p>
        </div>
    </div>
</div>

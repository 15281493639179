import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "@mt-ng2/auth-module";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { UserService } from "@system-select/web-services";

import { FormGroup } from "@angular/forms";
import { common } from "@mt-ng2/common-functions";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import {
    IAuthUser,
    ICreatePublicUserPayload,
    IPublicUserDetail,
    IUser,
    PublicUserRoles,
} from "@system-select/model";
import { CommonService } from "src/app/common/services/common.service";
import { AuthUserDynamicConfig } from "../../common/config/auth-user.dynamic-config";
import { PublicUserDynamicConfig } from "../../common/config/public-user-detail.dynamic-config";
import { UserDynamicConfig } from "../../common/config/user.dynamic-config";
import { LoginService } from "../../common/services/login.service";

@Component({
    selector: "create-account-form",
    styles: [
        `
        .embedded-padding {
            display: inline-block;
            margin-top: 10px;
        }
        .has-error {
            color: #a94442;
        }
        .form-control .has-error {
            border-color: #a94442;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        }
        .other-role-input {
            margin-bottom: 15px;
        }`,
    ],
    templateUrl: "./create-account-form.component.html",
})
export class CreateAccountFormComponent implements OnInit {
    @Input("isEmbedded") isEmbedded: boolean;
    @Output("emitSuccessfulLogin") emitSuccessfulLogin: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    authUser: IAuthUser;
    additionalConfigs = [];
    isEditing: boolean;
    isHovered: boolean;
    userForm: FormGroup;
    formFactory: UserDynamicConfig<IUser>;
    user: IUser;
    states: { Id: string; Name: string }[];
    configLoaded = false;
    roleId: number = null;
    roles = [
        { Id: 1, Name: "Architect" },
        { Id: 2, Name: "Developer" },
        { Id: 3, Name: "Contractor" },
        { Id: 4, Name: "Glazier Subcontractor" },
        { Id: 5, Name: "OBE Employee" },
        { Id: 6, Name: "Other" },
    ];
    role = "";
    isOtherRole = false;
    roleSelected = true;
    optIn = false;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private loginService: LoginService,
        private commonService: CommonService
    ) {}

    ngOnInit(): void {
        this.user = this.userService.getEmptyUser();
        this.commonService.getStates().subscribe((states) => {
            this.states = states.map((state) => {
                return {
                    Id: state.StateCode,
                    Name: state.Name,
                };
            });
            this.setConfig();
        });
    }

    getAdditionalConfigs() {
        const pwConfigControls: string[] = ["Password", "ConfirmPassword"];
        const authUser: IAuthUser = null;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(
            authUser,
            null,
            pwConfigControls
        );
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, null);
        const publicUserDetail: IPublicUserDetail = null;
        const publicUserDetailConfig =
            new PublicUserDynamicConfig<IPublicUserDetail>(
                publicUserDetail,
                this.states
            );
        return [pwConfig, roleConfig, publicUserDetailConfig];
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        const config = this.formFactory.getForCreate(this.additionalConfigs);
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
        this.configLoaded = true;
    }

    cancelClick(): void {
        void this.router.navigate(["/login"]);
    }

    formSubmitted(form: FormGroup): void {
        let passwordmatch = true;
        if (!this.authService.matchPassword(form)) {
            passwordmatch = false;
        }
        if (this.roleId === null || (this.isOtherRole && this.role === "")) {
            this.roleSelected = false;
        } else {
            this.roleSelected = true;
        }
        if (form.valid && passwordmatch && this.roleSelected) {
            this.formFactory.assignFormValues(
                this.user,
                form.value.User as IUser
            );
            form.value.PublicUserDetail.Role = this.roles.find(
                (role) => role.Id === +this.roleId
            ).Name;
            const data: ICreatePublicUserPayload = {
                City: form.value.PublicUserDetail.City,
                CompanyName: form.value.PublicUserDetail.CompanyName,
                OptedIn: this.optIn,
                Password: form.value.AuthUser.Password,
                Role: this.isOtherRole
                    ? this.role
                    : form.value.PublicUserDetail.Role,
                SendEmail: false,
                State: form.value.PublicUserDetail.State,
                User: this.user,
                // User their email as their username
                Username: form.value.User.Email,
                // Set User Role explicitly server side
                UserRoleId: 0,
            };
            // handle new user save
            this.userService.createPublicUser(data).subscribe(
                () => {
                    this.authService
                        .login(
                            form.value.User.Email as string,
                            form.value.AuthUser.Password as string,
                            false
                        )
                        .subscribe(() => {
                            if (this.isEmbedded) {
                                this.emitSuccessfulLogin.emit(true);
                                this.loginService.loggedIn.emit(true);
                                this.userService.emitChange(this.user);
                                this.success();
                            } else {
                                void this.router.navigate(["/my-products"]);
                                this.userService.emitChange(this.user);
                                this.loginService.loggedIn.emit(true);
                                this.success();
                            }
                        });
                },
                () => {
                    this.error();
                }
            );
        } else {
            if (!passwordmatch) {
                this.error("Passwords do not match");
            } else if (!this.roleSelected) {
                this.error("Role is required");
            } else {
                common.markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    checkForOtherRole(): void {
        this.role = "";
        this.isOtherRole = +this.roleId === PublicUserRoles.Other;
        this.roleSelected = +this.roleId !== null;
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(
                `Save failed.  Please check the form and try again.`
            );
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success("Account Created Successfully");
    }
}

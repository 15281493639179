<h2>Choose a Path</h2>
<div class="row">
    <div class="col-md-4">
        <button
            class="btn btn-primary btn-path-choice"
            (click)="onPathSelected([LeedReportBuilderFormSteps.Glass])"
        >
            Glass
        </button>
    </div>
    <div class="col-md-4">
        <button
            class="btn btn-primary btn-path-choice"
            (click)="onPathSelected([LeedReportBuilderFormSteps.Metal])"
        >
            Metal
        </button>
    </div>
    <div class="col-md-4">
        <button
            class="btn btn-primary btn-path-choice"
            (click)="
                onPathSelected([
                    LeedReportBuilderFormSteps.Glass,
                    LeedReportBuilderFormSteps.Metal
                ])
            "
        >
            Both
        </button>
    </div>
</div>

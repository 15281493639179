import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IGlass, IEntity, IGlazingSystem, ILeedReport, IDocument } from '@system-select/model';
import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { FormatService, IGlazingSystemLayer, FileService } from '@system-select/common';
import swal from 'sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { LeedReportService, ProductDocumentService, GlassService, WindowFramesService } from '@system-select/web-services';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Injectable()
export class LeedReportsListConfig extends EntityListConfig {
    @Output('onItemDeleted') onItemDeleted = new EventEmitter<ILeedReport>();
    constructor(
    ) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    component: SavedLeedReportGlazingSystemsGlassCellComponent,
                    name: 'Glass System',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (item: ILeedReport) => {
                        return item.GlazingSystem?.GlazingSystemSolidLayers?.map((gs) => {
                            return gs.Glass.LeedPoints;
                        }).reduce((total, score) => total + score)?.toString() ?? '';
                    },
                    name: 'LEED v4 Score',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: SavedLeedReportsFrameCellComponent,
                    name: 'Metal System',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (item: ILeedReport) => {
                        return item.WindowFrame?.LeedPoints?.toString() ?? '';
                    },
                    name: 'LEED v4 Score',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: SavedLeedReportsActionComponent,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });

        this.getDefaultSortProperty = () => 'Id';
    }
}

@Component({
    template: `
    <ng-container *ngIf="glazingSystem">
    <div class="d-tc v-align-top" [style.padding-right]="'5px'">
            <div class="color-swatch" [style.background-color]="'rgb(' + glass.ColorR + ', ' + glass.ColorG + ', ' + glass.ColorB + ')'"></div>
        </div>
        <div class="d-tc v-align-top">
            <div *ngFor="let layer of layers">
                {{layer.Prefix}}: {{layer.Name}}
                <button
                *ngIf="layerHasLeedDocuments(layer)"
                (click)="downloadLeedDocuments(layer.Id)" class="btn btn-xs btn-default" title="Download LEED Documents"
            >
                <i class="fa fa-file-pdf-o"></i>
            </button>
            </div>
        </div>
    </ng-container>
    `,
})
export class SavedLeedReportGlazingSystemsGlassCellComponent implements IEntityListDynamicCellComponent {
    item: ILeedReport;
    glazingSystem: IGlazingSystem | null;
    glass: IGlass | null;
    entityListComponentMembers: IEntityListComponentMembers;
    layers: IGlazingSystemLayer[];

    constructor(
        private formatService: FormatService,
        private productDocumentService: ProductDocumentService,
        private glassService: GlassService,
    ) { }

    set entity(value: IEntity) {
        this.item = value as ILeedReport;
        this.glazingSystem = this.item?.GlazingSystem;
        this.glass = this.glazingSystem?.GlazingSystemSolidLayers[0].Glass;
        this.layers = this.getLayers();
    }

    getLayers(): IGlazingSystemLayer[] {
        return this.formatService.formatGlazingLayers(this.glazingSystem?.GlazingSystemSolidLayers ?? [], this.glazingSystem?.GlazingSystemGasLayers ?? []);
    }

    // Gas layers won't ever have LEED documents so verify it's a solid layer
    layerHasLeedDocuments(layer: IGlazingSystemLayer): boolean {
        return layer.IsSolidLayer &&
            this.glazingSystem?.GlazingSystemSolidLayers.find((gs) => gs.GlassId === layer.Id).Glass.Documents.length > 0;
    }

    downloadLeedDocuments(glassId: number): void {
        const glass = this.glazingSystem?.GlazingSystemSolidLayers?.find((gs) => gs.GlassId === glassId)?.Glass;
        if (glass.Documents && glass.Documents.length === 1) {
            this.productDocumentService.downloadLeedDocuments(glass.Documents);
        } else {
            this.glassService.getProductDocumentsZipped(glassId).subscribe((resp) => {
                FileService.save(resp as unknown as string, 'Documents.zip', 'application/zip', false);
            });
        }
    }
}

@Component({
    template: `
    <ng-container *ngIf="item.WindowFrame">
        <div class="d-tc v-align-top">
            <b>{{ item.WindowFrame.Name }}</b>
            <button
            *ngIf="hasLeedDocuments()"
                (click)="downloadLeedDocuments()" class="btn btn-xs btn-default" title="Download LEED Documents"
            >
                <i class="fa fa-file-pdf-o"></i>
            </button>
            <ng-container *ngIf="item.WindowFrame.SiteLink">
                <br>
                <a href="{{item.WindowFrame.SiteLink}}" target="_blank">Link to URL</a>
            </ng-container>
        </div>
    </ng-container>
    `,
})
export class SavedLeedReportsFrameCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: ILeedReport;

    constructor(
        private productDocumentService: ProductDocumentService,
        private windowFramesService: WindowFramesService,
    ) { }

    set entity(value: IEntity) {
        this.item = value as ILeedReport;
    }

    hasLeedDocuments(): boolean {
        return this.item.WindowFrame?.Documents.length > 0;
    }

    downloadLeedDocuments(): void {
        if (this.item.WindowFrame.Documents.length === 1) {
            this.productDocumentService.downloadLeedDocuments(this.item.WindowFrame?.Documents ?? []);
        } else {
            this.windowFramesService.getProductDocumentsZipped(this.item.WindowFrame?.Id).subscribe((zip) => {
                FileService.save(zip as unknown as string, 'Documents.zip', 'application/zip', false);
            });
        }
    }
}

@Component({
    styles: [`
        .ib-container {
            white-space: nowrap;
        }
    `],
    template: `
        <div class="ib-container p-sm">
            <button
                (click)="getLeedReportDocuments(item)" class="btn btn-xs btn-default" title="Download LEED Report Documents"
            >
                <i class="fa fa-file-pdf-o"></i>
            </button>
                <button
                (click)="deleteLeedReport(item)"  [disabled]="!item.Id" class="btn btn-xs btn-default" title="Delete"
            >
                <i class="fa fa-close"></i>
            </button>
        </div>
    `,
})
export class SavedLeedReportsActionComponent implements IEntityListDynamicCellComponent {
    item: ILeedReport;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(
        private leedReportService: LeedReportService,
        private notificationsService: NotificationsService,
    ) {
    }

    set entity(value: IEntity) {
        this.item = value as ILeedReport;
    }

    getLeedReportDocuments(item: ILeedReport): void {
        const windowFrameDocs = item.WindowFrame ? item.WindowFrame.Documents : [];
        // Flattens an array of Document arrays (IDocument[][]) then groups the Documents to avoid downloading duplicate docs
        const glassDocs = item.GlazingSystem ? item.GlazingSystem.GlazingSystemSolidLayers.map((gs) => gs.Glass.Documents).reduce((acc, d) => [...acc, ...d], []) : [];
        const groupedDocs = windowFrameDocs.concat(glassDocs).reduce((acc, value) => {
            if (!acc[value.Id]) {
                acc[value.Id] = [];
            }
            acc[value.Id].push(value);
            return acc;
        }, {});
        const uniqueDocs: IDocument[] = [];
        for (const key in groupedDocs) {
            if (Object.prototype.hasOwnProperty.call(groupedDocs, key)) {
                const element = groupedDocs[key] as IDocument[];
                uniqueDocs.push(element[0]);
            }
        }

        if (uniqueDocs.length === 0) {
            this.leedReportService.getLeedReportSheet(item.Id).subscribe((pdf) => {
                FileService.save(pdf, 'LEED Report.pdf', 'application/pdf', true);
            });
        } else {
            // the parts of this LEED have documents so we have to zip everything
            this.leedReportService.getLeedReportDocumentsZipped(item.Id).subscribe((zip) => {
                FileService.save(zip as unknown as string, 'LEED Report.zip', 'application/zip', false);
            });
        }
    }

    deleteLeedReport(item: ILeedReport): void {
        swal.fire(<SweetAlertOptions>{
            focusCancel: false,
            focusConfirm: false,
            showCancelButton: true,
            showCloseButton: true,
            text: 'Are you sure you want to delete this from your saved LEED Reports?',
            title: 'Confirm',
            type: 'warning',
        }).then((answer) => {
            if (answer.value) {
                this.leedReportService.delete(item.Id).subscribe(
                    () => {
                        (<LeedReportsListConfig>this.entityListComponentMembers.entityListConfig).onItemDeleted.emit(item);
                    }, () => {
                        this.notificationsService.error('Unable to delete LEED Report.');
                    });
            }
        }).catch(() => null);
    }
}

<h2>My Saved Products</h2>
<h3>My Projects</h3>
<projects-list
    (onProjectSelected)="updateSelectedProjectId($event)"
></projects-list>
<hr />
<h3>Glass Products</h3>
<button
    class="btn btn-xs btn-default"
    title="Download Glazing List Sheet"
    (click)="downloadGlazingListSheet()"
>
    <i class="fa fa-file-pdf-o"></i>
</button>
<button
    class="btn btn-xs btn-default"
    title="Email Glazing List Sheet"
    (click)="sendGlazingListSheetEmail()"
>
    <i class="fa fa-envelope-o"></i>
</button>
<div *ngIf="loadingResult" class="loader-small"></div>
<products-list-sheet-email-form
    [displayForm]="isSendingGlazingListEmail"
    (emitSuccessfulEmail)="onSuccessfulEmail()"
    [productListType]="productsListTypes.GlassProducts"
    [selectedProjectId]="selectedProjectId"
></products-list-sheet-email-form>
<glazing-systems-list
    [selectedProjectId]="selectedProjectId"
></glazing-systems-list>

<h3>Total Products</h3>
<button
    class="btn btn-xs btn-default"
    title="Download Total Product List Sheet"
    (click)="downloadTotalProductListSheet()"
>
    <i class="fa fa-file-pdf-o"></i>
</button>
<button
    class="btn btn-xs btn-default"
    title="Email Total Product List Sheet"
    (click)="sendTotalProductListSheetEmail()"
>
    <i class="fa fa-envelope-o"></i>
</button>
<div *ngIf="loadingResult" class="loader-small"></div>
<products-list-sheet-email-form
    [displayForm]="isSendingTotalProductListEmail"
    (emitSuccessfulEmail)="onSuccessfulEmail()"
    [productListType]="productsListTypes.TotalProducts"
    [selectedProjectId]="selectedProjectId"
></products-list-sheet-email-form>
<window-total-products-list
    [selectedProjectId]="selectedProjectId"
></window-total-products-list>
<hr />

<h3>LEED Reports</h3>
<div *ngIf="loadingResult" class="loader-small"></div>
<leed-reports-list [selectedProjectId]="selectedProjectId"></leed-reports-list>
<p id="feedback">Have Feedback?</p>
<p>
    Take our
    <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSekWB4LINApg82Y6p-9t1Jz404Tsg1VzfGj_ClG3h7OEbfWtg/viewform?usp=sf_link"
        target="_blank"
        alt="Survey"
        >quick survey</a
    >
    to provide your input.
</p>

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AuthService } from "@mt-ng2/auth-module";
import { IModalOptions, IModalWrapperApi } from "@mt-ng2/modal-module";
import { NotificationsService } from "@mt-ng2/notifications-module";
import {
    IExpandableObject,
    IProject,
    IProjectTimeline,
    IUser,
    ProjectDynamicControlsPartial,
} from "@system-select/model";
import {
    ProjectService,
    ProjectTimelineService,
    UserService,
} from "@system-select/web-services";
import { forkJoin } from "rxjs";
import { CommonService } from "src/app/common/services/common.service";

@Component({
    selector: "project-basic-info",
    styles: [
        `
        .form {
            font-size: 14px;
        }`,
    ],
    templateUrl: "project-basic-info.component.html",
})
export class ProjectBasicInfoComponent implements OnInit, OnChanges {
    @Input() isLeedReportEmbedded: boolean;
    projectInfoModalApi: IModalWrapperApi;
    @Input() project: IProject;
    @Input() displayForm: boolean;
    projectTimelines: IProjectTimeline[];
    @Output() endEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onProjectCreated: EventEmitter<IProject> =
        new EventEmitter<IProject>();

    isEditing = false;
    isHovered: boolean;
    formCreated = false;
    states: { Id: string; Name: string }[];
    modalOptions: IModalOptions = {};
    customClass: {
        container: "project-modal";
    };
    currentUserId: number;
    currentUser: IUser;
    isAuthenticated: boolean;

    abstractProjectControls: IExpandableObject;
    projectForm: UntypedFormGroup;

    get isNewProject(): boolean {
        return this.project && this.project.Id ? false : true;
    }

    constructor(
        private projectService: ProjectService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private projectTimelineService: ProjectTimelineService,
        private commonService: CommonService,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            if (isAuthed) {
                this.currentUserId = this.authService.currentUser.getValue().Id;
                if (this.currentUserId) {
                    this.userService
                        .getById(this.currentUserId)
                        .subscribe((user) => {
                            this.currentUser = user;
                        });
                }
            }
        });

        forkJoin([
            this.projectTimelineService.getAll(),
            this.commonService.getStates(),
        ]).subscribe(([timelines, states]) => {
            this.projectTimelines = timelines;
            this.states = states.map((s) => {
                return {
                    Id: s.StateCode,
                    Name: s.StateCode,
                };
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.displayForm && this.displayForm) {
            this.createForm();
            setTimeout(() => {
                this.projectInfoModalApi.show();
                this.autofillFormFields();
            }, 0);
        } else {
            this.abstractProjectControls = null;
            this.formCreated = false;
            this.projectForm = null;
        }
    }

    createForm(): void {
        this.getControls();
        this.projectForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        this.isEditing = this.isNewProject;
    }

    getControls(): void {
        this.abstractProjectControls = new ProjectDynamicControlsPartial(
            this.project,
            {
                formGroup: "Project",
                projectTimelines: this.projectTimelines,
                states: this.states,
            }
        ).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Project: this.fb.group({
                CompanyType: [
                    this.project.CompanyType,
                    (ctrl: AbstractControl) => Validators.required(ctrl),
                ],
            }),
        });
    }

    onFormCreated(form: UntypedFormGroup): void {
        this.projectForm = form;
        this.autofillFormFields();
    }

    /**
     * Autofill form fields on a new project if the user is logged in
     */
    autofillFormFields(): void {
        if (this.currentUser && this.isNewProject && this.projectForm) {
            (<UntypedFormGroup>(
                this.projectForm.controls.Project
            )).controls.ContactEmail.setValue(this.currentUser.Email, {
                emitEvent: false,
            });
            (<UntypedFormGroup>(
                this.projectForm.controls.Project
            )).controls.ContactName.setValue(
                `${this.currentUser.FirstName} ${this.currentUser.LastName}`,
                { emitEvent: false }
            );
        }
    }

    cancelEditing(): void {
        this.projectInfoModalApi.close();
        this.endEditing.emit(false);
    }

    formSubmitted(): void {
        if (this.projectForm.valid) {
            Object.assign(this.project, this.projectForm.value.Project);

            // If this project is created as part of the LEED Report workflow and the user is not authenticated just emit the Project
            if (!this.isAuthenticated && this.isLeedReportEmbedded) {
                this.onProjectCreated.emit(this.project);
                this.projectInfoModalApi.close();
                this.endEditing.emit(true);
            } else {
                this.project.UserId =
                    this.authService.currentUser.getValue().Id;
                this.saveProject();
            }
        } else {
            this.notificationsService.error(
                "Save failed.  Please check the form and try again."
            );
        }
    }

    private saveProject(): void {
        if (this.isNewProject) {
            this.projectService.create(this.project).subscribe((answer) => {
                this.project.Id = answer;
                this.success();
            });
        } else {
            this.projectService.update(this.project).subscribe((success) => {
                const id = success as unknown as number;
                this.project.Id = id;
                this.success();
            });
        }
    }

    private success(): void {
        this.projectService.emitChange(this.project);
        this.notificationsService.success("Project saved successfully.");
        this.projectInfoModalApi.close();
        this.endEditing.emit(true);
    }
}

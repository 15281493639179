import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
    ExtraSearchParams,
    IEntitySearchParams,
    SearchParams,
} from "@mt-ng2/common-classes";
import { UnitsService } from "@system-select/common";
import type {
    IEntity,
    IFramingSystemCategory,
    IGlass,
    IProductCategory,
    ITotalWindowResult,
    IWindowFrame,
    IWindowGasLayerWrapper,
    IWindowGlazingSystem,
    IWindowGlazingSystemWrapper,
    IWindowTotalProduct,
    IWindowTotalProductFilter,
    IWindowTotalProductsSearchItem,
} from "@system-select/model";
import { MyProductsListTypes } from "@system-select/model";
import {
    IGlazingPerformance,
    ProjectService,
    SystemDepthService,
    UserWindowTotalProductService,
    WindowTotalProductService,
} from "@system-select/web-services";
import { debounceTime } from "rxjs/operators";
import { MyProductsService } from "src/public/my-products/my-products.service";

import { UntypedFormControl } from "@angular/forms";
import { AuthService } from "@mt-ng2/auth-module";
import { IModalOptions, IModalWrapperApi } from "@mt-ng2/modal-module";
import {
    ISelectionChangedEvent,
    MultiselectItem,
} from "@mt-ng2/multiselect-control";
import { FormatService } from "@system-select/common";
import {
    ProductCategoryService,
    SightlineService,
} from "@system-select/web-services";
import {
    IGlazingSystemSolidLayer,
    IWindowSolidLayer,
} from "src/app/model/public-api";

@Component({
    selector: "total-product",
    styleUrls: ["./total-product-builder.component.css"],
    templateUrl: "./total-product-builder.component.html",
})
export class TotalProductBuilderComponent implements OnInit {
    id: number;
    parentCategories: IEntity[];
    framingSystemCategories: IFramingSystemCategory[];
    collapsedCategoryMap: boolean[];
    windowTotalProduct: IWindowTotalProduct;
    height = 1000;
    width = 1000;
    glazingResult: IGlazingPerformance;
    totalWindowResult: ITotalWindowResult;
    currentPage = 1;
    query = "";
    total: number;
    windowTotalProducts: IWindowTotalProductsSearchItem[] = [];
    windowFrames: IWindowFrame[] = [];
    windowGlazingSystems: IWindowGlazingSystemWrapper[] = [];
    currentFilter: IWindowTotalProductFilter;
    selectedFrameId = 0;
    selectedGlazingSystemId = 0;
    glazingSystemCurrentPage = 1;
    frameCurrentPage = 1;
    itemsPerPage = 10000;
    currentUserId: number;
    loadingFrames = false;
    loadingGlazingSystems = false;
    selectedGlasses: IGlass[];
    selectedTotalProduct: IWindowTotalProduct;
    isAuthenticated: boolean;
    isGeneratingDataSheet = false;
    needsAuthenticationForDatasheet = false;
    isCustomSizingAvailable = false;

    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;
    createTotalProductModalApi: IModalWrapperApi;
    isSaving: boolean;
    displaySpecSheetInputForm = false;
    productListTypes = MyProductsListTypes;
    isSavingToProject: boolean;
    selectedProjectId: number;
    needsRedirect: boolean;
    hasInitialSearch = false;
    userHasProjects: boolean;
    selectedClimateZoneId: number;
    selectedProductCategoryId: number;
    isCog: boolean;
    productCategory: IProductCategory;
    searchControl = new UntypedFormControl();
    noFramesFound: boolean;
    selectedProductCategorySubCategoryIds: number[] = [];
    selectedSystemDepthIds: number[] = [];
    selectedSightlineIds: number[] = [];
    selectedFrame: IWindowFrame;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };
    sightlineItems: MultiselectItem[] = [];
    systemDepthItems: MultiselectItem[] = [];
    productCategoryItems: MultiselectItem[] = [];
    unfilteredSystemDepthItems: MultiselectItem[];
    unfilteredSightlineItems: MultiselectItem[];

    constructor(
        private authService: AuthService,
        private unitsService: UnitsService,
        private myProductsService: MyProductsService,
        private windowTotalProductService: WindowTotalProductService,
        private userWindowTotalProductService: UserWindowTotalProductService,
        private router: Router,
        private projectService: ProjectService,
        private formatService: FormatService,
        private productCategoryService: ProductCategoryService,
        private sightlineService: SightlineService,
        private systemDepthService: SystemDepthService
    ) {}

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.projectService
                .getAll()
                .subscribe(
                    (answer) => (this.userHasProjects = answer.length > 0)
                );
        });
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.setWindowTotalProduct();
        this.getSightlineItems();
        this.getSystemDepthItems();
        this.searchControl.valueChanges
            .pipe(debounceTime(300))
            .subscribe((value: string) => this.search(value));
    }

    getSightlineItems(): void {
        this.sightlineService.getAll().subscribe((sightlines) => {
            this.unfilteredSightlineItems = sightlines
                .filter((fw) => fw.WindowFrames.length > 0)
                .map((fw) => {
                    return {
                        Item: fw,
                        Selected: false,
                    };
                });
        });
    }

    getSystemDepthItems(): void {
        this.systemDepthService.getAll().subscribe((systemDepths) => {
            this.unfilteredSystemDepthItems = systemDepths
                .filter((sd) => sd.WindowFrames.length > 0)
                .map((sd) => {
                    return {
                        Item: sd,
                        Selected: false,
                    };
                });
        });
    }

    updateAuthenticationStatus(): void {
        this.loginPopupModalApi.close();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            this.currentUserId = this.authService.currentUser.getValue().Id;
            this.projectService.getAll().subscribe(() => {
                if (this.isSaving) {
                    this.needsRedirect = true;
                    this.saveTotalProductToProject();
                }
            });
            if (this.needsAuthenticationForDatasheet) {
                this.downloadDataSheet();
            }
        });
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }

    getDatasheetDownloadLabel(): string {
        return this.isGeneratingDataSheet
            ? "Generating Spec Sheet..."
            : "Download Spec Sheet";
    }

    setWindowTotalProduct(): void {
        this.windowTotalProduct =
            this.windowTotalProductService.getEmptyWindowTotalProduct();
    }

    clearSelectedFrame(): void {
        this.glazingResult = null;
        this.totalWindowResult = null;
        this.selectedFrameId = null;
        this.selectedGlazingSystemId = null;
        this.getTotalProducts(this.currentFilter);
    }

    clearSelectedGlazingSystem(): void {
        this.glazingResult = null;
        this.totalWindowResult = null;
        this.selectedGlazingSystemId = 0;
        this.getTotalProducts(this.currentFilter);
    }

    private buildSearch(
        updatedFilter: IWindowTotalProductFilter
    ): ExtraSearchParams[] {
        this.currentFilter = updatedFilter;
        this.isCog = updatedFilter.UseGenericCOGValue;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "UFactorLow",
                value: updatedFilter
                    ? this.unitsService
                          .uFactorImpToMetric(+updatedFilter.UFactorLowValue)
                          .toString()
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "UFactorHigh",
                value: updatedFilter
                    ? this.unitsService
                          .uFactorImpToMetric(+updatedFilter.UFactorHighValue)
                          .toString()
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "ShgcLow",
                value: updatedFilter ? updatedFilter.ShgcLowValue : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "ShgcHigh",
                value: updatedFilter ? updatedFilter.ShgcHighValue : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "VisibleTransmittanceLow",
                value: updatedFilter
                    ? updatedFilter.VisibleTransmittanceLowValue
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "VisibleTransmittanceHigh",
                value: updatedFilter
                    ? updatedFilter.VisibleTransmittanceHighValue
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "CondensationResistanceLow",
                value: updatedFilter
                    ? updatedFilter.CondensationResistanceLowValue
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "CondensationResistanceHigh",
                value: updatedFilter
                    ? updatedFilter.CondensationResistanceHighValue
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "UseGenericCOGValue",
                value: updatedFilter
                    ? updatedFilter.UseGenericCOGValue.toString()
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "UseActualGlazingProduct",
                value: updatedFilter
                    ? updatedFilter.UseActualGlazingProduct.toString()
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "ProductCategoryId",
                value: updatedFilter
                    ? updatedFilter.ProductCategoryId.toString()
                    : "",
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "ProductCategorySubCategoryIds",
                valueArray: this.selectedProductCategorySubCategoryIds,
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "SystemDepthIds",
                valueArray: this.selectedSystemDepthIds,
            })
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: "SightlineIds",
                valueArray: this.selectedSightlineIds,
            })
        );

        return _extraSearchParams;
    }

    getTotalProducts(updatedFilter: IWindowTotalProductFilter): void {
        this.selectedClimateZoneId = updatedFilter.ClimateZoneId;
        this.selectedProductCategoryId = updatedFilter.ProductCategoryId;
        if (!this.hasInitialSearch) {
            this.hasInitialSearch = true;
        }
        this.loadingGlazingSystems =
            this.selectedGlazingSystemId > 0 ? false : true;
        this.loadingFrames = this.selectedFrameId > 0 ? false : true;
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.buildSearch(updatedFilter);

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: "WindowTotalProduct.Id",
            orderDirection: "asc",
            query: search && search.length > 0 ? search : "",
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.windowTotalProductService
            .getTotalProducts(searchparams)
            .subscribe((answer) => {
                this.windowTotalProducts = answer.body;
                this.windowFrames = [];
                this.windowTotalProducts.forEach((tp) => {
                    if (this.filterUniqueFrames(tp.WindowFrame)) {
                        this.windowFrames.push(tp.WindowFrame);
                    }
                });
                this.windowGlazingSystems = this.windowTotalProducts.map(
                    (tp) => {
                        return tp.WindowGlazingSystem;
                    }
                );
                this.windowGlazingSystems.forEach((glz) => {
                    this.formatService.formatWindowSolidLayersWitGlassNames(
                        glz.WindowSolidLayers
                    );
                });
                this.loadingFrames = false;
                this.loadingGlazingSystems = false;
                const availableSightlineIds = this.windowFrames
                    .map((wf) => wf.SightlineId)
                    .filter((fw) => !!fw);
                this.sightlineItems = this.unfilteredSightlineItems.filter(
                    (fw) => {
                        return (
                            availableSightlineIds.indexOf(
                                fw.Item.Id
                            ) > -1
                        );
                    }
                );
                const availableSystemDepthIds = this.windowFrames
                    .map((wf) => wf.SystemDepthId)
                    .filter((sd) => !!sd);
                this.systemDepthItems = this.unfilteredSystemDepthItems.filter(
                    (sd) => {
                        return (
                            availableSystemDepthIds.indexOf(
                                sd.Item.Id
                            ) > -1
                        );
                    }
                );
                if (this.selectedFrameId) {
                    this.selectedFrame = this.windowFrames.find(
                        (frame) => frame.Id === this.selectedFrameId
                    );
                    if (!this.selectedFrame) {
                        this.selectedFrameId = null;
                        this.selectedGlazingSystemId = null;
                    } else {
                        this.checkForCustomSizing(this.selectedFrame);
                        this.windowGlazingSystems =
                            this.windowGlazingSystems.filter((gs) => {
                                return (
                                    gs.WindowGlazingSystem.WindowDataSetId ===
                                    this.selectedFrame.WindowDataSetId
                                );
                            });
                    }
                }
                if (this.selectedGlazingSystemId) {
                    const selectedGlazingSystem =
                        this.windowGlazingSystems.find(
                            (gs) =>
                                gs.WindowGlazingSystem.Id ===
                                this.selectedGlazingSystemId
                        ).WindowGlazingSystem;
                    this.windowFrames = this.windowFrames.filter((frame) => {
                        return (
                            frame.WindowDataSetId ===
                            selectedGlazingSystem.WindowDataSetId
                        );
                    });
                }
                if (this.selectedGlazingSystemId && this.selectedFrameId) {
                    this.calculate();
                }
                this.noFramesFound = this.windowFrames.length === 0;
                this.total = +answer.headers.get("X-List-Count");
            });
    }

    onProductCategoryUpdate(productCategory: IProductCategory): void {
        this.clearSelectedValues();
        this.getSightlineItems();
        this.getSystemDepthItems();
        this.productCategory = productCategory;
        this.productCategoryService
            .getProductCategorySubCategories(this.productCategory.Id)
            .subscribe((sc) => {
                this.productCategoryItems = sc
                    .filter(
                        (subCategory) =>
                            subCategory.WindowFrameProductCategories.length > 0
                    )
                    .map((sc) => {
                        return {
                            Item: sc,
                            Selected: false,
                        };
                    });
            });
    }

    private clearSelectedValues(): void {
        this.hasInitialSearch = false;
        this.selectedFrameId = null;
        this.selectedGlazingSystemId = null;
        this.selectedSightlineIds = [];
        this.selectedSystemDepthIds = [];
        this.selectedFrame = null;
        this.selectedTotalProduct = null;
        this.selectedProductCategorySubCategoryIds = [];
        this.selectedGlasses = [];
        this.selectedClimateZoneId = null;
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getTotalProducts(this.currentFilter);
    }

    clearSearch(): void {
        this.searchControl.setValue("");
    }

    onFilterUpdate(
        updatedFilter: IWindowTotalProductFilter,
        isNullZoneSelection: boolean
    ): void {
        this.getTotalProducts(updatedFilter);
        this.clearSelectedProducts(isNullZoneSelection);
    }

    onSelectedProductCategoriesUpdate(event: ISelectionChangedEvent): void {
        this.selectedProductCategorySubCategoryIds = event.selectedItems.map(
            (i) => i.Id
        );
        this.currentPage = 1;
        this.getTotalProducts(this.currentFilter);
    }

    onSelectedSystemDepthsUpdate(event: ISelectionChangedEvent): void {
        this.selectedSystemDepthIds = event.selectedItems.map((i) => i.Id);
        this.currentPage = 1;
        this.getTotalProducts(this.currentFilter);
    }

    onSelectedSightlinesUpdate(event: ISelectionChangedEvent): void {
        this.selectedSightlineIds = event.selectedItems.map((i) => i.Id);
        this.currentPage = 1;
        this.getTotalProducts(this.currentFilter);
    }

    checkForCustomSizing(selectedFrame: IWindowFrame): void {
        this.isCustomSizingAvailable = selectedFrame.HasCustomSizing;
    }

    clearSelectedProducts(isNullZoneSelection: boolean): void {
        this.selectedFrameId = null;
        this.selectedGlazingSystemId = null;
        if (isNullZoneSelection) {
            this.windowTotalProducts = [];
            this.windowFrames = [];
            this.windowGlazingSystems = [];
            this.hasInitialSearch = false;
        }
    }

    filterUniqueFrames(frame: IWindowFrame): boolean {
        return (
            this.windowFrames.findIndex((f) => {
                return f.Id === frame.Id;
            }) === -1
        );
    }

    isGenericCogSystem(system: IWindowGlazingSystem): boolean {
        return system.Name.includes("COG");
    }

    displayGenericSystem(system: IWindowGlazingSystem): string {
        return (
            "COG " +
            (
                Math.round(
                    this.unitsService.uFactorMetricToImp(system.UFactorWinter) *
                        100
                ) / 100
            ).toFixed(2)
        );
    }

    displaySolidLayer(
        solidLayers: (IGlazingSystemSolidLayer & IWindowSolidLayer)[],
        index?: number
    ): string {
        if (solidLayers[index] && solidLayers[index].Glass) {
            return `${this.setLayerLabel(solidLayers.length, index)} ${
                (solidLayers[index] as IGlazingSystemSolidLayer).Glass.Name
            }`;
        } else if (solidLayers[index]) {
            return `${this.setLayerLabel(solidLayers.length, index)} ${
                (solidLayers[index] as IWindowSolidLayer).GlassName
            }`;
        }
        return "";
    }

    displayGasLayer(
        index: number,
        gasLayers: IWindowGasLayerWrapper[]
    ): string {
        if (!gasLayers[index]) {
            return "";
        }
        return `AS: ${gasLayers[index].WindowGasLayer.GasName}`;
    }

    setLayerLabel(numberOfLayers: number, index?: number): string {
        let label = "";
        switch (index) {
            case 0:
                label = "OB:";
                break;
            case numberOfLayers - 1:
                label = "IB:";
                break;
            default:
                label = `CB${index}:`;
                break;
        }
        return label;
    }

    calculate(): void {
        const windowGlazingSystem = this.windowGlazingSystems.find(
            (gs) => gs.WindowGlazingSystem.Id === this.selectedGlazingSystemId
        );
        this.selectedGlasses = windowGlazingSystem.WindowSolidLayers.map(
            (sl) => {
                return sl.Glass;
            }
        );
        this.glazingResult = {
            LightToSolarGain:
                windowGlazingSystem.WindowGlazingSystem.LightToSolarGain,
            RelativeHeatGain:
                windowGlazingSystem.WindowGlazingSystem.RelativeHeatGain,
            ShadingCoefficient:
                windowGlazingSystem.WindowGlazingSystem.ShadingCoefficient,
            Shgc: windowGlazingSystem.WindowGlazingSystem.Shgc,
            SolarReflectanceOutside:
                windowGlazingSystem.WindowGlazingSystem.SolarReflectanceOutside,
            SolarTransmittance:
                windowGlazingSystem.WindowGlazingSystem.SolarTransmittance,
            Thickness: windowGlazingSystem.WindowGlazingSystem.Thickness,
            UFactorSummer:
                windowGlazingSystem.WindowGlazingSystem.UFactorSummer,
            UFactorWinter:
                windowGlazingSystem.WindowGlazingSystem.UFactorWinter,
            UVTransmittance:
                windowGlazingSystem.WindowGlazingSystem.UvTransmittance,
            VisibleReflectanceInside:
                windowGlazingSystem.WindowGlazingSystem
                    .VisibleReflectanceInside,
            VisibleReflectanceOutside:
                windowGlazingSystem.WindowGlazingSystem
                    .VisibleReflectanceOutside,
            VisibleTransmittance:
                windowGlazingSystem.WindowGlazingSystem.VisibleTransmittance,
        };
        this.selectedTotalProduct = this.windowTotalProducts.find((tp) => {
            return (
                tp.WindowTotalProduct.WindowFrameId === this.selectedFrameId &&
                tp.WindowTotalProduct.WindowGlazingSystemId ===
                    this.selectedGlazingSystemId
            );
        }).WindowTotalProduct;
        if (this.selectedTotalProduct != null) {
            this.totalWindowResult = {
                CondensationResistance:
                    this.selectedTotalProduct.CondensationResistance,
                Shgc: this.selectedTotalProduct.Shgc,
                UValue: this.selectedTotalProduct.UFactor,
                VisibleTransmittance:
                    this.selectedTotalProduct.VisibleTransmittance,
            };
        } else {
            this.totalWindowResult = null;
        }
    }

    downloadDataSheet(): void {
        if (this.isAuthenticated) {
            this.isGeneratingDataSheet = true;
        } else {
            this.needsAuthenticationForDatasheet = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

    saveTotalProductToProject(): void {
        if (this.isAuthenticated) {
            if (this.userHasProjects) {
                this.isSavingToProject = true;
            } else {
                this.updateSaveProjectId(null);
            }
        } else {
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    updateSaveProjectId(projectId: number): void {
        this.selectedProjectId = projectId;
        this.saveTotalProduct();
    }

    saveTotalProduct(): void {
        if (this.isAuthenticated) {
            const userWindowTotalProduct =
                this.userWindowTotalProductService.getEmptyWindowTotalProduct();
            userWindowTotalProduct.UserId = this.currentUserId;
            userWindowTotalProduct.WindowTotalProductId =
                this.selectedTotalProduct.Id;
            userWindowTotalProduct.ProjectId = this.selectedProjectId;
            userWindowTotalProduct.UserSaveDate = new Date();
            userWindowTotalProduct.ClimateZoneId = this.selectedClimateZoneId;
            userWindowTotalProduct.ProductCategoryId =
                this.selectedProductCategoryId;

            if (!userWindowTotalProduct.Id || userWindowTotalProduct.Id === 0) {
                this.userWindowTotalProductService
                    .create(userWindowTotalProduct)
                    .subscribe(() => {
                        this.myProductsService.productSaved.emit();
                        this.isSaving = false;
                        this.isSavingToProject = false;
                        if (this.needsRedirect) {
                            this.router
                                .navigate(["/my-products"])
                                .catch(() => null);
                        } else {
                            setTimeout(() => {
                                this.createTotalProductModalApi.show();
                            }, 0);
                        }
                    });
            }
        } else {
            this.isSaving = true;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    closeDialog(): void {
        this.createTotalProductModalApi.close();
    }

    clearForNext(): void {
        this.windowTotalProduct =
            this.windowTotalProductService.getEmptyWindowTotalProduct();
        this.totalWindowResult = null;
        this.glazingResult = null;
        this.selectedGlazingSystemId = 0;
        this.selectedFrameId = 0;
    }

    async navigateToContactUs(): Promise<void> {
        await this.router.navigate(["/contact-us"]);
    }

    saveToProjectModalClosed(): void {
        this.isSavingToProject = false;
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDataSheetParams, IEmailParams, ISpecSheetParams, MyProductsListTypes } from '@system-select/model';
import { GlazingSystemService, IGlazingPerformanceRequest, WindowTotalProductService } from '@system-select/web-services';

@Component({
    selector: 'spec-sheet-email-form',
    styles: [`
    .form-error {
        color: red;
    }
    .form-control {
        border-radius: 0;
        margin-top: 10px;
    }
    .btn-send {
        margin-top: 10px;
    }
    `],
    templateUrl: 'spec-sheet-email-form.component.html',
})

export class SpecSheetEmailFormComponent implements OnInit, OnChanges {
    @Input() displayForm: boolean;
    @Input() specSheetParams: ISpecSheetParams;
    @Input() selectedProductId: number;
    @Input() glazingPerformanceRequest: IGlazingPerformanceRequest;
    @Input() productListType: MyProductsListTypes;
    @Output() emitSuccessfulEmail: EventEmitter<boolean> = new EventEmitter<boolean>();
    emailFormModalApi: IModalWrapperApi;
    emailParams: IEmailParams;
    loadingResult = false;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(
        private windowTotalProductService: WindowTotalProductService,
        private glazingSystemService: GlazingSystemService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.emailParams = {
            Message: '',
            Subject: '',
            To: '',
        };
    }

    ngOnChanges(): void {
        if (this.displayForm) {
            setTimeout(() => {
                this.emailFormModalApi.show();
                // let cancelButton = this.emailFormSwal.nativeSwal.getCancelButton();
                // cancelButton.addEventListener('click', () => {
                //     this.emitSuccessfulEmail.emit(false);
                // });
            }, 0);
        }
    }

    save(): void {
        this.loadingResult = true;
        if (this.glazingPerformanceRequest) {
            this.sendDataSheetEmailFromPerformanceRequest();
        } else if (this.selectedProductId) {
            this.sendDataSheetEmailFromProductId();
        }
    }

    sendDataSheetEmailFromPerformanceRequest(): void {
        const dataSheetParams: IDataSheetParams = {
            EmailParams: this.emailParams,
            SpecSheetParams: this.specSheetParams,
        };
        const glassDataSheetEmailParams = {
            DataSheetParams: dataSheetParams,
            GlazingPerformanceRequest: this.glazingPerformanceRequest,
        };
        this.glazingSystemService.sendDataSheetEmail(glassDataSheetEmailParams)
            .subscribe(() => {
                this.success();
            });
    }

    sendDataSheetEmailFromProductId(): void {
        const dataSheetParams: IDataSheetParams = {
            EmailParams: this.emailParams,
            SpecSheetParams: this.specSheetParams,
        };
        if (this.productListType === MyProductsListTypes.GlassProducts) {
            this.glazingSystemService.sendDataSheetEmailFromId(this.selectedProductId, dataSheetParams)
                .subscribe(() => {
                    this.success();
                });
        } else if (this.productListType === MyProductsListTypes.TotalProducts) {
            this.windowTotalProductService.sendDataSheetEmail(this.selectedProductId, dataSheetParams)
                .subscribe(() => {
                    this.success();
                });
        }
    }

    success(): void {
        this.loadingResult = false;
        this.emailFormModalApi.close();
        this.notificationsService.success('Email sent successfully.');
        this.emitSuccessfulEmail.emit(true);
    }
}

import { IAddress } from './base';

import { IProject } from './project';

export interface IState {
    StateCode: string;
    Name: string;

    // reverse nav
    Addresses?: IAddress[];
    Projects_CompanyStateCode?: IProject[];
    Projects_StateCode?: IProject[];
}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { IGlazingSystemSolidLayer, ISpandrelType, IGlassColor, IGlassType, IGlass, ISilkScreenColor, ISilkScreenPattern, IVendor, GlassTypes, GlassColors, IInterlayerType, ISilkScreenLayerSelectedDTO } from '@system-select/model';
import { GlassService, SpandrelTypeService } from '@system-select/web-services';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { FormatService } from '@system-select/common';
import { IRealSurfaceLayer } from '../../common/interfaces/real-surface-layer';
import { IGlassBuilderLayerFilter, InterlayerTypes, ISpandrelSelectionDTO } from '@system-select/model';
import { TargetSystemTypes, SpandrelTypes } from '@system-select/model';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-solid-layer-edit',
    styles: [`
        .well {
            padding: 11px !important;
            margin-bottom: 10px !important;
        }
        .well .laminated {
            margin-top: 10px !important;
        }
        .radio-inline {
            padding-left: 0 !important;
        }
        .color-filter {
            height:20px;
            width:20px;
            display:inline-block;
            border-radius: 50%;
            padding: 11px;
            border: 1px solid #00000063;
        }
        input[type='radio'] {
            display: none;
        }
        input[type='radio']:checked ~ span {
            border: 3px solid black;
        }
    `],
    templateUrl: './solid-layer-edit.component.html',
})
export class SolidLayerEditComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() solidLayer: IGlazingSystemSolidLayer;
    @Input() layer: number;
    @Input() numberOfLayers: number;
    @Input() realSurfaceLayers: IRealSurfaceLayer[];
    @Input() glassColorItems: IGlassColor[];
    @Input() glassTypeItems: IGlassType[];
    @Input() silkScreenColorItems: ISilkScreenColor[];
    @Input() silkScreenPatternItems: ISilkScreenPattern[];
    @Input() interlayerTypeItems: IInterlayerType[];
    @Input() vendorItems: IVendor[];
    @Input() silkScreenSelectedDTO: ISilkScreenLayerSelectedDTO;
    @Output() updateSelectedGlassProduct: EventEmitter<IGlass> = new EventEmitter<IGlass>();
    @Output() updateRealSurfaceCount: EventEmitter<IRealSurfaceLayer> = new EventEmitter<IRealSurfaceLayer>();
    @Output() updateSilkScreenSelected: EventEmitter<ISilkScreenLayerSelectedDTO> = new EventEmitter<ISilkScreenLayerSelectedDTO>();
    @Output() emitUpdatedSpandrelSelection: EventEmitter<ISpandrelSelectionDTO> = new EventEmitter<ISpandrelSelectionDTO>();

    glassProducts: IGlass[];
    layerLabel: string;
    glassTypeId: number;
    glassColorId: number;
    silkScreenColorId: number;
    silkScreenPatternId: number;
    interlayerTypeId: number;
    interlayerThickness: number;
    interlayerThicknesses = [0.030, 0.060, 0.090];
    laminated = false;
    filteredGlassProducts: IGlass[];
    glassTypes = GlassTypes;
    // Hard coded count to remove Spandrel
    glassTypesCount = 4;
    glassColorKey = {
        'Blue': '#6c9ba8',
        'Blue Green': '#add5d4',
        'Bronze': '#c0b5a8',
        'Clear': '#e2e8df',
        'Gray': '#c2c7c0',
        'Green': '#bad2bd',
        'Low Iron': '#e8eae2',
        'Silver': '#aab9bb',
        'White': '#fff',
    };
    laminateAvailableColors = ['Clear', 'Low Iron', 'White'];
    availableColors = ['Clear', 'Low Iron'];
    glassColorItemsCopy: IGlassColor[];
    glassTypeItemsCopy: IGlassType[];
    interlayerTypeItemsCopy: IInterlayerType[];
    vendorFilterItems: MtSearchFilterItem[] = [];
    displayThicknessFilter = true;
    isSpandrelAvailable = false;
    spandrel = false;
    spandrelTypeItems: ISpandrelType[];
    spandrelTypeId: number;
    spandrelColor: string;
    isInitialized = false;
    id: number;

    constructor(private glassService: GlassService, private formatService: FormatService, private spandrelTypeService: SpandrelTypeService) { }

    ngOnInit(): void {
        this.isInitialized = true;
        this.spandrelTypeService.getItems()
        .subscribe((answer) => {
            this.spandrelTypeItems = answer;
        });
    }

    ngAfterViewInit(): void {
        if (this.solidLayer && !this.solidLayer.GlassId) {
            this.setFilterDefaults();
        } else {
            this.getGlassProducts();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.solidLayer) {
            if (this.solidLayer) {
                this.spandrelTypeId = this.solidLayer.SpandrelTypeId;
                this.spandrelColor = this.solidLayer.SpandrelColor;
                if (this.spandrelTypeId) {
                    this.spandrel = true;
                    this.isSpandrelAvailable = true;
                    this.glassTypeId = GlassTypes.Spandrel;
                    this.updateSpandrelSelection();
                }
            }
        }
        if (changes.numberOfLayers) {
            this.updateLayerLabel();
            if (this.layer) {
                this.checkForSpandrelAvailability();
            }
        }
        if (changes.numberOfLayers && this.isInitialized) {
            this.getGlassProducts();
        }
        if (changes.realSurfaceLayers && this.isInitialized) {
            this.getGlassProducts();
        }
        if (changes.glassColorItems) {
            this.glassColorItemsCopy = changes.glassColorItems.currentValue;
            this.filterAvailableGlassColors(this.laminated);
        }
        if (changes.glassTypeItems) {
            this.glassTypeItemsCopy = changes.glassTypeItems.currentValue;
            this.filterAvailableGlassTypes();
        }
        if (changes.interlayerTypeItems) {
            this.interlayerTypeItemsCopy = changes.interlayerTypeItems.currentValue;
        }
        if (changes.silkScreenSelectedDTO) {
            this.silkScreenSelectedDTO = changes.silkScreenSelectedDTO.currentValue;
            if (this.silkScreenSelectedDTO.Layer !== this.layer && this.silkScreenSelectedDTO.SilkScreen) {
                this.filterAvailableGlassTypes();
                this.glassTypeItems = this.glassTypeItems.filter((gt) => {
                    return gt.Id !== GlassTypes.SilkScreened;
                });
            } else if (this.silkScreenSelectedDTO.Layer > 0 && !this.silkScreenSelectedDTO.SilkScreen) {
                this.filterAvailableGlassTypes();
            }
        }
        if (changes.vendorItems && changes.vendorItems.currentValue) {
            this.vendorFilterItems = this.vendorItems.map((vi) => new MtSearchFilterItem(vi, false));
        }
    }

    private setFilterDefaults(): void {
        this.glassTypeId = GlassTypes.LowE;
        this.glassColorId = GlassColors.Clear;
        this.getGlassProducts();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'VendorIds',
            valueArray: this.vendorFilterItems.filter((f) => f.Selected).map((f) => f.Item.Id),
        }));

        return _extraSearchParams;
    }

    getGlassProducts(): void {
        if (this.layer && this.numberOfLayers) {
            const glassTypeId = +this.glassTypeId ? this.glassTypeId : null;
            const filters: IGlassBuilderLayerFilter = {
                GlassColorId: this.glassColorId,
                GlassTypeId: this.spandrel ? this.glassTypes.Uncoated : glassTypeId,
                InterlayerThickness: this.interlayerThickness,
                InterlayerTypeId: this.interlayerTypeId,
                Laminated: this.laminated,
                Layer: this.layer,
                NumberOfLayers: this.numberOfLayers,
                SilkScreenedColorId: this.silkScreenColorId,
                SilkScreenedPatternId: this.silkScreenPatternId,
                VendorIds: this.buildSearch(),
            };
            this.glassService.getFilteredProductsForGlassBuilder(filters)
                .subscribe((glassProducts) => {
                    this.glassProducts = glassProducts.body;
                    this.filteredGlassProducts = [...this.glassProducts];
                    if (this.realSurfaceLayers.length > 0) {
                        this.updateSurfaceLabels();
                    }
                });
        }
    }

    private checkForSpandrelAvailability(): void {
        if ((this.layer === TargetSystemTypes.DoublePane && this.numberOfLayers === TargetSystemTypes.DoublePane) && !this.laminated) {
            this.isSpandrelAvailable = true;
        } else if ((this.layer === TargetSystemTypes.Monolithic && this.numberOfLayers === TargetSystemTypes.Monolithic) && !this.laminated) {
            this.isSpandrelAvailable = true;
        } else {
            this.isSpandrelAvailable = false;
        }
        // clear out any existing Spandrel selections
        if (!this.isSpandrelAvailable) {
            this.spandrel = false;
            this.spandrelTypeId = null;
            this.spandrelColor = '';
            this.updateSpandrelSelection();
        }
        this.filterAvailableGlassTypes();
    }

    updateSpandrelValue(): void {
        // Default values if Spandrel is selected
        if (this.spandrel) {
            this.glassColorId = GlassColors.Clear;
            this.spandrelTypeId = SpandrelTypes.CeramicFrit;
            this.updateSpandrelSelection();
        }
        // Otherwise, clear out any existing Spandrel selections
        if (!this.spandrel) {
            this.spandrelTypeId = null;
            this.spandrelColor = '';
            this.updateSpandrelSelection();
        }
    }

    updateSpandrelSelection(): void {
        this.emitUpdatedSpandrelSelection.emit({
            Layer: this.layer,
            SpandrelColor: this.spandrelColor,
            SpandrelTypeId: this.spandrelTypeId,
        });
    }

    updateSurfaceLabels(): void {
        this.filteredGlassProducts = [...this.glassProducts];
        if (this.filteredGlassProducts) {
            this.filteredGlassProducts.forEach((gp) => {
                gp.Name = this.formatService.formatRealSurfaceLayerLabelForGlassBuilder(gp.Name, this.realSurfaceLayers, this.layer);
            });
        }
    }

    updateLayerLabel(): void {
        switch (this.layer) {
            case (1):
                this.layerLabel = 'Outboard';
                break;
            case (this.numberOfLayers):
                this.layerLabel = 'Inboard';
                break;
            default:
                this.layerLabel = `Center Glass`;
                break;
        }
    }

    checkLaminatedValue(): void {
        this.laminated = !this.laminated;
        this.checkForSpandrelAvailability();
        if (this.laminated) {
            this.getGlassProducts();
            this.filterAvailableGlassColors(true);
        } else {
            if (this.interlayerTypeId) {
                this.interlayerTypeId = null;
            }
            if (this.interlayerThickness) {
                this.interlayerThickness = 0;
            }
            if (this.glassColorId) {
                this.glassColorId = null;
            }
            this.getGlassProducts();
            this.filterAvailableGlassColors(false);
        }
    }

    onGlassTypeChange(): void {
        this.vendorFilterItems.forEach((i) => (i.Selected = false));
        this.checkForSilkScreened(false);
        this.checkForSpandrel();
        this.checkGlassTypeFilter();
        this.getGlassProducts();
    }

    checkForSilkScreened(refreshList: boolean): void {
        if (+this.glassTypeId === GlassTypes.SilkScreened) {
            this.updateSilkScreenSelected.emit({
                Layer: this.layer,
                SilkScreen: true,
            });
        } else if (this.silkScreenSelectedDTO.Layer === this.layer) {
            this.updateSilkScreenSelected.emit({
                Layer: this.layer,
                SilkScreen: false,
            });
        }
        if (refreshList) {
            this.getGlassProducts();
        }
    }

    checkForSpandrel(): void {
        this.spandrel = +this.glassTypeId === GlassTypes.Spandrel;
        this.updateSpandrelValue();
    }

    isSilkScreenDisabled(): boolean {
        return this.silkScreenSelectedDTO.SilkScreen && this.silkScreenSelectedDTO.Layer !== this.layer;
    }

    getSwatchColor(colorKey: string): string {
        return this.glassColorKey[colorKey];
    }

    getGlassColorLabel(id: number): string {
        return `glassColor${id}${this.layer}`;
    }

    filterAvailableGlassColors(laminated: boolean): void {
        if (this.glassColorItems) {
            this.glassColorItems = this.glassColorItemsCopy.slice();
            if (this.spandrel) {
                this.glassColorItems = this.glassColorItems.filter((gc) => {
                    return gc.Id === GlassColors.LowIron || gc.Id === GlassColors.Clear;
                });
            }
            if (this.layer > 1 && laminated) {
                this.glassColorItems = this.glassColorItems.filter((gc) => {
                    return this.laminateAvailableColors.indexOf(gc.Name) > -1;
                });
                this.glassColorId = this.glassColorItems[1].Id;
                this.getGlassProducts();
            } else if (this.layer > 1) {
                this.glassColorItems = this.glassColorItems.filter((gc) => {
                    return this.availableColors.indexOf(gc.Name) > -1;
                });
                // this.glassColorId = this.glassColorItems[1].Id;
                this.getGlassProducts();
            }
        }
    }

    filterAvailableInterlayerThickness(): void {
        if (
            +this.interlayerTypeId === InterlayerTypes.SaflexHP ||
            +this.interlayerTypeId === InterlayerTypes.StormGlas ||
            +this.interlayerTypeId === InterlayerTypes.ArmorGarde
        ) {
            if (this.interlayerThickness) {
                this.interlayerThickness = null;
            }
            this.displayThicknessFilter = false;
        } else {
            this.displayThicknessFilter = true;
        }
    }

    filterAvailableInterlayerTypes(): void {
        if (this.interlayerThickness) {
            this.interlayerTypeItems = this.interlayerTypeItemsCopy.slice();
            this.interlayerTypeItems = this.interlayerTypeItems.filter((itp) => {
                return itp.Id === InterlayerTypes.PVB || itp.Id === InterlayerTypes.SentryGlas;
            });
        }
    }

    filterAvailableGlassTypes(): void {
        if (this.glassTypeItems && this.glassTypeItemsCopy) {
            this.glassTypeItems = this.glassTypeItemsCopy.slice();
            if (this.isSpandrelAvailable) {
                this.glassTypeItems.push({ Id: GlassTypes.Spandrel, Name: 'Spandrel' });
            } else {
                if (this.glassTypeItems.length > this.glassTypesCount) {
                    this.glassTypeItems.pop();
                }
            }
            if (this.layer > 1) {
                this.glassTypeItems = this.glassTypeItems.filter((gt) => gt.Id !== GlassTypes.Reflective);
            }
        }
    }

    clearLaminateFilter(): void {
        if (this.interlayerTypeId) {
            this.interlayerTypeId = null;
        }
        if (this.interlayerThickness) {
            this.interlayerThickness = 0;
        }
        this.interlayerTypeItems = this.interlayerTypeItemsCopy.slice();
        this.displayThicknessFilter = true;
        this.getGlassProducts();
    }

    clearGlassColorFilter(): void {
        if (this.glassColorId) {
            this.glassColorId = null;
            this.getGlassProducts();
        }
    }

    checkGlassTypeFilter(): void {
        if (!+this.glassTypeId) {
            this.clearGlassTypeFilter();
        } else if (+this.glassTypeId !== GlassTypes.SilkScreened) {
            if (this.silkScreenColorId) {
                this.silkScreenColorId = null;
            }
            if (this.silkScreenPatternId) {
                this.silkScreenPatternId = null;
            }
        }
    }

    clearGlassTypeFilter(): void {
        if (this.glassTypeId) {
            if (this.silkScreenColorId) {
                this.silkScreenColorId = null;
            }
            if (this.silkScreenPatternId) {
                this.silkScreenPatternId = null;
            }
            this.checkForSilkScreened(false);
            this.checkForSpandrel();
            this.getGlassProducts();
        }
    }

    emitUpdatedGlassProduct(selectedGlassProduct: IGlass): void {
        if (selectedGlassProduct) {
            if (selectedGlassProduct.GlassTypes.some((gt) => gt.Id === GlassTypes.SilkScreened)) {
                this.glassTypeId = GlassTypes.SilkScreened;
                this.checkForSilkScreened(true);
            } else {
                this.checkForSilkScreened(true);
            }
            const realSurfaceCount = selectedGlassProduct.Laminated ? 4 : 2;
            this.updateRealSurfaceCount.emit({
                Layer: this.layer,
                RealSurfaceCount: realSurfaceCount,
            });
            this.updateSelectedGlassProduct.emit(selectedGlassProduct);
        }
    }

    isGlassColorDisabled(glassColor: IGlassColor): string {
        // Hide the 'White' color swatch unless the layer is laminated
        if (!this.laminated && glassColor.Name === 'White') {
            return 'none';
        }
        return 'inline-block';
    }
}

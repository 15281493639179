import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { GlazingSystemService } from '@system-select/web-services';
import { IGlazingSystem, MyProductsListTypes } from '@system-select/model';
import { GlazingSystemsListConfig } from './glazing-systems.entity-list-config';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams, SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { UnitsService, FormatService } from '@system-select/common';
import { MyProductsService } from '../my-products.service';

@Component({
    selector: 'glazing-systems-list',
    templateUrl: 'glazing-systems-list.component.html',
})
export class GlazingSystemsListComponent implements OnInit, OnChanges {
    @Input() selectedProjectId: number;
    entityListConfig: GlazingSystemsListConfig;
    glazingSystems: IGlazingSystem[] = [];
    order: string;
    orderDirection: string;
    total: number;
    currentPage = 1;
    itemsPerPage = 25;
    isGeneratingDataSheet = false;
    selectedProductId: number;
    productListTypes = MyProductsListTypes;

    constructor(
        private glazingSystemService: GlazingSystemService,
        private myProductsService: MyProductsService,
        private unitsService: UnitsService,
        private formatService: FormatService,
    ) {
        this.entityListConfig = new GlazingSystemsListConfig(unitsService, formatService);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.entityListConfig.onItemDeleted.subscribe((glazingSystem) => {
            const i = this.glazingSystems.indexOf(glazingSystem);
            this.glazingSystems.splice(i, 1);
            this.glazingSystems = this.glazingSystems.slice();
            this.myProductsService.productRemoved.emit();
        });

        this.entityListConfig.onDataSheetDownload.subscribe((selectedProductId) => {
            this.isGeneratingDataSheet = true;
            this.selectedProductId = selectedProductId;
        });

        this.getGlazingSystems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedProjectId && !changes.selectedProjectId.firstChange) {
            this.getGlazingSystems();
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProjectId',
            value: this.selectedProjectId ? this.selectedProjectId.toString() : '',
        }));
        return _extraSearchParams;
    }

    getGlazingSystems(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take:  this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.glazingSystemService.get(searchparams).subscribe(
            (answer) => {
                this.glazingSystems = answer.body;
                this.glazingSystems.forEach((glz) => {
                    glz.GlazingSystemSolidLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                    glz.GlazingSystemGasLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                    let realSurfaceCount = 0;
                    for (let index = 0; index < glz.GlazingSystemSolidLayers.length; index++) {
                        let previousLayer;
                        const currentLayer = glz.GlazingSystemSolidLayers[index];
                        if (index > 0 && currentLayer.Layer > 1) {
                            previousLayer = glz.GlazingSystemSolidLayers[index - 1];
                            const surfaceCount = previousLayer.Glass.Laminated ? 4 : 2;
                            realSurfaceCount += surfaceCount;
                            currentLayer.Glass.Name = this.formatService.formatRealSurfaceLayerLabel(currentLayer.Glass.Name, realSurfaceCount, currentLayer.Layer);
                        }
                    }
                });

                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getGlazingSystems();
    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

}

import { IEntity } from './base';

import { IGlazingSystemGasLayer } from './glazing-system-gas-layer';

export interface IGasType extends IEntity {
    Name: string;

    // reverse nav
    GlazingSystemGasLayers?: IGlazingSystemGasLayer[];
}

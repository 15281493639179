import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFramingSystemTypeLayout } from '../interfaces/framing-system-type-layout';
import { IFrameType } from '../interfaces/frame-type';
import { IFramingSystemType } from '../interfaces/framing-system-type';

export interface IFramingSystemTypeLayoutDynamicControlsParameters {
    formGroup?: string;
    framingSystemTypes?: IFramingSystemType[];
    frameTypes?: IFrameType[];
}

export class FramingSystemTypeLayoutDynamicControls {

    formGroup: string;
    framingSystemTypes: IFramingSystemType[];
    frameTypes: IFrameType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private framingsystemtypelayout?: IFramingSystemTypeLayout, additionalParameters?: IFramingSystemTypeLayoutDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FramingSystemTypeLayout';
        this.framingSystemTypes = additionalParameters && additionalParameters.framingSystemTypes || undefined;
        this.frameTypes = additionalParameters && additionalParameters.frameTypes || undefined;

        this.Form = {
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.framingsystemtypelayout && this.framingsystemtypelayout.hasOwnProperty('Description') && this.framingsystemtypelayout.Description !== null ? this.framingsystemtypelayout.Description.toString() : '',
            }),
            FrameNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame Number',
                name: 'FrameNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framingsystemtypelayout && this.framingsystemtypelayout.FrameNumber || null,
            }),
            FrameTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame Type',
                name: 'FrameTypeId',
                options: this.frameTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystemtypelayout && this.framingsystemtypelayout.FrameTypeId || null,
            }),
            FramingSystemTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Framing System Type',
                name: 'FramingSystemTypeId',
                options: this.framingSystemTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framingsystemtypelayout && this.framingsystemtypelayout.FramingSystemTypeId || null,
            }),
        };

        this.View = {
            Description: new DynamicLabel({
			    label: 'Description',
			    value: this.framingsystemtypelayout && this.framingsystemtypelayout.hasOwnProperty('Description') && this.framingsystemtypelayout.Description !== null ? this.framingsystemtypelayout.Description.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FrameNumber: new DynamicLabel({
			    label: 'Frame Number',
			    value: this.framingsystemtypelayout && this.framingsystemtypelayout.FrameNumber || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FrameTypeId: new DynamicLabel({
			    label: 'Frame Type',
			    value: getMetaItemValue(this.frameTypes as unknown as IMetaItem[], this.framingsystemtypelayout && this.framingsystemtypelayout.hasOwnProperty('FrameTypeId') && this.framingsystemtypelayout.FrameTypeId !== null ? this.framingsystemtypelayout.FrameTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FramingSystemTypeId: new DynamicLabel({
			    label: 'Framing System Type',
			    value: getMetaItemValue(this.framingSystemTypes as unknown as IMetaItem[], this.framingsystemtypelayout && this.framingsystemtypelayout.hasOwnProperty('FramingSystemTypeId') && this.framingsystemtypelayout.FramingSystemTypeId !== null ? this.framingsystemtypelayout.FramingSystemTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

export interface IPerformanceTargetFilters {
    UFactorLowValue: string,
    UFactorHighValue: string,
    ShgcLowValue: string,
    ShgcHighValue: string,
    TransmittanceLowValue: string,
    TransmittanceHighValue: string,
    ReflectanceOutsideLowValue: string,
    ReflectanceOutsideHighValue: string,
    ReflectanceInsideLowValue: string,
    ReflectanceInsideHighValue: string,
}

import { IEntity, IVersionable, IDocument, IAddress } from './base';

import { IGlazingSystem } from './glazing-system';
import { ILeedReport } from './leed-report';
import { IProject } from './project';
import { ITotalProduct } from './total-product';
import { IUserPhone } from './user-phone';
import { IUserWindowTotalProduct } from './user-window-total-product';
import { IAuthUser } from './auth-user';
import { IImage } from './image';

export interface IUser extends IEntity, IVersionable {
    FirstName: string;
    LastName: string;
    Email: string;
    AuthUserId: number;
    ImageId?: number;
    AddressId?: number;
    IsObeUser: boolean;

    // reverse nav
    Documents?: IDocument[];
    Documents_UploadedBy?: IDocument[];
    GlazingSystems?: IGlazingSystem[];
    LeedReports?: ILeedReport[];
    Projects?: IProject[];
    TotalProducts?: ITotalProduct[];
    UserPhones?: IUserPhone[];
    UserWindowTotalProducts?: IUserWindowTotalProduct[];

    // foreign keys
    Address?: IAddress;
    AuthUser?: IAuthUser;
    Image?: IImage;
}

<leed-report-summary
    [windowFrame]="windowFrame"
    [glazingSystem]="glazingSystem"
    [isAuthenticated]="isAuthenticated"
></leed-report-summary>
<div class="row report-actions">
    <div class="col-md-6 pull-right text-right">
        <button [disabled]="isSaved" class="btn btn-primary" (click)="onSave()">
            Save to My Projects
        </button>
        <button
            class="btn btn-default"
            [disabled]="!isSaved"
            (click)="downloadLeedReportDocuments()"
        >
            Download LEED Report Docs
        </button>
        <button
            class="btn btn-default"
            [disabled]="!isSaved"
            (click)="onBuildAnotherReportSelected()"
        >
            Generate Another LEED Report
        </button>
    </div>
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus($event)"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus($event)"
        ></create-account>
    </div>
</mt-modal-wrapper>
<ngx-loading [show]="loadingResult"></ngx-loading>

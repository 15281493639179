import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { LoginService } from './common/services/login.service';
import { Router } from '@angular/router';
import { MyProductsService } from './my-products/my-products.service';
import { GlazingSystemService, UserWindowTotalProductService } from '@system-select/web-services';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-root',
    styles: [`
        .home-link {
            color: #fff !important;
        }
    `],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    isAuthenticated: boolean;
    productCount = 0;
    showMobileMenu = false;

    constructor(
        private authService: AuthService,
        private loginService: LoginService,
        private myProductsService: MyProductsService,
        private glazingSystemService: GlazingSystemService,
        private userWindowTotalProductService: UserWindowTotalProductService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            if (isAuthed) {
                this.refreshSavedProductCount();
            }
        });

        this.loginService.loggedIn.subscribe((isLoggedIn) => {
            this.isAuthenticated = isLoggedIn;
            this.refreshSavedProductCount();
        });

        this.myProductsService.productSaved.subscribe(() => {
            this.productCount++;
        });

        this.myProductsService.productRemoved.subscribe(() => {
            this.productCount--;
        });
    }

    refreshSavedProductCount(): void {
        forkJoin(
            this.glazingSystemService.getCount(),
            this.userWindowTotalProductService.getCount(),
        ).subscribe((counts) => {
            this.productCount = counts[0] + counts[1];
        });
    }

    logout(): void {
        this.authService.logout();
        this.loginService.loggedIn.emit(false);
        this.productCount = 0;
        void this.router.navigate(['/login']);
    }
}

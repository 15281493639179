import { Component, Input, SimpleChanges } from '@angular/core';
import { GlazingSystemService } from '@system-select/web-services';
import { IGlazingSystem, IProductRecommendationParams } from '@system-select/model';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { UnitsService, FormatService } from '@system-select/common';
import { GlazingSystemsPartialListConfig } from './glazing-systems-partial.entity-list-config';
import { TargetSystemTypes } from '@system-select/model';
import { Router } from '@angular/router';

@Component({
    selector: 'glazing-systems-partial-detail-list',
    templateUrl: 'glazing-systems-partial-detail-list.component.html',
})
export class GlazingSystemsPartialDetailListComponent {
    @Input() _outboardParams: ExtraSearchParams[];
    @Input() _inboardParams: ExtraSearchParams[];
    @Input() _centerGlassParams: ExtraSearchParams[];
    @Input() _overallParams: ExtraSearchParams[];
    @Input() _targetSystemType: number;
    @Input() _performanceTargetParams: ExtraSearchParams[];
    recommendedProducts: IGlazingSystem[];
    entityListConfig: GlazingSystemsPartialListConfig;
    order: string;
    orderDirection: string;
    total: number;
    currentPage = 1;
    itemsPerPage = 10;
    layerParams: ExtraSearchParams[][];
    isLoadingResults: boolean;

    constructor(
        private glazingSystemService: GlazingSystemService,
        private unitsService: UnitsService,
        private formatService: FormatService,
        private router: Router,
    ) {
        this.entityListConfig = new GlazingSystemsPartialListConfig(unitsService, formatService);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let firstChange = true;
        if (changes._targetSystemType) {
            firstChange = changes._targetSystemType.firstChange;
            this.resetSecondaryFilters();
        }
        if (changes._performanceTargetParams) {
            firstChange = changes._performanceTargetParams.firstChange;
        }
        if (changes._outboardParams) {
            firstChange = changes._outboardParams.firstChange;
        }
        if (changes._inboardParams) {
            firstChange = changes._inboardParams.firstChange;
        }
        if (changes._centerGlassParams) {
            firstChange = changes._centerGlassParams.firstChange;
        }
        if (changes._overallParams) {
            firstChange = changes._overallParams.firstChange;
        }

        if (!firstChange) {
            this.getGlazingSystems();
        }
    }

    resetSecondaryFilters(): void {
        if (this._targetSystemType === TargetSystemTypes.Monolithic) {
            this._inboardParams = [];
            this._centerGlassParams = [];
        } else if (this._targetSystemType === TargetSystemTypes.DoublePane) {
            this._centerGlassParams = [];
        }
    }

    getGlazingSystems(): void {
        this.layerParams = [];
        this.layerParams.push(this._outboardParams);
        this.layerParams.push(this._inboardParams);
        this.layerParams.push(this._centerGlassParams);

        // If it's monolithic we don't need overall params
        if (this._targetSystemType === TargetSystemTypes.Monolithic) {
            this._overallParams = null;
        }

        const searchEntity: IProductRecommendationParams = {
            extraParams: this._performanceTargetParams,
            layerParams: this.layerParams,
            order: this.order,
            orderDirection: this.orderDirection,
            overallParams: this._overallParams,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take:  this.itemsPerPage,
            targetsystemType: this._targetSystemType,
        };

        this.glazingSystemService.getProductRecommendations(searchEntity).subscribe(
            (answer) => {
                this.recommendedProducts = answer.body;
                this.recommendedProducts.forEach((glz) => {
                    glz.GlazingSystemSolidLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                    glz.GlazingSystemGasLayers.sort((a, b) => a.Layer < b.Layer ? -1 : 1);
                });
                this.recommendedProducts.forEach((rp) => {
                    let realSurfaceCount = 0;
                    for (let index = 0; index < rp.GlazingSystemSolidLayers.length; index++) {
                        let previousLayer;
                        const currentLayer = rp.GlazingSystemSolidLayers[index];
                        if (index > 0 && currentLayer.Layer > 1) {
                            previousLayer = rp.GlazingSystemSolidLayers[index - 1];
                            const surfaceCount = previousLayer.Glass.Laminated ? 4 : 2;
                            realSurfaceCount += surfaceCount;
                            currentLayer.Glass.Name = this.formatService.formatRealSurfaceLayerLabel(currentLayer.Glass.Name, realSurfaceCount, currentLayer.Layer);
                        }
                    }
                });
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getGlazingSystems();
    }

   async navigateToContactUs(): Promise<void> {
        await this.router.navigate(['/contact-us']);
    }

}

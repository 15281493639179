import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { GasTypeService, GlassColorService, GlassTypeService, GlassService, GlazingSystemService, VendorService, SilkScreenColorService, SilkScreenPatternService, InterlayerTypeService } from '@system-select/web-services';
import { forkJoin } from 'rxjs';
import { IGasType, IGlassColor, IGlassType, ISilkScreenColor, ISilkScreenPattern, IInterlayerType, IVendor } from '@system-select/model';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { TargetSystemTypes } from '@system-select/model';

@Component({
    selector: 'secondary-filter',
    templateUrl: 'secondary-filter.component.html',
})

export class SecondaryFilterComponent implements OnInit {
    @Output() emitUpdatedOutboardParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();
    @Output() emitUpdatedInboardParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();
    @Output() emitUpdatedOverallParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();
    @Output() emitUpdatedCenterGlassParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();
    @Output() emitTargetSystemType: EventEmitter<number> = new EventEmitter<number>();

    gasTypeItems: IGasType[];
    glassColorItems: IGlassColor[];
    glassTypeItems: IGlassType[];
    silkScreenColorItems: ISilkScreenColor[];
    silkScreenPatternItems: ISilkScreenPattern[];
    interlayerTypeItems: IInterlayerType[];
    vendorItems: IVendor[];
    layers = [1, 2, 3];

    constructor(
        private gasTypeService: GasTypeService,
        private glassColorService: GlassColorService,
        private glassTypeService: GlassTypeService,
        private glassService: GlassService,
        private glazingSystemService: GlazingSystemService,
        private vendorService: VendorService,
        private silkScreenColorService: SilkScreenColorService,
        private silkScreenPatternService: SilkScreenPatternService,
        private interlayerTypeService: InterlayerTypeService,
    ) { }

    ngOnInit(): void {
        forkJoin(
            this.gasTypeService.getItems(),
            this.glassColorService.getItems(),
            this.glassTypeService.getItems(),
            this.vendorService.getItems(),
            this.silkScreenColorService.getItems(),
            this.silkScreenPatternService.getItems(),
            this.interlayerTypeService.getItems(),
        ).subscribe((data) => {
            [
                this.gasTypeItems,
                this.glassColorItems,
                this.glassTypeItems,
                this.vendorItems,
                this.silkScreenColorItems,
                this.silkScreenPatternItems,
                this.interlayerTypeItems] = [data[0], data[1], data[2], data[3], data[4], data[5], data[6]];
        });
    }

    toggleMonolithicFilter(): void {
        this.layers = [1];
        this.emitTargetSystemType.emit(TargetSystemTypes.Monolithic);
    }

    toggleDoublePaneFilter(): void {
        this.layers = [1, 2];
        this.emitTargetSystemType.emit(TargetSystemTypes.DoublePane);
    }

    toggleTriplePaneFilter(): void {
        this.layers = [1, 2, 3];
        this.emitTargetSystemType.emit(TargetSystemTypes.TriplePane);
    }

    getLayerHeading(layer: number): string {
        switch (layer) {
            case (1):
                return 'Outboard';
            case (this.layers.length):
                return 'Inboard';
            default:
                return `Center Glass`;
        }
    }

    updateLayerParams(updatedParams: ExtraSearchParams[], layer: number): void {
        switch (layer) {
            case (1):
                this.emitUpdatedOutboardParams.emit(updatedParams);
                break;
            case (this.layers.length):
                this.emitUpdatedInboardParams.emit(updatedParams);
                break;
            default:
                this.emitUpdatedCenterGlassParams.emit(updatedParams);
                break;
        }
    }

    updateOverallParams(updatedParams: ExtraSearchParams[]): void {
        this.emitUpdatedOverallParams.emit(updatedParams);
    }
}

<div>
    <h2>Contact Us:</h2>
    <p>Please use the following form to submit any comments or to request additional information, custom products or
        samples.</p>
    <p>We do not sell or share any information about individual users. For more information about our collection and use
        of your information, please read our <a (click)="navigateToPrivacyPolicy()">Privacy Policy.</a></p>
</div>
<div class="col-md-7">
    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <div class="form-group" [class.has-error]="showFirstNameRequiredError()">
            <label [ngClass]="{'errortext': showFirstNameRequiredError()}">First Name<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="First Name" formControlName="FirstName">
            <div *ngIf="showFirstNameRequiredError()" class="small errortext" [style.position]="'block'">First Name is required</div>
        </div>
        <div class="form-group" [class.has-error]="showLastNameRequiredError()">
            <label [ngClass]="{'errortext': showLastNameRequiredError()}">Last Name<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="Last Name" formControlName="LastName">
            <div *ngIf="showLastNameRequiredError()" class="small errortext" [style.position]="'block'">Last Name is required</div>
        </div>
        <div class="form-group" [class.has-error]="showCompanyRequiredError()">
            <label [ngClass]="{'errortext': showCompanyRequiredError()}">Company<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="Company" formControlName="Company">
            <div *ngIf="showCompanyRequiredError()" class="small errortext" [style.position]="'block'">Company is required</div>
        </div>
        <div class="form-group" [class.has-error]="showCityRequiredError()">
            <label [ngClass]="{'errortext': showCityRequiredError()}">City<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="City Name" formControlName="City">
            <div *ngIf="showCityRequiredError()" class="small errortext" [style.position]="'block'">City is required</div>
        </div>
        <div class="form-group" [class.has-error]="showStateRequiredError()">
            <label [ngClass]="{'errortext': showStateRequiredError()}">State / Prov<span> *</span></label>
            <select autofocus class="form-control" placeholder="State" formControlName="State">
                <option *ngFor="let state of states" [value]="state.StateCode">{{state.StateCode}}</option>
            </select>
            <div *ngIf="showStateRequiredError()" class="small errortext" [style.position]="'block'">State is required</div>
        </div>
        <div class="form-group" [class.has-error]="showPhoneRequiredError()">
            <label [ngClass]="{'errortext': showPhoneRequiredError()}">Phone<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="Phone" formControlName="Phone">
            <div *ngIf="showPhoneRequiredError()" class="small errortext" [style.position]="'block'">Phone Number is required</div>
        </div>
        <div class="form-group">
            <label>Extension:</label>
            <input type="text" autofocus class="form-control" placeholder="Extension" formControlName="Extension">
        </div>
        <div class="form-group" [class.has-error]="showEmailRequiredError()">
            <label [ngClass]="{'errortext': showEmailRequiredError()}">Email<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="Email" formControlName="Email">
            <div *ngIf="showEmailRequiredError()" class="small errortext" [style.position]="'block'">Email is required</div>
            <div *ngIf="showInvalidEmailError()" class="small errortext" [style.position]="'block'">Not a valid Email</div>

        </div>
        <div class="form-group" [class.has-error]="showConfirmEmailRequiredError()">
            <label [ngClass]="{'errortext': showConfirmEmailRequiredError()}">Confirm Email<span> *</span></label>
            <input type="text" autofocus class="form-control" placeholder="Confirm Email" formControlName="ConfirmEmail">
            <div *ngIf="showConfirmEmailRequiredError()" class="small errortext" [style.position]="'block'">Confirm Email is required</div>
            <div *ngIf="showInvalidConfirmEmailError()" class="small errortext" [style.position]="'block'">Not a valid Email</div>
        </div>
        <div class="form-group" [class.has-error]="showInquiryRequiredError()">
            <label [ngClass]="{'errortext': showInquiryRequiredError()}">Inquiry Type<span> *</span></label>
            <select autofocus class="form-control" placeholder="State" formControlName="Inquiry">
                <option *ngFor="let type of inquiryTypes" [value]="type.Name">{{type.Name}}</option>
            </select>
            <div *ngIf="showInquiryRequiredError()" class="small errortext" [style.position]="'block'">Inquiry type is required</div>
        </div>
        <div class="form-group" [class.has-error]="showCommentRequiredError()">
            <label [ngClass]="{'errortext': showCommentRequiredError()}">Comment<span> *</span></label>
            <textarea class="form-control" placeholder="Comment" formControlName="Comment"></textarea>
            <div *ngIf="showCommentRequiredError()" class="small errortext" [style.position]="'block'">Comment is required</div>
        </div>
        <button [disabled]="!contactUsForm.valid || isSendingEmail" type="submit"
            class="btn btn-primary btn-flat inline-block block-right">Submit</button>
    </form>
</div>

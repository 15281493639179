import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IGlazingSystemGasLayer } from '../interfaces/glazing-system-gas-layer';
import { IGasType } from '../interfaces/gas-type';
import { IGlazingSystem } from '../interfaces/glazing-system';

export interface IGlazingSystemGasLayerDynamicControlsParameters {
    formGroup?: string;
    glazingSystems?: IGlazingSystem[];
    gasTypes?: IGasType[];
}

export class GlazingSystemGasLayerDynamicControls {

    formGroup: string;
    glazingSystems: IGlazingSystem[];
    gasTypes: IGasType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private glazingsystemgaslayer?: IGlazingSystemGasLayer, additionalParameters?: IGlazingSystemGasLayerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GlazingSystemGasLayer';
        this.glazingSystems = additionalParameters && additionalParameters.glazingSystems || undefined;
        this.gasTypes = additionalParameters && additionalParameters.gasTypes || undefined;

        this.Form = {
            GasTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gas Type',
                name: 'GasTypeId',
                options: this.gasTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.GasTypeId || null,
            }),
            GlazingSystemId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Glazing System',
                name: 'GlazingSystemId',
                options: this.glazingSystems,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.GlazingSystemId || null,
            }),
            Layer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Layer',
                name: 'Layer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.Layer || null,
            }),
            Thickness: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thickness',
                name: 'Thickness',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 5,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.Thickness || null,
            }),
        };

        this.View = {
            GasTypeId: new DynamicLabel({
			    label: 'Gas Type',
			    value: getMetaItemValue(this.gasTypes as unknown as IMetaItem[], this.glazingsystemgaslayer && this.glazingsystemgaslayer.hasOwnProperty('GasTypeId') && this.glazingsystemgaslayer.GasTypeId !== null ? this.glazingsystemgaslayer.GasTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            GlazingSystemId: new DynamicLabel({
			    label: 'Glazing System',
			    value: getMetaItemValue(this.glazingSystems as unknown as IMetaItem[], this.glazingsystemgaslayer && this.glazingsystemgaslayer.hasOwnProperty('GlazingSystemId') && this.glazingsystemgaslayer.GlazingSystemId !== null ? this.glazingsystemgaslayer.GlazingSystemId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Layer: new DynamicLabel({
			    label: 'Layer',
			    value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.Layer || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Thickness: new DynamicLabel({
			    label: 'Thickness',
			    value: this.glazingsystemgaslayer && this.glazingsystemgaslayer.Thickness || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 5,
			    })
			}
            ),
        };

    }
}

<div>
    <h2>Definitions:</h2>
    <a name="1"></a>
    <strong>Acid-Etched Glass:</strong> Glass that has been lightly frosted/etched with an acid process so as to scatter
    the visible light.  The performance properties of acid-etched glass are approximately the same as those of regular
    clear or low iron glass.  However both the visible transmittance and visible reflectance are modified.
    Regarding transmittance, the process renders the glass translucent rather than transparent, and, regarding reflectance,
    it changes the acid-etched surface from specular to diffuse reflectance.
    As there is no approved Lawrence Berkeley National Laboratory (LBNL) process for adding translucent
    products into the International Glazing Database (IGDB) at this time, SystemSelect uses the glass performance properties
    of the equivalent thickness of clear or low iron glass for acid-etched glass.
    <p></p>
    <a name="2"></a>
    <strong>Air Space:</strong> The cavity between two panes of glass in an insulating glass unit.
    <p></p>
    <a name="3"></a>
    <strong>Argon:</strong> An inert, nontoxic gas used in the airspace of insulating glass units to reduce heat transfer, i.e., lower the U-factor.
    <p></p>
    <a name="4"></a>
    <strong>Center-of-Glass (COG) Data:</strong> Performance data that only accounts for the center area of the glass.
    It does not take into account the entire fenestration (glass + framing) system. The Fenestration & Glazing Industry Alliance
    (FGIA) defines the Center-of-Glass area as 64mm (2.5”) away from any framing member.
    <p></p>
    <a name="5"></a>
    <strong>Gas Content:</strong> The gas used in the airspace of insulating glass units, either air or 90% argon.
    <p></p>
    <a name="6"></a>
    <strong>Inboard Lite:</strong> The interior glass lite in an Insulating Glass Unit.
    <p></p>
    <a name="7"></a>
    <strong>Insulating Glass (also known as Insulating Glass Unit, IG Unit, IGU):</strong> An
    Insulating Glass (IG) unit is a hermetically sealed combination of two or more lites of glass separated by a dry
     airspace. Also referred to as 'Dual Pane' glass.
     <p></p>
     <a name="8"></a>
     <strong>Laminated Glass:</strong> Two or more lites of glass permanently bonded together with one or more interlayers.
     <p></p>
     <a name="9"></a>
     <strong>Light to Solar Gain (LSG):</strong> The ratio of visible light transmittance to the solar heat gain coefficient (SHGC).
    The higher the number, the more efficient the glazing is as a light source.
    <p></p>
    <a name="10"></a>
    <strong>Low-E (Low Emissivity):</strong> A coating applied to glass that has a low rate of emitting (radiating) absorbed radiant
    energy (heat); it lowers (improves) the U-factor.
    <p></p>
    <a name="11"></a>
    <strong>Low Iron Glass:</strong> Glass that has lower iron content in the basic glass batch than regular clear glass. Iron imparts a slight green tint in clear
    glass that becomes more pronounced in thicker glass. Low iron glass has minimal to no green color. Also called
    'water-white' glass.
    <p></p>
    <a name="12"></a>
    <strong>Monolithic:</strong> Refers to a single lite of glass as a
    finished product. Also referred to as 'Single Pane' glass.
    <p></p>
    <a name="13"></a>
    <strong>Outboard Lite:</strong>
    The exterior glass lite in an Insulating Glass Unit.
    <p></p>
    <a name="14"></a>
    <strong>PVB:</strong> Abbreviation for
    polyvinyl butyral, an extruded polymer sheet used as an interlayer in laminated glass.
    <p></p>
    <a name="15"></a>
    <strong>Reflective:</strong> Glass with a metallic coating that increases its visible and solar
    reflectivity and therefore reduces solar heat gain.
    <p></p>
    <a name="16"></a>
    <strong>Relative Heat Gain (RHG):</strong> A calculated relationship of heat gain through a window system at set conditions that accounts
    for center of glass U-factor/U-Value and center of glass shading coefficient. The RHG can be calculated as follows:
    RHG = 200 (Shading Coefficient) + 14 (U-factor). To calculate the metric RHG (W/m<sup>2</sup>), multiply by 3.154.
    <p></p>
    <a name="17"></a>
    <strong>RGB (Red Green Blue color value):</strong> The RGB color model is an additive color model
    in which red, green, and blue light are added together in various ways to reproduce a broad array of colors. The
    name of the model comes from the initials of the three additive primary colors, red, green, and blue. Values are
    represented separately for each component with a range from 0-255 denoting the saturation of the base color. RGB
    values in SystemSelect&trade; are derived from L*a*b* coordinates obtained from the LBNL Window 7.4 program for transmitted
    color with the exception of Polar White. The Window 7.4 program can not accurately represent the Polar White
    interlayer color, so a pure white color is provided. Silk-screened glass in SystemSelect&trade; uses RGB values only for the
    base substrate. Glass color decisions should not be based on RGB color simulations. Actual glass samples or mockups
    are required to get true glass colors.
    <p></p>
    <a name="18"></a>
    <strong>Saflex® HP:</strong> A strong interlayer used in laminated glass to provide protection from hurricanes, typhoons and violent storms.
    <p></p>
    <a name="19"></a>
    <strong>SentryGlas® :</strong> Kuraray ionoplast interlayers used to create lighter, safer, more
    structural glass that can stand up to greater loads and higher threat levels.
    <p></p>
    <a name="20"></a>
    <strong>Shading Coefficient (SC):</strong> The ratio of solar heat gain through a particular product compared to the solar heat
    gain through 1/8" (3mm) clear glass. The lower the shading coefficient, the lower the solar heat gain, and
    therefore, the lower the air-conditioning loads.
    <p></p>
    <a name="21"></a>
    <strong>Silk-screened:</strong> Ceramic frit is screen printed onto the surface of the glass through a fine mesh screen producing standard or custom designs.
    <p></p>
    <a name="22"></a>
    <strong>Solar Heat Gain Coefficient (SHGC):</strong> The amount of solar energy both directly
    transmitted, and absorbed and reradiated into a building. It is computed in accordance with NFRC 200 methodology
    using LBNL Window software.
    <p></p>
    <a name="23"></a>
    <strong>Solar Reflectance - Outside:</strong> The amount of solar
    energy reflected away from a building.
    <p></p>
    <a name="24"></a>
    <strong>Solar Transmittance (also known as Total Solar
        Transmittance):</strong> Is based on laboratory spectrophotometric measurements weighted by an appropriate
    weighting function using LBNL Window software in accordance with NFRC 300 methodology. The wavelength range of the
    sun's energy used to calculate Solar Transmittance is 0.30 to 2.5 microns.
    <p></p>
    <a name="25"></a>
    <strong>StormGlass™:</strong> An Oldcastle BuildingEnvelope® branded hurricane interlayer used in
    laminated glass. In independent testing, StormGlass™ met test requirements for large glass sizes up to 50 square
    feet. That means the freedom to design larger glazed openings while meeting the most stringent building codes for
    hurricane resistance. StormGlass™ has even been tested in four-sided structural glazed systems.
    <p></p>
    <a name="26"></a>
    <strong>Thickness (inches):</strong> Thickness refers to the total thickness of the glass,
    measured in inches. This may vary slightly from the nominal thickness of the glass as it's often referred to in
    inches and fractions of an inch rather than the more exact decimal thickness observed here.
    <p></p>
    <a name="27"></a>
    <strong>U-factor:</strong> See Winter &amp; Summer U-factor
    <p></p>
    <a name="28"></a>
    <strong>Ultraviolet (UV) Transmittance:</strong> is based on laboratory spectrophotometric
    measurements weighted by an appropriate weighting function using LBNL Window software in accordance with NFRC 300
    methodology. The wavelength range of the sun's energy used to calculate UV transmittance is 0.30 to 0.38 microns.
    <p></p>
    <a name="29"></a>
    <strong>U-Value:</strong> See Winter &amp; Summer U-factor
    <p></p>
    <a name="30"></a>
    <strong>Visible Reflectance - Inside:</strong> The amount of visible light reflected when viewed
    from the inside of a building.
    <p></p>
    <a name="31"></a>
    <strong>Visible Reflectance - Outside:</strong> The amount of visible light reflected when viewed from outside of a building.
    <p></p>
    <a name="32"></a>
    <strong>Visible Transmittance
        (also known as Visible Light Transmittance, VLT):</strong> Is based on laboratory spectrophotometric
    measurements weighted by an appropriate weighting function using LBNL Window software in accordance with NFRC 300
    methodology. The wavelength range of the sun's energy used to calculate Visible transmittance is 0.38 to 0.78
    microns.
    <p></p>
    <a name="33"></a>
    <strong>Winter &amp; Summer U-factor (Also known as U-Value):</strong> The measure
    of heat gain or loss through a glazing system due to differences between exterior and interior air temperatures.
    U-factors given are center-of-glass values calculated per NFRC 100 standard using LBNL Window software. The lower
    the U-factor, the less heat is transmitted through a material in a given time for a given temperature difference.
    Hence, the lower the U-factor, the better the insulating properties. Winter U-factors are based on an outdoor
    temperature of -0.4°F (-18°C), an indoor temperature of 69.8°F (21°C) and a 12.3 mph wind speed with no sun. Summer
    U-factors are based on an outdoor temperature of 89.6°F (32°C), an indoor temperature of 75.2°F (24°C), a 6.3 mph
    wind speed and a solar intensity of 248.2 Btu/hr/ft². The units for U-factor are Btu/hr/ft²/°F. To calculate the
    metric U-factor (W/m<sup>2</sup>/°C), multiply by 5.678.
    <p></p>
</div>

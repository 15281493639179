<div class="panel panel-default" *ngIf="glassProducts">
    <div class="panel-heading">{{layerLabel}} <i>(<b>{{glassProducts.length}} </b>Product(s) Available)</i></div>
    <div class="panel-body">
        <ul class="list-group" id="glassProductList">
            <button type="button" class="list-group-item list-group-item-action ib-container v-align-middle p-sm"
                [ngClass]="{'active': isActiveProduct(product.Id)}" *ngFor="let product of glassProducts | paginate: { itemsPerPage: 10, currentPage: currentPage, id: layerLabel }"
                (click)="selectGlassProduct(product)">
                <span>
                    <div style="display:inline-block;">
                        <span class="color-swatch-sm" [ngStyle]="{'background-color': getSwatchColor(product)}"></span></div>
                    <div class="glass-description" style="display:inline-block;">
                        {{product.Name}}
                    </div>
                </span>
            </button>
        </ul>
        <pagination-controls *ngIf="glassProducts && glassProducts.length" class="product-pagination" (pageChange)="currentPage = $event" id="{{layerLabel}}"></pagination-controls>
        <div *ngIf="!glassProducts || !glassProducts.length">No results for the options you have selected, please adjust your selections,
            call 1-866-OLDCASTLE (653-2278), or <a [routerLink]="[]" (click)="navigateToContactUs()">contact us</a> for additional assistance</div>
    </div>
</div>

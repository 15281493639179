import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { IAuthUser } from '@system-select/model';
import { IUserRole } from '@system-select/model';
import { AuthUserDynamicControlsExtended } from '../../create-account/create-account-form/auth-user.form-controls';

export class AuthUserDynamicConfig<T extends IAuthUser> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private authUser: T,
        private roles: IUserRole[],
        private configControls?: string[]) {
        super();

        const dynamicControls = new AuthUserDynamicControlsExtended(this.authUser, this.roles);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
            ];
        }

        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
        };
    }
}

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '@mt-ng2/auth-module';
import { LeedReportBuilderInterfaceComponent } from './leed-report-builder-interface/leed-report-builder-interface.component';

const leedReportBuilderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: LeedReportBuilderInterfaceComponent,
        path: 'leed-report-builder',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            leedReportBuilderRoutes,
        ),
    ],
})
export class LeedReportBuilderRoutingModule {

}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'create-account',
    templateUrl: 'create-account.component.html',
})

export class CreateAccountComponent {
    @Input('isEmbedded') isEmbedded: boolean;
    @Output('emitSuccessfulLogin') emitSuccessfulLogin: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() { }

    updateSuccessfulLogin(loggedIn: boolean): void {
        this.emitSuccessfulLogin.emit(loggedIn);
    }
}

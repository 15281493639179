<mt-modal-wrapper
    title="Input Email Info"
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="emailFormModalApi = $event"
>
    <div class="m-t-sm text-center">
        <div class="row m-t-md">
            <div class="col-xs-12 form-group">
                <form #emailForm="ngForm" (ngSubmit)="save()">
                    <input
                        class="form-control"
                        name="toName"
                        placeholder="To"
                        [(ngModel)]="emailParams.To"
                        required
                        #toName="ngModel"
                    />
                    <div
                        class="form-error"
                        *ngIf="toName.touched && toName.invalid"
                    >
                        To Name is required
                    </div>
                    <input
                        class="form-control"
                        name="subject"
                        placeholder="Subject"
                        [(ngModel)]="emailParams.Subject"
                        required
                        #subject="ngModel"
                    />
                    <div
                        class="form-error"
                        *ngIf="subject.touched && subject.invalid"
                    >
                        Subject is required
                    </div>
                    <textarea
                        class="form-control"
                        name="message"
                        placeholder="Message"
                        [(ngModel)]="emailParams.Message"
                        #message="ngModel"
                    ></textarea>
                    <div>
                        <button
                            class="btn btn-primary btn-send"
                            type="submit"
                            [disabled]="emailForm.invalid"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <ngx-loading [show]="loadingResult"></ngx-loading>
    </div>
</mt-modal-wrapper>

import { Component, Input } from '@angular/core';
import { IGlazingPerformance } from '@system-select/web-services';
import { UnitsService, FormatService } from '@system-select/common';
import { IGlass } from '@system-select/model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-glass-system-performance',
    templateUrl: './glass-system-performance.component.html',
})
export class GlassSystemPerformanceComponent {
    @Input() glazingResult: IGlazingPerformance;
    @Input() glassLayers: IGlass[];
    @Input() isMissingOpticsFile: boolean;
    @Input() isSpandrel: boolean;

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
        private router: Router,
        ) { }

    mmToIn(mm: number): number {
        return this.unitsService.mmToIn(mm);
    }

    uFactorMetricToImp(uFactor: number): number {
        return this.unitsService.uFactorMetricToImp(uFactor);
    }

    formatUvTransmittance(uvTransmittance: number): string {
        if (this.glassLayers && this.glassLayers.length > 0) {
            return this.formatService.formatUVTransmittanceDisplay(this.glassLayers, uvTransmittance);
        }
        return '';
    }

    async navigateToContactUs(): Promise<void> {
        await this.router.navigate(['/contact-us']);
    }
}

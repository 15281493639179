import { NotFoundComponent } from './components/not-found.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [ NotFoundComponent ],
    exports: [ RouterModule ],
    imports: [
        RouterModule.forChild([
            { path: '**', component: NotFoundComponent },
        ]),
    ],
})
export class WildcardRoutingModule { }

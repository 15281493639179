import { IEntity } from './base';

import { IProductCategory } from './product-category';

export interface ICustomSizingRule extends IEntity {
    Name: string;

    // reverse nav
    ProductCategories?: IProductCategory[];
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './common/components/home/home.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { GlassBuilderComponent } from './glass-builder/glass-builder.component';
import { ProductRecommendationComponent } from './product-recommendations/product-recommendation.component';
import { TotalProductBuilderComponent } from './total-product/total-product-builder/total-product-builder.component';
import { LoginOverrideComponent } from './login/login-override.component';
import { PublicForgotPasswordComponent } from './common/components/forgot-password.component';
import { DefinitionsComponent } from './common/components/definitions/definitions.component';
import { ContactUsComponent } from './common/components/contact-us/contact-us.component';
import { PublicResetPasswordComponent } from './common/components/reset-password.component';
import { LeedReportBuilderInterfaceComponent } from './leed-report-builder/leed-report-builder-interface/leed-report-builder-interface.component';

const title = 'SystemSelect';
const appRoutes: Routes = [
    { path: 'login', component: LoginOverrideComponent, title: title, },
    { path: 'home', component: HomeComponent, title: title, },
    { path: 'forgotpassword', component: PublicForgotPasswordComponent, title: title, },
    { path: 'resetpassword', component: PublicResetPasswordComponent, title: title, },
    { path: 'create-account', component: CreateAccountComponent, title: title, },
    { path: 'total-product-builder', component: TotalProductBuilderComponent, title: title, },
    { path: 'glass-builder', component: GlassBuilderComponent, title: title, },
    { path: 'product-recommendations', component: ProductRecommendationComponent, title: title, },
    { path: 'leed-report-builder', component: LeedReportBuilderInterfaceComponent, title: title, },
    { path: 'definitions', component: DefinitionsComponent, title: title, },
    { path: 'contact-us', component: ContactUsComponent, title: title, },
    { path: '', redirectTo: '/home', pathMatch: 'full', title: title, },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    ],
})

export class AppRoutingModule { }

import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Output } from '@angular/core';
import { IPerformanceTargetFilters } from '@system-select/model';

@Component({
    selector: 'performance-targets',
    templateUrl: 'performance-targets.component.html',
})

export class PerformanceTargetsComponent {
    @Output() emitUpdatedFilter: EventEmitter<IPerformanceTargetFilters> = new EventEmitter<IPerformanceTargetFilters>();
    minUFactorValue = 0;
    maxUFactorValue = 1;
    minShgcValue = 0;
    maxShgcValue = 1;
    minTransmittanceValue = 0;
    maxTransmittanceValue = 100;
    minReflectanceOutsideValue = 0;
    maxReflectanceOutsideValue = 100;
    minReflectanceInsideValue = 0;
    maxReflectanceInsideValue = 100;
    smallOptions: Options = {
        ceil: 1,
        floor: 0,
        step: 0.01,
    };
    options: Options = {
        ceil: 100,
        floor: 0,
        step: 1,
    };

    performanceTargetsFilter: IPerformanceTargetFilters = {
        ReflectanceInsideHighValue: '',
        ReflectanceInsideLowValue: '',
        ReflectanceOutsideHighValue: '',
        ReflectanceOutsideLowValue: '',
        ShgcHighValue: '',
        ShgcLowValue: '',
        TransmittanceHighValue: '',
        TransmittanceLowValue: '',
        UFactorHighValue: '',
        UFactorLowValue: '',
    };
    constructor() { }

    setFilterValues(): void {
        this.performanceTargetsFilter.UFactorLowValue = this.minUFactorValue.toString();
        this.performanceTargetsFilter.UFactorHighValue = this.maxUFactorValue.toString();
        this.performanceTargetsFilter.ShgcLowValue = this.minShgcValue.toString();
        this.performanceTargetsFilter.ShgcHighValue = this.maxShgcValue.toString();
        this.performanceTargetsFilter.TransmittanceLowValue = this.minTransmittanceValue.toString();
        this.performanceTargetsFilter.TransmittanceHighValue = this.maxTransmittanceValue.toString();
        this.performanceTargetsFilter.ReflectanceInsideLowValue = this.minReflectanceInsideValue.toString();
        this.performanceTargetsFilter.ReflectanceInsideHighValue = this.maxReflectanceInsideValue.toString();
        this.performanceTargetsFilter.ReflectanceOutsideLowValue = this.minReflectanceOutsideValue.toString();
        this.performanceTargetsFilter.ReflectanceOutsideHighValue = this.maxReflectanceOutsideValue.toString();
        this.emitUpdatedFilter.emit(this.performanceTargetsFilter);
    }
}

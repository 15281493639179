import { IDocument } from '../document';
export interface IWindowDatasetBasePayload {
    Archived: boolean,
    IsCOG: boolean,
    LEEDPoints: number;
    HasCustomSizing: boolean;
    Documents: IDocument[];
    ProductCategoryId: number,
    ProductCategorySubCategoryIds: number[],
    SightlineId: number,
    SystemDepthId: number,
    SiteLink: string;
}

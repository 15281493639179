import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGasType, IGlazingSystemGasLayer } from '@system-select/model';
import { UnitsService } from '@system-select/common';
@Component({
    selector: 'app-gas-layer-edit',
    templateUrl: './gas-layer-edit.component.html',
})

export class GasLayerEditComponent implements OnInit {
    @Input() gasLayer: IGlazingSystemGasLayer;
    @Input() layer: number;
    @Input() gasTypeItems: IGasType[];
    @Output() emitUpdatedGasLayer: EventEmitter<IGlazingSystemGasLayer> = new EventEmitter<IGlazingSystemGasLayer>();

    gasTypeId: number;
    thickness: number;
    airSpaceItems: IAirSpaceThickness[] = [
        {Thickness: 0.25, InchesLabel: '1/4"', MetricLabel: '(6mm)'},
        {Thickness: 0.3125, InchesLabel: '5/16"', MetricLabel: '(8mm)'},
        {Thickness: 0.34375, InchesLabel: '11/32"', MetricLabel: '(8.7mm)'},
        {Thickness: 0.375, InchesLabel: '3/8"', MetricLabel: '(10mm)'},
        {Thickness: 0.40625, InchesLabel: '13/32"', MetricLabel: '(10.3mm)'},
        {Thickness: 0.4375, InchesLabel: '7/16"', MetricLabel: '(11mm)'},
        {Thickness: 0.46875, InchesLabel: '15/32"', MetricLabel: '(11.9mm)'},
        {Thickness: 0.5, InchesLabel: '1/2"', MetricLabel: '(12mm)'},
        {Thickness: 0.53125, InchesLabel: '17/32"', MetricLabel: '(13.5mm)'},
        {Thickness: 0.5625, InchesLabel: '9/16"', MetricLabel: '(14mm)'},
        {Thickness: 0.625, InchesLabel: '5/8"', MetricLabel: '(16mm)'},
        {Thickness: 0.6875, InchesLabel: '11/16"', MetricLabel: '(17.5mm)'},
        {Thickness: 0.75, InchesLabel: '3/4"', MetricLabel: '(19mm)'},
        {Thickness: 0.875, InchesLabel: '7/8"', MetricLabel: '(22mm)'},
        {Thickness: 1, InchesLabel: '1"', MetricLabel: '(24mm)'},
    ];

    constructor(
        private unitsService: UnitsService,
    ) { }

    ngOnInit(): void {
        this.gasLayer.Layer = this.layer;
        if (this.gasLayer.GasTypeId) {
            this.gasTypeId = this.gasLayer.GasTypeId;
        }
        if (this.gasLayer.Thickness) {
            this.thickness = this.unitsService.mmToIn(this.gasLayer.Thickness);
        }
    }

    updateGasLayer(): void {
        if (this.gasTypeId) {
            this.gasLayer.GasTypeId = +this.gasTypeId;
        }
        if (this.thickness) {
            this.gasLayer.Thickness = this.unitsService.inToMm(this.thickness);
        }
        if (this.gasTypeId && this.thickness) {
            this.emitUpdatedGasLayer.emit(this.gasLayer);
        } else {
            this.emitUpdatedGasLayer.emit(null);
        }
    }

    getMetricThickness(airSpace: number): string {
        return this.unitsService.inToMm(airSpace).toString() + 'mm';
    }

}

interface IAirSpaceThickness {
    Thickness: number,
    InchesLabel: string,
    MetricLabel: string,
}

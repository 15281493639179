<div class="panel panel-default">
    <div class="panel-heading">
        <h4>1. Select Performance Targets</h4>
    </div>
    <div class="panel-body">
        <div class="col-md-6">
            <span><b>Energy Specs</b></span
            ><br />
            <span>Winter U-factor (BTU/ ft2-hr-°F ):</span>
            <ngx-slider
                [(value)]="minUFactorValue"
                [(highValue)]="maxUFactorValue"
                [options]="smallOptions"
            ></ngx-slider>
            <span>Solar Heat Gain Coefficient:</span>
            <ngx-slider
                [(value)]="minShgcValue"
                [(highValue)]="maxShgcValue"
                [options]="smallOptions"
            ></ngx-slider>
        </div>
        <div class="col-md-6" style="border-left: solid 1px #ddd">
            <span><b>Light Characteristics</b></span
            ><br />
            <span>Transmittance (%):</span>
            <ngx-slider
                [(value)]="minTransmittanceValue"
                [(highValue)]="maxTransmittanceValue"
                [options]="options"
            ></ngx-slider>
            <span>Reflectance - Outside (%):</span>
            <ngx-slider
                [(value)]="minReflectanceOutsideValue"
                [(highValue)]="maxReflectanceOutsideValue"
                [options]="options"
            ></ngx-slider>
            <span>Reflectance - Inside (%):</span>
            <ngx-slider
                [(value)]="minReflectanceInsideValue"
                [(highValue)]="maxReflectanceInsideValue"
                [options]="options"
            ></ngx-slider>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary" (click)="setFilterValues()">
                Update Results
            </button>
        </div>
    </div>
</div>

import { Component, Inject, Injector } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ForgotPasswordComponent, ILoginConfig, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'forgot-password',
    template: `
     <div class="login-box">
            <div class="login-box-body">
                <p class="login-box-msg">{{ config.messageOverrides.failedPattern }}</p>
                <form [formGroup]="forgotPasswordForm" (submit)="onLogin()">
                    <div
                        class="form-group has-feedback"
                        [class.has-error]="emailErrorStatus.hasError"
                    >
                        <input
                            type="text"
                            autofocus
                            class="form-control"
                            placeholder="example@domain.com"
                            formControlName="email"
                        />
                        <span class="fa fa-user form-control-feedback"></span>
                        <div
                            *ngIf="emailErrorStatus.required"
                            class="small errortext"
                            [style.position]="'block'"
                        >
                            Email is required
                        </div>
                        <div
                            *ngIf="emailErrorStatus.invalid"
                            class="small errortext"
                            [style.position]="'block'"
                        >
                            Invalid email address. Valid e-mail can contain only
                            letters, numbers and '@'
                        </div>
                    </div>
                    <div class="padded block-parent">
                        <button
                            type="button"
                            routerLink="/login"
                            class="btn btn-default btn-flat inline-block block-left"
                        >
                            Back
                        </button>
                        <button
                            [disabled]="!forgotPasswordForm.valid"
                            type="submit"
                            class="btn btn-primary btn-flat inline-block block-right"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
`,
})

export class PublicForgotPasswordComponent extends ForgotPasswordComponent {
    constructor(
        private injector: Injector,
        private _authService: AuthService,
        private _notificationsService: NotificationsService,
        @Inject(LoginModuleConfigToken) private loginConfig: ILoginConfig,
    ) {
        super(injector);
    }

    onLogin(): void {
        if (this.forgotPasswordForm.valid) {
            const values = this.forgotPasswordForm.value;
            this._authService.forgot(values.email).subscribe(
                () => {
                    this._notificationsService.success(
                        `If this email is associated with a valid user, you will receive an email with a link to reset your password`,
                    );
                },
                () =>
                    this._notificationsService.success(
                        `If this email is associated with a valid user, you will receive an email with a link to reset your password`,
                    ),
            );
        } else {
            markAllFormFieldsAsTouched(this.forgotPasswordForm);
        }
    }

}

<mt-modal-wrapper
    title="Save to Project?"
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="saveToProjectModalApi = $event"
>
    <div class="m-t-sm text-center">
        <div class="row m-t-md">
            <div class="col-xs-8 col-xs-offset-2 actions">
                <button
                    *ngIf="defaultProject"
                    class="btn btn-margin-bottom"
                    (click)="useDefaultProject()"
                >
                    Use Default Project
                </button>
                <button class="btn btn-margin-bottom" (click)="chooseProject()">
                    Choose Project
                </button>
                <button class="btn" (click)="useNoProject()">
                    Don't Save to Project
                </button>
            </div>
        </div>
    </div>
</mt-modal-wrapper>

<choose-project
    [displayForm]="displayProjectList"
    [isAuthenticated]="isAuthenticated"
    (emitProjectSelection)="useProject($event)"
    (onModalClosed)="chooseProjectModalClosed()"
></choose-project>

import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { WindowTotalProductService } from '@system-select/web-services';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams, SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { UnitsService, FormatService } from '@system-select/common';
import { MyProductsService } from '../my-products.service';
import { WindowTotalProductsListConfig } from './window-total-products.entity-list-config';
import { AuthService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MyProductsListTypes, IWindowTotalProductsSearchItem } from '@system-select/model';

@Component({
    selector: 'window-total-products-list',
    templateUrl: 'window-total-products-list.component.html',
})
export class WindowTotalProductsListComponent implements OnInit, OnChanges {
    @Input() selectedProjectId: number;
    query = '';
    entityListConfig: WindowTotalProductsListConfig;
    products: IWindowTotalProductsSearchItem[] = [];
    order: string;
    orderDirection: string;
    total: number;
    currentPage = 1;
    itemsPerPage = 25;
    isGeneratingDataSheet = false;
    selectedProductId: number;
    productListTypes = MyProductsListTypes;

    constructor(
        private unitsService: UnitsService,
        private formatService: FormatService,
        private myProductsService: MyProductsService,
        private authService: AuthService,
        private windowTotalProductService: WindowTotalProductService,
        private notificationsService: NotificationsService,
    ) {
        this.entityListConfig = new WindowTotalProductsListConfig(unitsService, formatService);
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    ngOnInit(): void {
        this.entityListConfig.onItemDeleted.subscribe((totalProductItem) => {
            const i = this.products.indexOf(totalProductItem);
            this.products.splice(i, 1);
            this.products = this.products.slice();
            this.myProductsService.productRemoved.emit();
            this.notificationsService.success('Successfully removed Total Product');
        });

        this.entityListConfig.onDataSheetDownload.subscribe((selectedProductId) => {
            this.isGeneratingDataSheet = true;
            this.selectedProductId = selectedProductId;
        });

        this.getTotalProducts();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedProjectId && !changes.selectedProjectId.firstChange) {
            this.getTotalProducts();
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'UserId',
            value: this.authService.currentUser.getValue().Id.toString(),
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProjectId',
            value: this.selectedProjectId ? this.selectedProjectId.toString() : '',
        }));
        return _extraSearchParams;
    }

    getTotalProducts(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.windowTotalProductService.getTotalProducts(searchparams).subscribe(
            (answer) => {
                this.products = answer.body;
                this.total = +answer.headers.get('X-List-Count');
                this.products.forEach((product) => {
                        this.formatService.formatWindowSolidLayersWitGlassNames(product.WindowGlazingSystem.WindowSolidLayers);
                });
            },
        );
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTotalProducts();
    }

    onSuccessfulDownload(): void {
        this.isGeneratingDataSheet = false;
    }

}

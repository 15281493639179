import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomerAddress } from '../interfaces/customer-address';
import { IAddress } from '../interfaces/address';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerAddressDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    addresses?: IAddress[];
}

export class CustomerAddressDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    addresses: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customeraddress?: ICustomerAddress, additionalParameters?: ICustomerAddressDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerAddress';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customeraddress && this.customeraddress.AddressId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customeraddress && this.customeraddress.CustomerId || null,
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customeraddress && this.customeraddress.hasOwnProperty('IsPrimary') && this.customeraddress.IsPrimary !== null ? this.customeraddress.IsPrimary : false,
            }),
        };

        this.View = {
            AddressId: new DynamicLabel({
			    label: 'Address',
			    value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.customeraddress && this.customeraddress.hasOwnProperty('AddressId') && this.customeraddress.AddressId !== null ? this.customeraddress.AddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CustomerId: new DynamicLabel({
			    label: 'Customer',
			    value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.customeraddress && this.customeraddress.hasOwnProperty('CustomerId') && this.customeraddress.CustomerId !== null ? this.customeraddress.CustomerId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsPrimary: new DynamicLabel({
			    label: 'Is Primary',
			    value: this.customeraddress && this.customeraddress.hasOwnProperty('IsPrimary') && this.customeraddress.IsPrimary !== null ? this.customeraddress.IsPrimary : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}

<div
    id="custom-product-inquiry-form"
    [ngStyle]="{ visibility: getComponentVisibility() }"
>
    <ng-container *ngIf="isEditing">
        <mt-dynamic-form
            [config]="formObject"
            ([isEditing])="(isEditing)"
            (submitted)="formSubmitted($event)"
            (formCreated)="onFormCreated($event)"
        >
            <button
                type="submit"
                mtDisableClickDuringHttpCalls
                Class="btn btn-flat btn-primary"
            >
                Submit
            </button>
            <button
                type="button"
                Class="btn btn-flat btn-default"
                (click)="cancelClick()"
            >
                Cancel
            </button>
        </mt-dynamic-form>
    </ng-container>
</div>

import { Component } from '@angular/core';
import { LeedReportBuilderFormSteps } from '@system-select/model';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';

@Component({
    selector: 'leed-report-choose-path',
    templateUrl: 'leed-report-choose-path.component.html',
})

export class LeedReportChoosePathComponent {
    LeedReportBuilderFormSteps = LeedReportBuilderFormSteps;
    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
    ) { }

    onPathSelected(paths: LeedReportBuilderFormSteps[]): void {
        this.stateService.selectedPaths$.next(paths);
        this.stateService.markStepCompleted(LeedReportBuilderFormSteps.ChoosePath);
        this.stateService.currentFormStep$.next(paths[0]);
    }
}

<div class="products-container">
    <mt-entity-list
        class="performance-table saved-glazings-table table-xs first-header-green last-header-green"
        [entities]="recommendedProducts"
        [(itemsPerPage)]="itemsPerPage"
        [total]="total"
        [(currentPage)]="currentPage"
        (pageChanged)="getGlazingSystems()"
        (columnSortedEvent)="columnSorted($event)"
        [emptyMessage]="'No saved glass products.'"
        [entityListConfig]="entityListConfig"
    >
    </mt-entity-list>
</div>

<spec-sheet-input-form
    [displayForm]="isGeneratingDataSheet"
    [productListType]="productListTypes.GlassProducts"
    [selectedProductId]="selectedProductId"
    (emitSuccessfulDownload)="onSuccessfulDownload()"
>
</spec-sheet-input-form>

<save-to-project
    [displayForm]="isSavingToProject"
    [isAuthenticated]="isAuthenticated"
    (emitSaveProjectId)="updateSaveProjectId($event)"
    (onModalClosed)="saveToProjectModalClosed()"
>
</save-to-project>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
        ></create-account>
    </div>
</mt-modal-wrapper>

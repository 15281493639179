import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import {
    DynamicFormModule,
    DynamicFormModuleConfigToken,
} from "@mt-ng2/dynamic-form";
import { ModalModule } from "@mt-ng2/modal-module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ContactUsService } from "@system-select/web-services";
import { CommonService } from "src/app/common/services/common.service";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { DefinitionsComponent } from "./components/definitions/definitions.component";
import { PublicForgotPasswordComponent } from "./components/forgot-password.component";
import { HomeComponent } from "./components/home/home.component";
import { PublicResetPasswordComponent } from "./components/reset-password.component";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { LoginService } from "./services/login.service";
import { NavModule } from '@mt-ng2/nav-module';

@NgModule({
    declarations: [
        HomeComponent,
        PublicForgotPasswordComponent,
        DefinitionsComponent,
        ContactUsComponent,
        PublicResetPasswordComponent,
        YesNoPipe,
    ],
    exports: [
    YesNoPipe,
    BrowserAnimationsModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    ModalModule,
    DynamicFormModule,
    MtDisableDuringHttpCallsModule,
    NavModule,
],
    imports: [
    BrowserAnimationsModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    ModalModule,
    DynamicFormModule,
    MtDisableDuringHttpCallsModule,
    NavModule,
],
})
export class SharedPublicModule {
    static forRoot(): ModuleWithProviders<SharedPublicModule> {
        return {
            ngModule: SharedPublicModule,
            providers: [
                {
                    provide: DynamicFormModuleConfigToken,
                    useValue: { commonService: CommonService },
                },
                LoginService,
                CommonService,
                ContactUsService,
            ],
        };
    }
}

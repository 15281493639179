import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IProject, IProjectTimeline } from '@system-select/model';
import { ProjectService, ProjectTimelineService } from '@system-select/web-services';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NotificationsService } from '@mt-ng2/notifications-module';
@Component({
    selector: 'projects-list',
    styles: [`
    .project-list-item {
        cursor: pointer;
    }
    .project-pagination ::ng-deep .ngx-pagination .current {
        background: #00573d !important;
    }
    tr.selected td {
        background-color: #00573d;
        color: white;
    }
    `],
    templateUrl: 'projects-list.component.html',
})

export class ProjectsListComponent implements OnInit {
    @Input() isLeedReportEmbedded: boolean;
    @Output() onProjectSelected: EventEmitter<IProject> = new EventEmitter<IProject>();
    projects: IProject[];
    selectedProject: IProject;
    currentProjectsPage = 1;
    selectedProjectId: null;
    isAddingNewProject = false;
    isEditingProject = false;
    emptyProject: IProject;
    projectTimelines: IProjectTimeline[];

    constructor(
        private projectService: ProjectService,
        private notificationsService: NotificationsService,
        private projectTimelineService: ProjectTimelineService,
    ) { }

    ngOnInit(): void {
        this.emptyProject = this.projectService.getEmptyProject();
        this.projectTimelineService.getAll().subscribe((timelines) => {
            this.projectTimelines = timelines;
            this.initializeProjects();
        });
    }

    initializeProjects(): void {
        this.projectService.getAll().subscribe((projects) => this.projects = projects);
    }

    updateSelectedProject(project: IProject): void {
        if (this.isLeedReportEmbedded && this.projectIsLeedInvalid(project)) {
            this.editProject(project);
        } else {
            this.selectedProject = project;
            this.onProjectSelected.emit(project);
        }
    }

    /**
     * Checks whether a Project is valid to use in a LEED Report
     * @param project
     * @returns A boolean indicating validation
     */
    projectIsLeedInvalid(project: IProject): boolean {
        return !project.ContactEmail
            || !project.ContactPhone
            || !project.CompanyName
            || !project.CompanyType
            || !project.CompanyAddress
            || !project.CompanyCity
            || !project.CompanyStateCode
            || !project.CompanyZip
            || !project.City
            || !project.ContactName
            || !project.StateCode
            || !project.ProjectTimelineId;
    }

    clearSelectedProject(): void {
        this.selectedProject = null;
        this.onProjectSelected.emit(null);
    }

    addNewProject(): void {
        if (!this.isAddingNewProject) {
            this.isAddingNewProject = true;
        }
    }

    endAddingProject(doneEditing: boolean): void {
        this.isAddingNewProject = false;
        this.emptyProject = this.projectService.getEmptyProject();
        if (doneEditing) {
            this.initializeProjects();
        }
    }

    editProject(project: IProject): void {
        this.selectedProject = project;
        this.isEditingProject = true;
    }

    endEditingProject(doneEditing: boolean): void {
        this.isEditingProject = false;
        if (doneEditing) {
            this.initializeProjects();
            this.onProjectSelected.emit(this.selectedProject);
        }
    }

    deleteProject(project: IProject): void {
        Swal.fire(<SweetAlertOptions>{
            focusCancel: false,
            focusConfirm: false,
            showCancelButton: true,
            showCloseButton: true,
            text: 'Are you sure you want to delete this from your saved projects?',
            title: 'Confirm',
            type: 'warning',
        }).then((answer) => {
            if (answer.value) {
                this.projectService.delete(project.Id).subscribe(
                    () => {
                        this.notificationsService.success('Project successfully removed.');
                        this.initializeProjects();
                    }, () => {
                        this.notificationsService.error('Unable to delete project.  Please make sure the project contains no saved products.');
                    });
            }
        }).catch(() => null)
    }
}

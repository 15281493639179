import { Component } from "@angular/core";
import { FileService } from "@system-select/common";
import { IProject, MyProductsListTypes } from "@system-select/model";
import {
    GlazingSystemService,
    WindowTotalProductService,
} from "@system-select/web-services";

@Component({
    styles: [
        `
        glazing-systems-list,
        total-products-list {
            display: block;
            margin-bottom: 30px;
        }
        .loader-small {
            display: inline-block;
            margin-left: 20px;
            border: 5px solid #f3f3f3;
            -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
            border-top: 5px solid #00573d;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }`,
    ],
    templateUrl: "my-products.component.html",
})
export class MyProductsComponent {
    isSendingGlazingListEmail = false;
    isSendingTotalProductListEmail = false;
    productsListTypes = MyProductsListTypes;
    loadingResult = false;
    selectedProjectId: number | null = null;
    constructor(
        private glazingSystemService: GlazingSystemService,
        private windowTotalProductService: WindowTotalProductService
    ) {}

    updateSelectedProjectId(project: IProject): void {
        this.selectedProjectId = project ? project.Id : null;
    }

    downloadGlazingListSheet(): void {
        this.loadingResult = true;
        this.glazingSystemService
            .getGlazingListSheet(this.selectedProjectId ?? 0)
            .subscribe((answer: string) => {
                this.loadingResult = false;
                FileService.save(
                    answer,
                    "My Products Glazing List Sheet.pdf",
                    "application/pdf",
                    true
                );
            });
    }

    downloadTotalProductListSheet(): void {
        this.loadingResult = true;
        this.windowTotalProductService
            .getTotalProductListSheet(this.selectedProjectId ?? 0)
            .subscribe((answer: string) => {
                this.loadingResult = false;
                FileService.save(
                    answer,
                    "My Products Total Product List Sheet.pdf",
                    "application/pdf",
                    true
                );
            });
    }

    sendGlazingListSheetEmail(): void {
        this.isSendingGlazingListEmail = true;
    }

    sendTotalProductListSheetEmail(): void {
        this.isSendingTotalProductListEmail = true;
    }

    onSuccessfulEmail(): void {
        this.isSendingGlazingListEmail = false;
        this.isSendingTotalProductListEmail = false;
    }
}

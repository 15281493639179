import { ICustomer } from '../interfaces/customer';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType, IDynamicField, DynamicLabel, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { PublicUserDetailDynamicControls, IPublicUserDetailDynamicControlsParameters } from '../form-controls/public-user-detail.form-controls';
import { IPublicUserDetail } from '../interfaces/public-user-detail';
import { Validators } from '@angular/forms';

export interface IPublicUserDetailDynamicControlsParametersPartial extends IPublicUserDetailDynamicControlsParameters {
    states: any[],
}

export class PublicUserDetailDynamicControlsPartial extends PublicUserDetailDynamicControls {

    constructor(
        publicUserDetail?: IPublicUserDetail,
        additionalParameters?: IPublicUserDetailDynamicControlsParametersPartial,
    ) {
        super(publicUserDetail);

        this.Form.State = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            value: publicUserDetail && publicUserDetail.hasOwnProperty('State') && publicUserDetail.State !== null ? publicUserDetail.State : null,
            // tslint:disable-next-line:object-literal-sort-keys
            name: 'State',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            }),
            validation: [Validators.required],
            validators: { 'required': true },
            options: additionalParameters.states,
        });
    }

}

import { IEntity } from "@mt-ng2/entity-list-module";
import { IGasType } from "../gas-type";
import { IGlass } from "../glass";
import { IWindowFrame } from "../window-frame";
import { IWindowGasLayer } from "../window-gas-layer";
import { IWindowGlazingSystem } from "../window-glazing-system";
import { IWindowSolidLayer } from "../window-solid-layer";
import { IWindowTotalProduct } from "../window-total-product";

export interface IWindowGasLayerWrapper {
    GasType: IGasType;
    WindowGasLayer: IWindowGasLayer;
}

export interface IWindowSolidLayerWrapper {
    Glass: IGlass;
    WindowSolidLayer: IWindowSolidLayer;
}

export interface IWindowGlazingSystemWrapper {
    WindowGasLayers: IWindowGasLayerWrapper[];
    WindowGlazingSystem: IWindowGlazingSystem;
    WindowSolidLayers: IWindowSolidLayerWrapper[];
};

export interface IWindowTotalProductsSearchItem extends IEntity {
    WindowFrame: IWindowFrame;
    WindowGlazingSystem: IWindowGlazingSystemWrapper;
    WindowTotalProduct: IWindowTotalProduct;
}

import { Component, Input, OnInit } from '@angular/core';
import { IGlass, IGlazingSystem, IWindowFrame } from '@system-select/model';
import { FormatService, IGlazingSystemLayer } from '@system-select/common';
import { ProductDocumentService } from '@system-select/web-services';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'leed-report-summary',
    styles: [`
        .layers {
            text-align: left;
        }
        .window-frame {
            display: block;
        }
    `],
    template: `

<h3>LEED Report Summary</h3>
<div class="row p-t-lg">
    <div class="col-md-12">
        <div class="results-panel">
            <div class="panel panel-default">
                <div class="panel-heading product-performance-header">
                    Results
                </div>
                <div class="panel-body">
                    <div>
                        <table class="performance-table">
                            <tr>
                                <th>Glass System</th>
                                <th>LEED v4 Score</th>
                                <th>Metal System</th>
                                <th>LEED v4 Score</th>
                            </tr>
                            <tr>
                                <td>
                                    <ng-container *ngIf="glazingSystem">
                                        <div
                                            class="d-tc v-align-top"
                                            [style.padding-right]="'5px'"
                                        >
                                            <div
                                                class="color-swatch"
                                                [style.background-color]="
                                                    'rgb(' +
                                                    glass.ColorR +
                                                    ', ' +
                                                    glass.ColorG +
                                                    ', ' +
                                                    glass.ColorB +
                                                    ')'
                                                "
                                            ></div>
                                        </div>
                                        <div class="d-tc v-align-top glass-layers text-left">
                                            <div *ngFor="let layer of layers">
                                                {{ layer.Prefix }}:
                                                {{ layer.Name }}
                                                <button
                                                    *ngIf="
                                                        layerHasLeedDocuments(
                                                            layer
                                                        )
                                                    "
                                                    (click)="
                                                        downloadGlassLeedDocuments(
                                                            layer.Id
                                                        )
                                                    "
                                                    class="btn btn-xs btn-default"
                                                    title="Download Spec Sheet"
                                                >
                                                    <i
                                                        class="fa fa-file-pdf-o"
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </td>
                                <td>
                                    {{ getGlazingSystemLeedScore() }}
                                </td>
                                <td>
                                    <ng-container *ngIf="windowFrame">
                                        <div class="d-tc v-align-top window-frame">
                                            <b>{{ windowFrame.Name }}</b>
                                            <button
                                                [disabled]="!isAuthenticated"
                                                *ngIf="hasWindowFrameLeedDocuments()"
                                                (click)="
                                                    downloadWindowFrameLeedDocuments()
                                                "
                                                class="btn btn-xs btn-default"
                                                title="Download Spec Sheet"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                            </button>
                                            <ng-container
                                                *ngIf="windowFrame.SiteLink"
                                            >
                                                <br />
                                                <a
                                                    href="{{
                                                        windowFrame.SiteLink
                                                    }}"
                                                    target="_blank"
                                                    >Link to URL</a
                                                >
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </td>
                                <td>
                                    {{
                                        windowFrame
                                            ? windowFrame.LeedPoints
                                            : ""
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="loginPopupModalApi = $event"
>
    <div class="m-t-sm text-center">
        <login-override
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
            (emitCreateAccountSelected)="createAccountSelected($event)"
        ></login-override>
    </div>
</mt-modal-wrapper>

<mt-modal-wrapper
    title=""
    [autoShow]="false"
    [showActions]="false"
    [options]="closeModalOptions"
    (ready)="createAccountModalApi = $event"
>
    <div class="m-t-sm text-center">
        <create-account
            [isEmbedded]="true"
            (emitSuccessfulLogin)="updateAuthenticationStatus()"
        ></create-account>
    </div>
</mt-modal-wrapper>

    `,
})

export class LeedReportSummaryComponent implements OnInit {
    @Input() windowFrame: IWindowFrame;
    @Input() glazingSystem: IGlazingSystem;
    @Input() isAuthenticated: boolean;
    glass: IGlass;
    layers: IGlazingSystemLayer[];

    glassIdToDownloadAfterLogin: number = null;

    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;

    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };

    constructor(
        private formatService: FormatService,
        private productDocumentService: ProductDocumentService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        if (this.glazingSystem) {
            this.glass = this.glazingSystem.GlazingSystemSolidLayers.sort((a, b) => a.Layer - b.Layer)[0].Glass;
            this.layers = this.getLayers();
        }
    }

    /////////////// Glazing System Specific Methods /////////////////////

    // Gas layers won't ever have LEED documents so first check whether the ID matches a Glass layer
    layerHasLeedDocuments(layer: IGlazingSystemLayer): boolean {
        return layer.IsSolidLayer &&
            this.glazingSystem.GlazingSystemSolidLayers.find((gs) => gs.GlassId === layer.Id).Glass.Documents.length > 0;
    }

    getLayers(): IGlazingSystemLayer[] {
        return this.formatService.formatGlazingLayers(this.glazingSystem.GlazingSystemSolidLayers.sort((a, b) => a.Layer - b.Layer), this.glazingSystem.GlazingSystemGasLayers);
    }

    updateAuthenticationStatus(): void {
        this.loginPopupModalApi.close();
        this.createAccountModalApi.close();
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            if (this.glassIdToDownloadAfterLogin) {
                this.downloadGlassLeedDocuments(this.glassIdToDownloadAfterLogin);
                this.glassIdToDownloadAfterLogin = null;
            }
        });
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }

    downloadGlassLeedDocuments(glassId: number): void {
        if (!this.isAuthenticated) {
            this.glassIdToDownloadAfterLogin = glassId;
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);

            return;
        }

        const glass = this.glazingSystem.GlazingSystemSolidLayers.find((gs) => gs.GlassId === glassId).Glass;
        this.productDocumentService.downloadLeedDocuments(glass.Documents);
    }

    getGlazingSystemLeedScore(): number {
        return this.glazingSystem ? this.glazingSystem.GlazingSystemSolidLayers.map((gs) => {
            return gs.Glass.LeedPoints;
        }).reduce((total, score) => total + score) : null;
    }

    /////////////// Window Frame Specific Methods /////////////////////

    hasWindowFrameLeedDocuments(): boolean {
        return this.windowFrame && this.windowFrame.Documents.length > 0;
    }

    downloadWindowFrameLeedDocuments(): void {
        this.productDocumentService.downloadLeedDocuments(this.windowFrame.Documents);
    }
}

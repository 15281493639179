<div class="products-container">
    <mt-entity-list
        class="leed-reports-table saved-products-table table-xs"
        [entities]="leedReports"
        [(itemsPerPage)]="itemsPerPage"
        [total]="total"
        [(currentPage)]="currentPage"
        (pageChanged)="getLeedReports()"
        (columnSortedEvent)="columnSorted($event)"
        [emptyMessage]="'No saved LEED Reports.'"
        [entityListConfig]="entityListConfig"
    >
    </mt-entity-list>
</div>

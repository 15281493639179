import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { IGlassColor, IGlassType, IGlass, ISilkScreenColor, ISilkScreenPattern, GlassTypes, IInterlayerType } from '@system-select/model';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { IVendor } from 'src/app/model/public-api';

@Component({
    selector: 'layer-filter',
    styles: [`
        .well {
            padding: 11px !important;
            margin-bottom: 10px !important;
        }
        .radio-inline {
            padding-left: 0 !important;
        }
        .color-filter {
            height:20px;
            width:20px;
            display:inline-block;
            border-radius: 50%;
            padding: 11px;
            border: 1px solid #00000063;
        }
        input[type='radio'] {
            display: none;
        }
        input[type='radio']:checked ~ span {
            border: 3px solid black;
        }
    `],
    templateUrl: './layer-filter.component.html',
})
export class LayerFilterComponent implements OnInit {
    @Input() layer: number;
    @Input() glassColorItems: IGlassColor[];
    @Input() glassTypeItems: IGlassType[];
    @Input() vendorItems: IVendor[];
    @Input() silkScreenColorItems: ISilkScreenColor[];
    @Input() silkScreenPatternItems: ISilkScreenPattern[];
    @Input() interlayerTypeItems: IInterlayerType[];
    @Output() updateLayerParams: EventEmitter<ExtraSearchParams[]> = new EventEmitter<ExtraSearchParams[]>();

    layerLabel: string;
    glassTypeId: number;
    glassColorId: number;
    selectedVendorFilters: number[] = [];
    silkScreenColorId: number;
    silkScreenPatternId: number;
    interlayerTypeId: number;
    laminated = false;
    filteredGlassProducts: IGlass[];
    glassTypes: typeof GlassTypes;
    glassColorKey: object;
    laminateAvailableColors = ['Low Iron', 'Clear', 'White'];
    availableColors = ['Low Iron', 'Clear'];
    glassColorItemsCopy: IGlassColor[];
    glassTypeItemsCopy: IGlassType[];
    constructor(
    ) { }

    ngOnInit(): void {
        this.glassColorKey = {
            'Blue': '#6c9ba8',
            'Blue Green': '#add5d4',
            'Bronze': '#c0b5a8',
            'Clear': '#e2e8df',
            'Gray': '#c2c7c0',
            'Green': '#bad2bd',
            'Low Iron': '#e8eae2',
            'Silver': '#aab9bb',
            'White': '#fff',
        };
        this.glassTypes = GlassTypes;
        this.filterAvailableGlassColors(this.laminated);
        this.glassTypeId = null; // GlassTypes.LowE;
        this.glassColorId = null; // GlassColors.Clear;
        this.buildLayerParams();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.glassColorItems) {
            this.glassColorItemsCopy = changes.glassColorItems.currentValue;
            this.filterAvailableGlassColors(this.laminated);
        }
        if (changes.glassTypeItems) {
            this.glassTypeItemsCopy = changes.glassTypeItems.currentValue;
        }
    }

    getHeadingLabel(): string {
        switch (this.layer) {
            case 1:
                return 'Outboard';
            case 2:
                return 'Center Glass';
            case 3:
                return 'Inboard';
            default:
                return '';
        }
    }

    checkLaminatedValue(): void {
        this.laminated = !this.laminated;
        if (this.laminated) {
            this.buildLayerParams();
            this.filterAvailableGlassColors(true);

        } else {
            if (this.interlayerTypeId) {
                this.interlayerTypeId = null;
            }
            this.buildLayerParams();
            this.filterAvailableGlassColors(false);
            this.glassColorId = null;
        }
    }

    isGlassColorDisabled(glassColor: IGlassColor): string {
        // Hide the 'White' color swatch unless the layer is laminated
        if (!this.laminated && glassColor.Name === 'White') {
            return 'none';
        }
        return 'inline-block';
    }

    getSwatchColor(colorKey: string): string {
        return this.glassColorKey[colorKey];
    }

    getGlassColorLabel(id: number): string {
        return `glassColor${id}${this.layer}`;
    }

    clearLaminateFilter(): void {
        if (this.interlayerTypeId) {
            this.interlayerTypeId = null;
        }
        this.buildLayerParams();
    }

    clearGlassColorFilter(): void {
        if (this.glassColorId) {
            this.glassColorId = null;
            this.buildLayerParams();
        }
    }

    checkGlassTypeFilter(): void {
        if (!+this.glassTypeId) {
            this.clearGlassTypeFilter();
        }
    }

    clearGlassTypeFilter(): void {
        if (this.glassTypeId) {
            if (this.silkScreenColorId) {
                this.silkScreenColorId = null;
            }
            if (this.silkScreenPatternId) {
                this.silkScreenPatternId = null;
            }
            this.buildLayerParams();
        }
    }

    updateVendorList(vendorId: number): void {
        if (!this.selectedVendorFilters.includes(vendorId)) {
            this.selectedVendorFilters.push(vendorId);
        } else {
            const index = this.selectedVendorFilters.indexOf(vendorId);
            this.selectedVendorFilters.splice(index, 1);
        }
        this.buildLayerParams();
    }

    filterAvailableGlassColors(laminated: boolean): void {
        if (this.glassColorItems) {
            this.glassColorItems = this.glassColorItemsCopy.slice();
            if (this.layer > 1 && laminated) {
                this.glassColorItems = this.glassColorItems.filter((gc) => {
                    return this.laminateAvailableColors.indexOf(gc.Name) > -1;
                });
                this.glassColorId = null; // this.glassColorItems[0].Id;
            } else if (this.layer > 1) {
                this.glassColorItems = this.glassColorItems.filter((gc) => {
                    return this.availableColors.indexOf(gc.Name) > -1;
                });
                this.glassColorId = null; // this.glassColorItems[0].Id;
            }
        }
    }

    buildLayerParams(): void {
        const glassTypeId = +this.glassTypeId ? this.glassTypeId : null;
        const _layerParams: ExtraSearchParams[] = [];
        _layerParams.push(new ExtraSearchParams({
            name: 'Layer',
            value: this.layer.toString(),
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'GlassTypeId',
            value: glassTypeId ? glassTypeId.toString() : '',
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'GlassColorId',
            value: this.glassColorId ? this.glassColorId.toString() : '',
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'Laminated',
            value: this.laminated.toString(),
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'SilkScreenColorId',
            value: this.silkScreenColorId ? this.silkScreenColorId.toString() : '',
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'SilkScreenPatternId',
            value: this.silkScreenPatternId ? this.silkScreenPatternId.toString() : '',
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'InterlayerTypeId',
            value: this.interlayerTypeId ? this.interlayerTypeId.toString() : '',
        }));
        _layerParams.push(new ExtraSearchParams({
            name: 'VendorIds',
            valueArray: this.selectedVendorFilters,
        }));

        this.updateLayerParams.emit(_layerParams);
    }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { LeedReportBuilderFormSteps } from 'src/app/model/LeedReportBuilderFormSteps';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';

@Component({
    selector: 'leed-report-builder-navigation',
    styles: [`
        .nav-option {
            display: inline-block;
            width: 100%;
        }
        .nav-option button {
            width: 100%;
        }
        .fa-times-circle {
            color: red !important;
        }
        .fa-check-circle {
            color: green !important;
        }
        .btn-finalize {
            padding: 20px;
            font-size: 20px;
            min-width: 180px;
        }
    `],
    templateUrl: 'leed-report-builder-navigation.component.html',
})

export class LeedReportBuilderNavigationComponent implements OnInit {
    subscriptions: Subscription = new Subscription();
    completedFormSteps: LeedReportBuilderFormSteps[];
    LeedReportBuilderFormSteps = LeedReportBuilderFormSteps;
    currentFormStep: LeedReportBuilderFormSteps;
    formSteps: LeedReportBuilderFormSteps[] = [
        LeedReportBuilderFormSteps.Project,
        LeedReportBuilderFormSteps.ChoosePath,
        LeedReportBuilderFormSteps.Glass,
        LeedReportBuilderFormSteps.Metal,
        LeedReportBuilderFormSteps.Complete,
    ];

    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.stateService.currentFormStep$.subscribe((value) => {
                this.currentFormStep = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
    }

    isCurrentFormStep(step: LeedReportBuilderFormSteps): boolean {
        return step === this.currentFormStep;
    }

    isCompletedFormStep(step: LeedReportBuilderFormSteps): boolean {
        return this.completedFormSteps.indexOf(step) > -1;
    }

    canNavigateForwards(): boolean {
        if (this.currentFormStep === LeedReportBuilderFormSteps.Complete) {
            return false;
        }
        return this.completedFormSteps.indexOf(this.currentFormStep) > -1;
    }

    moveForwardInBuilder(): void {
        const indexOfCurrentStep = this.completedFormSteps.indexOf(this.currentFormStep);
        if (indexOfCurrentStep > -1) {
            if (this.stateService.selectedPaths$.value.length > 1 && this.currentFormStep === LeedReportBuilderFormSteps.Glass) {
                this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Metal);
            } else if (this.currentFormStep === LeedReportBuilderFormSteps.Glass) {
                this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Complete);
            } else {
                this.stateService.currentFormStep$.next(this.formSteps[indexOfCurrentStep + 1]);
            }
        }
    }

    canNavigateBackwards(): boolean {
        return this.currentFormStep !== LeedReportBuilderFormSteps.Project && this.currentFormStep !== LeedReportBuilderFormSteps.Complete;
    }

    navigateBackwards(): void {
        const indexOfCurrentStep = this.formSteps.indexOf(this.currentFormStep);
        if (this.stateService.selectedPaths$.value.length > 1 && this.currentFormStep === LeedReportBuilderFormSteps.Metal) {
            this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.Glass);
        } else if (this.currentFormStep === LeedReportBuilderFormSteps.Metal) {
            this.stateService.currentFormStep$.next(LeedReportBuilderFormSteps.ChoosePath);
        } else {
            this.stateService.currentFormStep$.next(this.formSteps[indexOfCurrentStep - 1]);
        }
    }

    updateCurrentFormStep(step: LeedReportBuilderFormSteps): void {
        this.stateService.currentFormStep$.next(step);
    }
}

import { IEntity } from './base';

import { IFrameMeshNode } from './frame-mesh-node';
import { IFramingSystemDetail } from './framing-system-detail';
import { IFrameType } from './frame-type';

export interface IFrame extends IEntity {
    Name: string;
    FrameTypeId: number;
    Pfd: number;
    UValue: number;
    UEdge: number;
    Shgc: number;

    // reverse nav
    FrameMeshNodes?: IFrameMeshNode[];
    FramingSystemDetails?: IFramingSystemDetail[];

    // foreign keys
    FrameType?: IFrameType;
}

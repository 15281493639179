import { IEntity } from './base';

import { ICustomer } from './customer';
import { IGlass } from './glass';
import { IUser } from './user';
import { IWindowFrame } from './window-frame';
import { IDocumentType } from './document-type';

export interface IDocument extends IEntity {
    Name: string;
    DateUpload: Date;
    UploadedBy?: number;
    FilePath: string;
    TypeId?: number;

    // reverse nav
    Customers?: ICustomer[];
    Glasses?: IGlass[];
    Users?: IUser[];
    WindowFrames?: IWindowFrame[];

    // foreign keys
    DocumentType?: IDocumentType;
    User?: IUser;
}
